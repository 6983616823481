import React from "react";

export function FormJoinWaitlist({handleSubmit, handleValidation, formValue, formError }) {
  return (
    <form onSubmit={handleSubmit} className="sm:max-w-xl sm:mx-auto lg:mx-0">
      <section className="sm:flex">
        <section className="min-w-0 flex-1">
          <label htmlFor="email" className="sr-only">
            Email address
          </label>
          <input
            id="sign-up-to-waitlist"
            name="email"
            type="text"
            value={formValue.email}
            onChange={handleValidation}
            placeholder="Enter your email"
            className="block w-full px-4 py-3 rounded-md border-0 text-base text-gray-900 placeholder-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-300 focus:ring-offset-gray-900"
          />
          <span className="text-red-400 mt-6">{formError.email}</span>
        </section>
        <section className="mt-3 sm:mt-0 sm:ml-3">
          <button
            type="submit"
            className="mt-3 sm:mt-0 sm:ml-3 block w-full py-3 px-4 rounded-md shadow bg-indigo-500 text-white font-medium hover:bg-indigo-600 focus:outline-none focus:ring-2  focus:ring-offset-gray-900"
          >
            Join waitlist!
          </button>
        </section>
      </section>
      <p className="mt-3 text-sm text-gray-300 sm:mt-4">
        Join our waitlist and we will let you know once our service is up and
        running.
      </p>
    </form>
  );
}
