import React from "react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
  Switch,
  Stack,
  FormControl,
  FormLabel,
} from "@chakra-ui/react";
import {
  allCookies,
  basicCookies,
  useCookiePreferences,
} from "../../context/useCookies";

export const CookieSettingsModal = ({ onClose }) => {
  const [preferences, savePreferences] = useCookiePreferences();

  const handleToggle = (cookieType) => {
    const newC = { ...preferences, [cookieType]: !preferences[cookieType] };
    savePreferences(newC);
  };

  const handleSave = () => {
    savePreferences(preferences);
    onClose();
  };

  const onRejectAll = () => {
    savePreferences(basicCookies);
    onClose();
  };

  const onAcceptAll = () => {
    savePreferences(allCookies);
    onClose();
  };

  const handleClose = () => {
    onClose(false);
  };

  return (
    <Modal isOpen={true} onClose={handleClose}>
      <ModalOverlay />
      <ModalContent
        sx={{
          marginTop: "auto",
          marginBottom: "auto",
          "@media (max-width: 640px)": {
            marginLeft: "1rem",
            marginRight: "1rem",
          },
        }}
      >
        <ModalHeader>Cookie Preferences</ModalHeader>
        <ModalCloseButton onClick={() => console.log("close was clicked")} />
        <ModalBody>
          <p>
            We use cookies in order to provide a better experience. You can read
            more about this in our
            <a href="/policies/cookies" className="border-b-2 border-gray-400">
              {" "}
              cookie policy
            </a>
            .
          </p>
          <p className="pt-2">
            You can customize your cookie preferences below:
          </p>
          <Stack spacing={4} mt={5}>
            <FormControl
              display="flex"
              alignItems="center"
              justifyContent="space-between"
            >
              <FormLabel htmlFor="necessary-cookies" mb="0">
                Necessary Cookies
              </FormLabel>
              <Switch
                id="necessary-cookies"
                isChecked={preferences.necessaryCookies}
                onChange={() => handleToggle("necessaryCookies")}
                isDisabled
              />
            </FormControl>
            <FormControl
              display="flex"
              alignItems="center"
              justifyContent="space-between"
            >
              <FormLabel htmlFor="analytics-cookies" mb="0">
                Analytics Cookies
              </FormLabel>
              <Switch
                id="analytics-cookies"
                isChecked={preferences.analyticsCookies}
                onChange={() => handleToggle("analyticsCookies")}
              />
            </FormControl>
            <FormControl
              display="flex"
              alignItems="center"
              justifyContent="space-between"
            >
              <FormLabel htmlFor="marketing-cookies" mb="0">
                Marketing Cookies
              </FormLabel>
              <Switch
                id="marketing-cookies"
                isChecked={preferences.marketingCookies}
                onChange={() => handleToggle("marketingCookies")}
              />
            </FormControl>
          </Stack>
        </ModalBody>
        <ModalFooter>
          <Button
            variant="outline"
            colorScheme="blue"
            mr={2}
            onClick={handleSave}
          >
            Save
          </Button>
          <Button colorScheme="blue" onClick={onRejectAll} mr={2}>
            Reject all
          </Button>
          <Button colorScheme="blue" onClick={onAcceptAll}>
            Accept all
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};
