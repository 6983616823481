import React, { useState } from "react";
import { XCircleIcon } from "@heroicons/react/24/solid";

export const ListInput = ({
  items,
  setItems,
  maxNumOfEntries = 5,
  stylesContainer = "",
  placeholderText = "Enter list item",
}) => {
  const [input, setInput] = useState("");

  const handleInputChange = (e) => {
    const str = e.target.value;
    const clean = str.charAt(0).toUpperCase() + str.slice(1);
    setInput(clean);
  };

  const handleAddItem = () => {
    if (input && items.length < maxNumOfEntries) {
      setItems([...items, input]);
      setInput("");
    }
  };

  const handleDeleteItem = (index) => {
    const updatedItems = items.filter((_, i) => i !== index);
    setItems(updatedItems);
  };

  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      handleAddItem();
    }
  };

  return (
    <div className={stylesContainer}>
      <div className="flex items-center space-x-2">
        <input
          type="text"
          value={input}
          onChange={handleInputChange}
          onKeyPress={handleKeyPress}
          onBlur={handleAddItem}
          placeholder={placeholderText}
          className="border p-2 bg-gray-200 border-gray-100 border-2 rounded w-full text-black placeholder-gray-600"
        />
        <button
          onClick={handleAddItem}
          className="bg-main-blue text-white border-2 border-main-blue rounded py-px px-2 text-3xl"
          disabled={items.length >= maxNumOfEntries}
        >
          +
        </button>
      </div>
      <ul className="mt-4 md:mt-8">
        {items.map((item, index) => (
          <li
            key={index}
            className="flex justify-between items-center py-2 border-t border-gray-400"
          >
            {index + 1}. {item}
            <button onClick={() => handleDeleteItem(index)} className="">
              <XCircleIcon
                className="h-5 w-5 text-red-400"
                aria-hidden="true"
              />
            </button>
          </li>
        ))}
      </ul>
    </div>
  );
};
