export const ButtonCustom = ({
  text = "button",
  isHighlight = false,
  style = "",
  onClick,
}) => {
  const colors = isHighlight
    ? "bg-yellow-400 hover:bg-yellow-600 text-black"
    : "bg-main-blue hover:bg-main-blue-hover text-black hover:text-white font-bold";

  const className = `font-black py-2 px-4 rounded ${colors} ${style}`;

  return (
    <button className={className} onClick={onClick}>
      {text}
    </button>
  );
};
