import React from "react";
import { useNavigate } from "react-router-dom";
import { AlertAll } from "../../components/AlertMessage";
import {
  CardDiscarded,
  CardEditProfile,
  CardSubmissions,
  CardSubscribeNow,
  CardSubscription,
  CardSuggestions,
  CardTitle,
} from "./Cards";

export const ProfileContent = ({
  userData,
  error,
  numSubmissions,
  numSuggestions,
  numDiscarded,
  subscriptionName,
  handleClickEdit,
  handleClickSuggestions,
  handleClickSubmissions,
  handleClickSubscription,
  handleClickDiscarded,
  isLoading,
}) => {
  const navigate = useNavigate();
  const isSubscriber = Boolean(subscriptionName);

  const onClickSubscribe = () => navigate("/subscriptions");

  const renderSubscriberContent = () => (
    <>
      <CardSuggestions
        numSuggestions={numSuggestions}
        onClick={handleClickSuggestions}
        isHighlight={true}
      />
      <CardSubmissions
        numSubmissions={numSubmissions}
        onClick={handleClickSubmissions}
      />
      <div className="md:grid grid-cols-2 md:space-x-4 space-y-4 md:space-y-0">
        <CardDiscarded
          numDiscarded={numDiscarded}
          onClick={handleClickDiscarded}
        />
        <CardSubscription
          subscriptionName={subscriptionName}
          onClick={handleClickSubscription}
        />
      </div>
    </>
  );

  const renderNonSubscriberContent = () => (
    <>
      <CardSubscribeNow onClick={onClickSubscribe} />
      <div className="md:grid grid-cols-2 md:space-x-4 space-y-4 md:space-y-0">
        <CardSuggestions
          numSuggestions={numSuggestions}
          onClick={handleClickSuggestions}
        />
        <CardSubmissions
          numSubmissions={numSubmissions}
          onClick={handleClickSubmissions}
        />
      </div>
    </>
  );

  return (
    <div className={"space-y-4"}>
      <CardTitle name={userData?.displayName} />
      <AlertAll isUpdating={isLoading} msgLoading="Loading your data..." />
      <AlertAll error={error} msgError="There was an issue loading your data" />
      {isSubscriber ? renderSubscriberContent() : renderNonSubscriberContent()}
      <CardEditProfile userData={userData} onClick={handleClickEdit} />
    </div>
  );
};
