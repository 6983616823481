import React, { createContext, useContext, useState } from "react";

const WeeklyContext = createContext();

export const WeeklyProvider = ({ children }) => {
  const [isWeekly, setIsWeekly] = useState(false);

  const updateIsWeekly = (val) => {
    setIsWeekly(val);
  };

  return (
    <WeeklyContext.Provider value={{ isWeekly, updateIsWeekly }}>
      {children}
    </WeeklyContext.Provider>
  );
};

export const useWeekly = () => useContext(WeeklyContext);
