export const desktopLinks = [
  {
    name: "AI Tools",
    list: [
      { name: "Resume Checker", href: "/ats-resume-checker", id: "" },
      {
        name: "Cover Letter Generator",
        href: "/cover-letter-generator",
        id: "",
      },
      { name: "Interview Answers", href: "/interview-preparation", id: "" },
    ],
  },
  { name: "Blog", href: "/blog", id: "ClickNavBarBlog" },
  { name: "Contacts", href: "/contact" },
  {
    name: "Subscriptions",
    href: "/subscriptions",
    id: "ClickNavBarSubscription",
  },
];

export const mobileLinks = [
  {
    name: "ATS Resume Checker",
    href: "/ats-resume-checker",
    id: "id-resume-checker",
  },
  {
    name: "Cover Letter Generator",
    href: "/cover-letter-generator",
    id: "id-cover-letter-generator",
  },
  { name: "Interview Answers", href: "/interview-preparation", id: "" },
  {},
  { name: "About", href: "/about" },
  { name: "Blog", href: "/blog", id: "ClickNavBarBlog" },
  { name: "Contacts", href: "/contact" },
  { name: "FAQ", href: "/#Faq" },
  {},
  {
    name: "Subscriptions",
    href: "/subscriptions",
    id: "ClickNavBarSubscription",
  },
];
