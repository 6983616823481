import React, { useEffect, useState } from "react";
import { IconWarningOrOkay } from "./IconWarningOrOkay";
import { Spinner } from "@chakra-ui/react";
import { CheckCircleIcon, WarningTwoIcon } from "@chakra-ui/icons";
import { styleWidthJobTitles } from "../assets/classnames";

export const SuccessOrAlertMessage = ({
  type = "success",
  message = "Success",
  delaySeconds = 5,
}) => {
  const [visible, setVisible] = useState(true);

  useEffect(() => {
    const timer = setTimeout(() => {
      setVisible(false);
    }, delaySeconds * 1000);

    return () => clearTimeout(timer);
  }, [delaySeconds]);

  if (!visible) return null;

  return (
    <div
      className={`flex items-center p-4 mb-4 text-sm text-left ${
        type === "success"
          ? "bg-green-100 text-green-700 border border-green-400"
          : "bg-yellow-100 text-yellow-700 border border-yellow-400"
      } rounded-lg`}
      role="alert"
    >
      <IconWarningOrOkay isOk={type === "success"} />
      <span className="pl-3">{message}</span>
    </div>
  );
};

export const MessageComponent = ({
  IconComponent,
  message,
  styleClasses,
  delaySeconds = 120,
}) => {
  const [visible, setVisible] = useState(true);

  useEffect(() => {
    const timer = setTimeout(() => {
      setVisible(false);
    }, delaySeconds * 1000);

    return () => clearTimeout(timer);
  }, [delaySeconds]);

  if (!visible) return null;

  return (
    <div
      className={`${styleClasses} flex items-center p-4 mb-4 text-sm text-left rounded-lg`}
      role="alert"
    >
      <IconComponent />
      <span className="pl-3">{message}</span>
    </div>
  );
};

export const AlertMessage = ({
  type = "success",
  display = false,
  message = "Success",
  center = false,
}) => {
  if (!display) return;

  const style = `flex py-2 rounded ${styleWidthJobTitles} ${center ? "mx-auto" : ""}`;
  return (
    <div className={style}>
      {type === "success" && <SuccessMessage message={message} />}
      {type === "error" && <ErrorMessage message={message} />}
      {type === "loading" && <LoadingMessage message={message} />}
    </div>
  );
};

export const AlertSuccess = ({ success, message, center = false }) => {
  if (!success) return;

  const style = `flex ${styleWidthJobTitles} ${center ? "mx-auto pt-4" : ""}`;

  return (
    <div className={style}>
      <SuccessMessage message={message} />
    </div>
  );
};

export const AlertAll = ({
  isUpdating,
  error,
  success,
  msgLoading = "Submitting data",
  msgError = "There was an error",
  msgSuccess = "Success",
}) => (
  <>
    <AlertMessage type={"loading"} display={isUpdating} message={msgLoading} />
    <AlertMessage type={"error"} display={error} message={msgError} />
    <AlertMessage type={"success"} display={success} message={msgSuccess} />
  </>
);

export const SuccessMessage = ({ message = "Success", delaySeconds }) => (
  <MessageComponent
    IconComponent={() => <CheckCircleIcon color="green.500" boxSize={5} />}
    message={message}
    styleClasses="bg-green-100 text-green-700 tracking-wide  border border-green-400"
    delaySeconds={delaySeconds}
  />
);

export const ErrorMessage = ({ message = "Error", delaySeconds }) => (
  <MessageComponent
    IconComponent={() => <WarningTwoIcon color="orange.500" boxSize={5} />}
    message={message}
    styleClasses="bg-red-100 text-red-700 tracking-wide  border border-red-400"
    delaySeconds={delaySeconds}
  />
);

export const LoadingMessage = ({ message = "Loading...", delaySeconds }) => (
  <MessageComponent
    IconComponent={() => <Spinner color="red.500" boxSize={5} />}
    message={message}
    styleClasses="bg-blue-100 text-blue-700 tracking-wide border border-blue-400 w-full md:w-fit"
    delaySeconds={delaySeconds}
  />
);
