import React, { createContext, useContext, useEffect, useState } from "react";
import axios from "axios";
import { isTokenExpired, useAuth } from "./useAuth";
import {
  URL_GET_USER_INFO,
  URL_SUBMISSIONS_LIST,
  URL_SUBSCRIBE_GET,
} from "../config";

const UserDataContext = createContext();

export const UserDataProvider = ({ children }) => {
  const { currentUser, token, refreshToken } = useAuth();
  const [userData, setUserData] = useState(null);
  const [userEmail, setUserEmail] = useState(null);
  const [isRecruiter, setIsRecruiter] = useState(false);
  const [loading, setLoading] = useState(true);
  const [isUpdating, setIsUpdating] = useState(false);
  const [subscriptionDetails, setSubscriptionDetails] = useState(null);
  const [stats, setStats] = useState({});

  const updateUserData = (url, data, onSuccess, onError) => {
    console.log("inside updateUserData", { url, data });
    setIsUpdating(true);

    const reqConfig = {
      headers: { Authorization: token },
    };

    axios
      .post(url, data, reqConfig)
      .then((res) => {
        console.log("res: ", res);
        // (re-) Fetch user data from backend
        fetchUserData(false);
        onSuccess();
      })
      .catch((error) => {
        console.error("There was an error updating the user data:", error);
        onError();
      })
      .finally(() => {
        setIsUpdating(false);
      });
  };

  const fetchUserData = async (displayLoadingIcon = true) => {
    console.log("inside fetchUserData");
    setLoading(displayLoadingIcon);

    try {
      const reqPayload = currentUser;

      const authToken = isTokenExpired(token) ? await refreshToken() : token;
      const reqConfig = {
        headers: { Authorization: authToken },
      };

      const res = await axios.post(URL_GET_USER_INFO, reqPayload, reqConfig);
      console.log("success fetching user data", { data: res.data || {} });

      setUserData(res.data);
      setUserEmail(res.data.email);
      setIsRecruiter(!!res.data.isRecruiter);
    } catch (error) {
      console.error("Error fetching user data:", error);
    } finally {
      setLoading(false);
    }
  };

  const getCustomerData = async (idUser, onSuccess, onError) => {
    console.log("inside getCustomerData");
    setLoading(true);

    try {
      const reqPayload = { _id: idUser };

      const authToken = isTokenExpired(token) ? await refreshToken() : token;
      const reqConfig = {
        headers: { Authorization: authToken },
      };

      const res = await axios.post(URL_GET_USER_INFO, reqPayload, reqConfig);
      console.log("getCustomerData success", { data: res.data || {} });

      onSuccess(res);
    } catch (error) {
      console.error("Error inside getCustomerData:", error);
      onError(error);
    } finally {
      setLoading(false);
    }
  };

  const fetchSubscriptionDetails = (userEmail) => {
    console.log("inside fetchSubscriptionDetails");

    const encodedEmail = encodeURIComponent(userEmail)
    const reqUrl = `${URL_SUBSCRIBE_GET}?userEmail=${encodedEmail}`;
    const reqConfig = { headers: { Authorization: token } };
    axios
      .get(reqUrl, reqConfig)
      .then((res) => {
        console.log("success fetching user subscription", {
          data: res?.data || {},
        });

        setSubscriptionDetails(res?.data?.subscriptionDetails);
      })
      .catch((error) => {
        console.error("Error fetching user subscription:", error);
      });
  };

  const fetchStats = async () => {
    const response = await axios.get(
      `${URL_SUBMISSIONS_LIST}?idUser=${userData._id}&limit=1`,
      { headers: { Authorization: token } }
    );
    console.log("fetchStats response: ", response);
    setStats(response?.data?.stats);
  };

  useEffect(() => {
    if (currentUser && !userData) {
      fetchUserData();
    } else {
      setLoading(false);
      setUserData(null);
    }
  }, [currentUser]);

  useEffect(() => {
    if (!userEmail) return;

    fetchSubscriptionDetails(userEmail);
  }, [userEmail]);

  useEffect(() => {
    if (!userEmail) return;

    fetchStats();
  }, [currentUser]);

  return (
    <UserDataContext.Provider
      value={{
        userData,
        isRecruiter,
        loading,
        updateUserData,
        isUpdating,
        subscriptionDetails,
        fetchUserData,
        getCustomerData,
        token,
        refreshToken,
        stats,
      }}
    >
      {children}
    </UserDataContext.Provider>
  );
};

export const useUserData = () => useContext(UserDataContext);
