import { CheckCircleIcon, MinusCircleIcon } from "@heroicons/react/24/solid";
import { Link, useLocation } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { titlesLanding } from "../../assets/classnames";
import { useLocationInfo } from "../../context/useLocation";
import { InfoIcon } from "../../components/cta/InfoIcon";
import { getPricingMap, infoMessagesSubscription } from "./dataPricing";
import { IconLoading } from "../../components/IconLoading";
import { selectCurrency } from "./subscriptionHelpers";
import { useWeekly } from "../../context/useWeekly";
import { useLogging } from "../../hooks/useLogging";
import { useObserver } from "../../hooks/useObserver";

const renderFeatures = (featureSet, name) => {
  const data = [];

  data.push(
    featureSet.includes.map((feature, index) => (
      <li key={feature} className="flex items-center justify-between">
        <div className="flex items-center">
          <section className="flex-shrink-0">
            <CheckCircleIcon
              className="flex-shrink-0 h-8 w-8 text-green-500"
              aria-hidden="true"
            />
          </section>
          <p
            className="ml-3 text-base font-medium text-gray-500"
            dangerouslySetInnerHTML={{ __html: feature }}
          />
        </div>
        <div>
          {infoMessagesSubscription[index] && (
            <InfoIcon
              message={infoMessagesSubscription[index]}
              id={`feature-${name}-${index}`}
            />
          )}
        </div>
      </li>
    ))
  );

  data.push(
    featureSet.doesNotInclude.map((feature) => (
      <li key={feature} className="flex items-start">
        <section className="flex-shrink-0">
          <MinusCircleIcon
            className="flex-shrink-0 h-6 w-8 text-gray-500"
            aria-hidden="true"
          />
        </section>
        <p className="ml-3 text-base font-medium text-gray-500 line-through">
          {feature}
        </p>
      </li>
    ))
  );

  return data;
};


export function NewPricingMenu() {
  const navigate = useNavigate();
  const urlLocation = useLocation();
  const { isLoading, location } = useLocationInfo();
  // const { isWeekly } = useWeekly();
  const isWeekly = true
  const currency = selectCurrency(location);
  
  const { logEvent } = useLogging();
  const [setRef] = useObserver("HowItWorks", () =>
    logEvent("visibility: Pricing menu")
  );

  const isProd = process.env.REACT_APP_STRIPE_ENV === "prod";
  const features = getPricingMap(isProd, isWeekly, location);


  const triggerVWOEvent = (tierName) => {
    console.log("triggering VWO event");

    // Ensure VWO is defined
    window.VWO = window.VWO || [];
    window.VWO.event =
      window.VWO.event ||
      function () {
        window.VWO.push(["event"].concat([].slice.call(arguments)));
      };

    window.VWO.event("event-click-subscribe", {
      isWeekly: isWeekly,
    });
  };

  const handleSubscribeClick = (e, tierName) => {
    console.log("handleSubscribeClick", tierName);
    e.preventDefault();
    triggerVWOEvent(tierName);
    window.scrollTo(0, 0);

    const queryParams = new URLSearchParams(urlLocation.search);
    const allParams = Object.fromEntries(queryParams.entries());
    const shouldShowPromo = allParams.showPromoCode !== undefined

    if (shouldShowPromo) {
      navigate(`/subscribe/${tierName}?showPromoCode`);
    } else {
      navigate(`/subscribe/${tierName}`);
    }
  };

  if (isLoading) {
    return (
      <div className="flex flex-center text-white">
        <IconLoading message="Loading subscription plans" />
      </div>
    );
  }

  return (
    <article ref={setRef} className="bg-gray-900">
      <section className="text-center md:text-left">
        <h3 className={titlesLanding}>Subscriptions</h3>
        <p className="mt-3 max-w-4xl mx-auto md:mx-0 text-xl text-gray-300 sm:mt-5 sm:text-2xl">
          Various plans depending on your needs and business goals
        </p>
      </section>

      <section className="mt-8 gray-900 lg:mt-10">
        <section className="relative z-0">
          <section className="absolute inset-0 h-5/6 bg-gray-900 lg:h-2/3" />
          <section className="max-w-7xl mx-auto ">
            <section className="relative lg:grid lg:grid-cols-7">
              {/* Lite */}
              <section className="mx-auto max-w-md lg:mx-0 lg:max-w-none lg:col-start-1 lg:col-end-3 lg:row-start-2 lg:row-end-3">
                <section className="h-full flex flex-col rounded-lg shadow-lg overflow-hidden lg:rounded-none lg:rounded-l-lg">
                  <section className="flex-1 flex flex-col">
                    <section className="bg-white px-6 py-10">
                      <section>
                        <h3
                          className="text-center text-4xl font-medium text-gray-900"
                          id="tier-hobby"
                        >
                          Lite
                        </h3>
                        <section className="mt-4 flex items-center justify-center">
                          <span className="px-3 flex items-start text-5xl tracking-tight text-gray-900">
                            <span className="mt-2 mr-2 text-4xl font-medium">
                              {currency.symbol}
                            </span>
                            <span className="font-extrabold">
                              {features.Lite.price}
                            </span>
                          </span>
                          <span className="text-xl font-medium text-gray-500">
                            /{features.Lite.cadence}
                          </span>
                        </section>
                      </section>
                    </section>
                    <section className="flex-1 flex flex-col justify-between border-t-2 border-gray-100 p-6 bg-gray-50 sm:p-10 lg:p-6 xl:p-6">
                      <ul className="space-y-4">
                        {renderFeatures(features.Lite, "lite")}
                      </ul>
                      <section className="mt-8">
                        <section className="rounded-lg shadow-md">
                          <Link
                            id="CtaSubscribeMenu"
                            to={`/subscribe/Lite`}
                            onClick={(e) => handleSubscribeClick(e, "Lite")}
                            className="block w-full text-center rounded-lg border border-transparent  px-6 py-3 text-lg bg-indigo-600 hover:bg-indigo-700 hover:font-black text-white"
                          >
                            Subscribe
                          </Link>
                        </section>
                      </section>
                    </section>
                  </section>
                </section>
              </section>

              {/* Pro */}
              <section className="mt-10 max-w-md mx-auto lg:mt-0 lg:max-w-none lg:mx-0 lg:col-start-3 lg:col-end-6 lg:row-start-1 lg:row-end-4">
                <section className="relative z-10 rounded-lg shadow-xl">
                  <section
                    className="pointer-events-none absolute inset-0 rounded-lg border-2 border-indigo-600"
                    aria-hidden="true"
                  />
                  <section className="absolute inset-x-0 top-0 transform translate-y-px">
                    <section className="flex justify-center transform -translate-y-1/2">
                      <span className="inline-flex rounded-full bg-indigo-600 px-4 py-2 text-sm font-semibold tracking-wider uppercase text-white">
                        Most Popular
                      </span>
                    </section>
                  </section>
                  <section className="bg-white rounded-t-lg px-6 pt-12 pb-8 xl:pt-20 xl:pb-12 2xl:pt-16 2xl:pb-16">
                    <section>
                      <h3
                        className="text-center text-5xl font-semibold text-gray-900 sm:-mx-6"
                        id="tier-growth"
                      >
                        Pro
                      </h3>
                      <section className="mt-4 flex items-center justify-center">
                        <span className="px-3 flex items-start text-5xl tracking-tight text-gray-900 sm:text-5xl">
                          <span className="mt-2 mr-2 text-4xl font-medium">
                            {currency.symbol}
                          </span>
                          <span className="font-extrabold">
                            {features.Pro.price}
                          </span>
                        </span>
                        <span className="text-2xl font-medium text-gray-500">
                          /{features.Pro.cadence}
                        </span>
                      </section>
                    </section>
                  </section>
                  <section className="border-t-2 border-gray-100 rounded-b-lg pt-10 2xl:pt-4 pb-8 px-6 bg-gray-50 sm:px-10 sm:py-10">
                    <ul className="space-y-4 mt-6 lg:mx-8 xl:mx-4 2xl:mx-10">
                      {renderFeatures(features.Pro, "pro")}
                    </ul>
                    <section className="mt-8 lg:mt-24 xl:mt-12 2xl:mt-20">
                      <section className="rounded-lg shadow-md">
                        <Link
                          to={`/subscribe/Pro`}
                          onClick={(e) => handleSubscribeClick(e, "Pro")}
                          className="block w-full text-center rounded-lg border border-transparent px-6 py-4 text-xl leading-6 font-medium text-white bg-indigo-600 hover:bg-indigo-700 hover:font-black"
                        >
                          Subscribe
                        </Link>
                      </section>
                    </section>
                  </section>
                </section>
              </section>

              {/* Premium */}
              <section className="mt-10 mx-auto max-w-md lg:m-0 lg:max-w-none lg:col-start-6 lg:col-end-8 lg:row-start-2 lg:row-end-3">
                <section className="h-full flex flex-col rounded-lg shadow-lg overflow-hidden lg:rounded-none lg:rounded-r-lg">
                  <section className="flex-1 flex flex-col">
                    <section className="bg-white px-6 py-10">
                      <section>
                        <h3
                          className="text-center text-4xl font-medium text-gray-900"
                          id="tier-scale"
                        >
                          Premium
                        </h3>
                        <section className="mt-4 flex items-center justify-center">
                          <span className="px-3 flex items-start text-5xl tracking-tight text-gray-900">
                            <span className="mt-2 mr-2 text-4xl font-medium">
                              {currency.symbol}
                            </span>
                            <span className="font-extrabold">
                              {features.Premium.price}
                            </span>
                          </span>
                          <span className="text-xl font-medium text-gray-500">
                            /{features.Premium.cadence}
                          </span>
                        </section>
                      </section>
                    </section>
                    <section className="flex-1 flex flex-col justify-between border-t-2 border-gray-100 p-6 bg-gray-50 sm:p-10 lg:p-6 xl:p-6">
                      <ul className="space-y-4">
                        {renderFeatures(features.Premium, "premium")}
                      </ul>
                      <section className="mt-8">
                        <section className="rounded-lg shadow-md">
                          <Link
                            to={`/subscribe/Premium`}
                            onClick={(e) => handleSubscribeClick(e, "Premium")}
                            className="block w-full text-center rounded-lg border border-transparent px-6 py-3 text-lg bg-indigo-600 hover:bg-indigo-700 hover:font-black text-white"
                          >
                            Subscribe
                          </Link>
                        </section>
                      </section>
                    </section>
                  </section>
                </section>
              </section>
            </section>
          </section>
        </section>
      </section>
    </article>
  );
}
