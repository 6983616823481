import { OAuthProvider, signInWithPopup } from "firebase/auth";
import { auth } from "../firebaseConfig";
import { useAuth } from "../context/useAuth";

export const authMicrosoft = () => {
  const { updateToken } = useAuth();

  const logIn = async () => {
    try {
      console.log("logging in using microsoft");

      const provider = new OAuthProvider("microsoft.com");
      const result = await signInWithPopup(auth, provider);
      const token = await result.user.getIdToken();
      updateToken(token);

      console.log("Sign in success");
    } catch (error) {
      console.log("Sign in error: ", error);
      throw error;
    }
  };

  return { logIn };
};
