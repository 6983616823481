import React, { useState } from "react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
  Input,
} from "@chakra-ui/react";


export const ModalWithBody = ({
  title = "Confirm Choice",
  text = "Are you sure?",
  placeholderBody="some text",
  onSubmit = () => {},
  onClose = () => {},
  confirmButtonText = "Confirm"
}) => {
  const [inputValue, setInputValue] = useState("");

  const handleInputChange = (event) => {
    setInputValue(event.target.value);
  };

  const handleSubmit = () => {
    onSubmit(inputValue);
    onClose();
  };

  const handleCancel = () => {
    onClose(false);
  };

  return (
    <Modal isOpen={true} onClose={onClose}>
      <ModalOverlay />
      <ModalContent
        sx={{
          marginTop: "auto",
          marginBottom: "auto",
          "@media (max-width: 640px)": {
            marginLeft: "1rem",
            marginRight: "1rem",
          },
        }}
      >
        <ModalHeader>{title}</ModalHeader>
        <ModalCloseButton onClick={onClose} />
        <ModalBody>
          <p>{text}</p>
          <textarea
            id="cvJobSearchNotes"
            rows="3"
            className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 mt-2 pt-2 pl-2 block w-full sm:text-md border-1 border-gray-200 rounded-md text-black placeholder-gray-600"
            value={inputValue}
            onChange={handleInputChange}
            placeholder={placeholderBody}
          />

        </ModalBody>
        <ModalFooter>
          <Button
            colorScheme="blue"
            variant="outline"
            onClick={handleCancel}
            mr={2}
          >
            Cancel
          </Button>
          <Button colorScheme="blue" onClick={handleSubmit}>
            {confirmButtonText}
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};
