import team from "../../data/team";

export default function Team() {
  return (
    <article  className={`bg-gray-900 pt-8 my-auto`}>
      <section className="max-w-7xl mx-auto py-12 px-4 text-center sm:px-6 lg:px-8 lg:py-24">
        <section className="sm:space-y-12">
          <section className="space-y-5 sm:mx-auto sm:max-w-xl sm:space-y-4 lg:max-w-5xl pb-12">
            <h3 className="mt-2 text-3xl font-extrabold text-main-blue sm:text-4xl lg:text-5xl">
              Our Team
            </h3>
            <p className="mt-3 max-w-4xl mx-auto text-xl text-gray-300 sm:mt-5 sm:text-2xl">
              We are a small startup, with no funding, but full of highly
              motivated people.
            </p>
          </section>
          <ul className="mx-auto space-y-16 sm:grid sm:grid-cols-2 sm:gap-16 sm:space-y-0 lg:grid-cols-3 lg:max-w-5xl">
            {team.map((person, index) => {
              const isVisible = index === 2 ? 'hidden sm:block' : '' || index === 0 ? 'block sm:hidden' : '' 
              return (
              <li key={`${person.name}-${index}`} className={isVisible}>
                <section className="space-y-6 ">
                  <img
                    className="mx-auto h-40 w-40 rounded-full xl:w-56 xl:h-56 object-cover "
                    src={person.imageUrl}
                    alt={`Team member ${person.name}`}
                  />
                  <section className="space-y-2">
                    <section className="text-lg leading-6 font-medium space-y-1">
                      <h3 className="text-white">{person.name}</h3>
                      <p className="text-[color:var(--primary-font-color)]">
                        {person.role}
                      </p>
                    </section>
                    <ul className="flex justify-center space-x-5">
                      <li>
                        <a
                          href={person.linkedinUrl}
                          target="_blank"
                          rel="noreferrer"
                          className="text-gray-400 hover:text-gray-500"
                        >
                          <span className="sr-only">LinkedIn</span>
                          <svg
                            className="w-5 h-5"
                            aria-hidden="true"
                            fill="currentColor"
                            viewBox="0 0 20 20"
                          >
                            <path
                              fillRule="evenodd"
                              d="M16.338 16.338H13.67V12.16c0-.995-.017-2.277-1.387-2.277-1.39 0-1.601 1.086-1.601 2.207v4.248H8.014v-8.59h2.559v1.174h.037c.356-.675 1.227-1.387 2.526-1.387 2.703 0 3.203 1.778 3.203 4.092v4.711zM5.005 6.575a1.548 1.548 0 11-.003-3.096 1.548 1.548 0 01.003 3.096zm-1.337 9.763H6.34v-8.59H3.667v8.59zM17.668 1H2.328C1.595 1 1 1.581 1 2.298v15.403C1 18.418 1.595 19 2.328 19h15.34c.734 0 1.332-.582 1.332-1.299V2.298C19 1.581 18.402 1 17.668 1z"
                              clipRule="evenodd"
                            />
                          </svg>
                        </a>
                      </li>
                    </ul>
                  </section>
                </section>
              </li>
            )})}
          </ul>
        </section>
      </section>
    </article>
  );
}
