import { useState, useEffect } from "react";
import { useLogging } from "../hooks/useLogging";

export const basicCookies = {
  necessaryCookies: true,
  analyticsCookies: false,
  marketingCookies: false,
};

export const allCookies = {
  necessaryCookies: true,
  analyticsCookies: true,
  marketingCookies: true,
};

function loadGoogleTagManager() {
  // Add check to exclude specific pages from analytics
  if (window.location.pathname.startsWith("/users")) {
    console.log("Analytics not loaded for /users* pages");
    return;
  }

  // this may not have an effect in next.js (due to window not existing on server)
  if (
    window.location.hostname.includes("dev") ||
    window.location.hostname.includes("localhost")
  ) {
    console.log("We are in dev/local environment. Not loading analytics");
    return;
  }

  console.log("Loading google analytics");

  const script = document.createElement("script");
  script.src = "https://www.googletagmanager.com/gtag/js?id=G-BR00HHW4PH";
  script.async = true;
  document.head.appendChild(script);

  script.onload = () => {
    window.dataLayer = window.dataLayer || [];
    function gtag() {
      dataLayer.push(arguments);
    }
    gtag("js", new Date());
    gtag("config", "G-BR00HHW4PH");

    // Call the function to set up Intersection Observer after GA is loaded
    setupAnalyticsSectionTracking(gtag);
    setupAnalyticsCtaTracking(gtag);
  };
}

function setupAnalyticsSectionTracking(gtag) {
  const sections = [
    { id: "Hero" },
    { id: "HowItWorks" },
    { id: "SectionReviews" },
    { id: "Subscriptions" },
    { id: "Faq" },
    { id: "Questions" },
  ];

  function sendGAEvent(section) {
    console.log("visibility event: ", section);
    gtag("event", "view_section", {
      event_category: "Section Visibility",
      event_label: section,
    });
  }

  const observer = new IntersectionObserver(
    (entries, observer) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          sendGAEvent(entry.target.id);
          observer.unobserve(entry.target); // Stop observing after the first time it becomes visible
        }
      });
    },
    { threshold: 0.1 } // Trigger when 10% of the section is visible
  );

  sections.forEach((section) => {
    const element = document.getElementById(section.id);
    if (element) {
      observer.observe(element);
    }
  });
}

function setupAnalyticsCtaTracking(gtag) {
  const ctaButtons = [
    { id: "CtaHero", label: "CtaHero" },
    { id: "CtaHowItWorks", label: "CtaHowItWorks" },
    { id: "CtaSubscribeMenu", label: "CtaSubscribeMenu" },
    { id: "CtaBottom", label: "CtaBottom" },
    { id: "CtaAboutSubscribe", label: "CtaAboutSubscribe" },
    // { id: "ClickNavBarBlog", label: "ClickNavBarBlog" },
    // { id: "ClickNavBarSubscription", label: "ClickNavBarSubscription" },
  ];

  function sendGAEvent(buttonLabel) {
    console.log("click event: ", buttonLabel);
    gtag("event", "click_cta", {
      event_category: "CTA Clicks",
      event_label: buttonLabel,
    });
  }

  ctaButtons.forEach((button) => {
    const element = document.getElementById(button.id);
    if (element) {
      element.addEventListener("click", () => {
        sendGAEvent(button.label);
      });
    }
  });
}

export const useCookiePreferences = () => {
  const [shouldDisplayBanner, setShouldDisplayBanner] = useState(false);
  const [preferences, setPreferences] = useState({
    necessaryCookies: true,
    analyticsCookies: false,
    marketingCookies: false,
  });
  const { logEvent } = useLogging();

  useEffect(() => {
    const storedPreferences = localStorage.getItem("cookiePreferences");
    if (storedPreferences) {
      setPreferences(JSON.parse(storedPreferences));
      setShouldDisplayBanner(false);
    } else {
      setShouldDisplayBanner(true);
    }
  }, []);

  useEffect(() => {
    if (preferences.analyticsCookies) {
      loadGoogleTagManager();
    }
  }, [preferences.analyticsCookies]);

  const savePreferences = (newPreferences) => {
    console.log("inside cookies savePreferences");

    localStorage.setItem("cookiePreferences", JSON.stringify(newPreferences));
    setPreferences(newPreferences);

    if (newPreferences.analyticsCookies) {
      logEvent("Cookies accepted");
    } else {
      logEvent("Cookies rejected");
    }
  };

  return [
    preferences,
    savePreferences,
    shouldDisplayBanner,
    setShouldDisplayBanner,
  ];
};
