import { signOut } from "firebase/auth";
import { auth } from "../firebaseConfig";
import { useAuth } from "../context/useAuth";

export const authLogout = () => {
  const { updateToken } = useAuth();

  const logOut = async () => {
    try {
      await signOut(auth);
      console.log("User signed out successfully, google");
      updateToken();
    } catch (error) {
      console.error("Error signing out:", error);
      throw error;
    }
  };

  return { logOut };
};
