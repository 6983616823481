import React, { useState, useEffect } from "react";
import { useProgress } from "../../context/useProgress";
import { ProgressBar, Step } from "react-step-progress-bar";
import { urlRegistration } from "../../config";
import { useNavigate } from "react-router-dom";
import "react-step-progress-bar/styles.css";

const styleStep = "rounded-full flex items-center justify-center";
const styleStepComplete = "bg-main-blue hover:w-12 hover:h-12  cursor-pointer";
const styleStepNotComplete = "bg-gray-200";
const styleStepActive = "bg-main-blue w-12 h-12";
const styleStepNotActive = "w-8 h-8";

const percentages = { 0: 0, 1: 20, 2: 40, 3: 60, 4: 80, 5: 100 };

export const ProgressBarRegistration = ({}) => {
  const navigate = useNavigate();
  const { currProgress, prevProgress, totalSteps, stepCompleted } =
    useProgress();

  const initialPercent = percentages[prevProgress];
  const [animatedPercent, setAnimatedPercent] = useState(initialPercent);

  useEffect(() => {
    const targetPercent = percentages[currProgress];

    const interval = setInterval(() => {
      setAnimatedPercent((prevPercent) => {
        if (prevPercent < targetPercent) {
          return prevPercent + 1;
        } else if (prevPercent > targetPercent) {
          return prevPercent - 1;
        } else {
          clearInterval(interval);
          return prevPercent;
        }
      });
    }, 10); // Adjust the speed as necessary

    return () => clearInterval(interval);
  }, [currProgress, prevProgress]);

  const handleStepClick = (index) => {
    if (!index > stepCompleted) return;

    if (index === 1) navigate(urlRegistration.cvUpload);
    if (index === 2) navigate(urlRegistration.jobTitles);
    if (index === 3) navigate(urlRegistration.locationPreferences);
    if (index === 4) navigate(urlRegistration.otherPreferences);
    if (index === 5) navigate(urlRegistration.review);
  };

  const StepItem = ({ index }) => {
    const isActive = index === currProgress;
    const isComplete = index <= stepCompleted;
    const styleComplete = isComplete ? styleStepComplete : styleStepNotComplete;
    const styleActive = isActive ? styleStepActive : styleStepNotActive;
    const style = `${styleStep} ${styleComplete} ${styleActive}`;

    return (
      <div className={style} onClick={() => handleStepClick(index)}>
        {isComplete || isActive  ? (
          <div className="rounded-full font-bold pt-px">{index}</div>
        ) : null}
      </div>
    );
  };

  return (
    <div className="w-full py-4 px-2">
      <ProgressBar
        percent={animatedPercent}
        filledBackground="#939DFA"
        height={2}
        hasStepZero={true}
      >
        {Array.from({ length: totalSteps }, (_, index) => (
          <Step key={index + 1} transition="scale">
            {() => <StepItem index={index + 1} />}
          </Step>
        ))}
      </ProgressBar>
    </div>
  );
};
