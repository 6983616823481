import React, { useState, useEffect } from "react";
import { CardSubmission } from "./CardSubmission";
import { stylePageTitle } from "../../assets/classnames";
import { useSubmissionsApi } from "../../context/useSubmissionsApi";
import { useUserData } from "../../context/useUserData";
import { AlertAll } from "../AlertMessage";
import { useNavigate, useParams } from "react-router-dom";
import { CardDiscardedSimplified, CardSubmissionsSimplified } from "../../routes/user-profile/Cards";
import { ButtonCustom } from "../cta/ButtonCustom";

export const DiscardedPage = () => {
  const navigate = useNavigate()
  const { idUser } = useParams();
  const { isRecruiter } = useUserData();
  const { isLoading, fetchDiscarded } = useSubmissionsApi();
  const [submissions, setSubmissions] = useState([]);
  const [error, setError] = useState(null);

  console.log("new Submissions Page", { isLoading, submissions });

  useEffect(() => {
    setError(false);
    fetchDiscarded(idUser, onSuccess, onError);
  }, [idUser]);

  const onSuccess = (res) => setSubmissions(res?.data?.docList);
  const onError = (e) => setError(e);

  const TitleContent = () => (
    <div className={`${stylePageTitle} text-main-blue`}>Discarded Suggestions</div>
  );

  const AlertContent = () => (
    <AlertAll
      isUpdating={isLoading}
      msgLoading={"Loading discarded suggestions"}
      error={error}
      msgError="Error loading discarded suggestions"
    />
  );

  const SuggestionsContent = () => {
    if (submissions.length === 0) return;
    return (
      <div>
        <p className="text-md max-w-3xl pb-4">
          These are the suggestions that were previously discarded.
        </p>
        <div className="space-y-2">
          {submissions.map((data, index) => (
            <CardSubmission
              data={data}
              isRecruiter={isRecruiter}
              key={`card-submission-${index}`}
              isComplete={true}
              isDiscarded={true}
            />
          ))}
        </div>
      </div>
    );
  };
  const NoSuggestionsContent = () => {
    if (submissions.length > 0) return;

    return <CardDiscardedSimplified />;
  };

  const BackHomeContent = () => (
    <div className="flex justify-center pt-12">
      <ButtonCustom
        text="Back Home"
        onClick={() => navigate("/profile")}
        isHighlight={false}
      />
    </div>
  );

  return (
    <div>
      <TitleContent />
      <AlertContent />
      <SuggestionsContent />
      <NoSuggestionsContent />
      <BackHomeContent />
    </div>
  );
};
