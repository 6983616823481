import React, { useState } from "react";
import { authEmail } from "../../hooks/authEmail";
import { useNavigate } from "react-router-dom";
import { navBarMl, navBarMr } from "../../assets/classnames";
import { ButtonCustom } from "../cta/ButtonCustom";

export const PageEmailSignUp = () => {
  const { signUpWithEmail } = authEmail();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const navigate = useNavigate();
  const [error, setError] = useState(null);

  const handleRegister = async (e) => {
    e.preventDefault();
    try {
      await signUpWithEmail(email, password);
      navigate("/profile");
    } catch (error) {
      console.error("Registration failed: ", error);
      setError("Registration failed. The email might be already in use.");
    }
  };
  return (
    <div className="">
      <div className={`${navBarMl} ${navBarMr}`}>
        <h1 className="text-center text-3xl font-bold">Sign Up</h1>
        <form onSubmit={handleRegister}>
          <div className="flex flex-col pt-4 w-64">
            <p>Email</p>
            <input
              type="email"
              placeholder="Email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              className="text-black"
              required
            />
            <p>Password</p>
            <input
              type="password"
              placeholder="Password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              className="text-black"
              required
            />
            <ButtonCustom
              text="Sign Up"
              style="mt-4"
              onClick={handleRegister}
            />

            {error && <p className="text-red-400 text-center mt-4">{error}</p>}

            <p className="mt-8 text-center">
              Already registered?
              <a href={"/email-login"}>
                <p
                  onClick={() => {}}
                  className="underline hover:cursor-pointer"
                >
                  Log In
                </p>
              </a>
            </p>
          </div>
        </form>
      </div>
    </div>
  );
};
