import { useState, useEffect, useRef } from "react";

const defaultOptions = {
  root: null,
  rootMargin: "0px",
  threshold: 0.1,
};

export const useObserver = (eventName, actionFn, options = defaultOptions) => {
  const [ref, setRef] = useState(null);
  const [isVisible, setIsVisible] = useState(false);
  const hasLogged = useRef(false);

  useEffect(() => {
    const mergedOptions = { ...defaultOptions, ...options };

    if (ref) {
      const observer = new IntersectionObserver(([entry]) => {
        setIsVisible(entry.isIntersecting);
      }, mergedOptions);

      observer.observe(ref);

      return () => {
        observer.disconnect();
      };
    }
  }, [ref, options]);

  useEffect(() => {
    if (isVisible && !hasLogged.current) {
      actionFn();
      hasLogged.current = true;
    }
  }, [isVisible, eventName]);

  return [setRef, isVisible];
};
