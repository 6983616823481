import axios from "axios";
import { URL_LOG_EVENT } from "../config";

export const useLogging = () => {
  const logEvent = async (message) => {
    try {
      const data = { message };
      await axios.post(URL_LOG_EVENT, data);
    } catch (error) {
      console.error("Error logging event:", error);
    }
  };

  return { logEvent };
};
