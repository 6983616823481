import React from "react";
import { InformationCircleIcon } from "@heroicons/react/24/solid";
import { Tooltip } from "react-tooltip";

export const InfoIcon = ({ message = "This is the info message.", id }) => {
  return (
    <div className="relative inline-block">
      <a id={id}>
        <InformationCircleIcon className="w-6 h-6 text-gray-500 cursor-pointer" />
      </a>
      <Tooltip anchorSelect={`#${id}`} content={message} place="top" className="max-w-56 z-30" />
    </div>
  );
};
