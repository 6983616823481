import React, { useRef, useEffect, useState } from "react";
import { Cta } from "../../components/cta/Cta";
import {
  animationDelays,
  navBarPaddingLeft,
  navBarPaddingRight,
} from "../../assets/classnames";
import { BannerCompanies } from "./BannerCompanies";
import { useLogging } from "../../hooks/useLogging";
import { useObserver } from "../../hooks/useObserver";
import { DemoVideo } from "./DemoVideo";

export default function Hero() {
  const { logEvent } = useLogging();
  const [setRef] = useObserver("Hero", () => logEvent("visibility: Hero"));

  return (
    <article ref={setRef} id="Hero" className="bg-gray-900 lg:overflow-hidden">
      <section className="h-[calc(100vh_-_11rem)]  sm:h-[calc(100vh_-_8rem)] pt-20 sm:pt-32 md:pt-20 lg:pb-16">
        <section
          className={`text-center ${navBarPaddingLeft} ${navBarPaddingRight} xs:pr-0`}
        >
          <h2
            className="mt-4 tracking-tight font-extrabold text-white text-5xl sm:text-5xl lg:mt-6 xl:text-5xl 2xl:text-6xl overflow-visible"
            translate="no"
          >
            <span className="inline-block text-main-blue">
              {"Get work, ".split("").map((char, index) => (
                <span
                  key={index}
                  className="inline-block animate-typing"
                  style={{
                    animationDelay: `${animationDelays.lettersDelay * index}s`,
                  }}
                >
                  {char === " " ? "\u00A0" : char}
                </span>
              ))}
            </span>
            <span
              className="inline-block animate-slideIn"
              style={{ animationDelay: animationDelays.titleQuick }}
            >
              quickly!
            </span>
          </h2>

          <p
            className="my-4 text-base text-white text-center text-lg sm:text-xl lg:text-lg xl:text-xl"
            style={{ animationDelay: animationDelays.subTitle }}
          >
            Tired of your job search?
          </p>

          <div className="pt-8 md:pt-16">
            <Cta animate={false} lgAlign="" id="CtaHero" />
          </div>
        </section>
        <section className="h-full">
          <DemoVideo />
        </section>
      </section>
      <div className="col-span-2 pt-20 md:pt-0">
        <BannerCompanies title="Get hired by" />
      </div>
    </article>
  );
}
