import axios from "axios";
import React, { useState } from "react";
import { NavLink } from "react-router-dom";
import { CheckCircleIcon } from "@chakra-ui/icons";
import { IconLoading } from "../../components/IconLoading";
import { URL_UPDATE_USER_INFO } from "../../config";
import { CvUploadForm } from "./CvUploadForm";
import FileUploadIcon from "../../assets/images/file-upload-icon.svg";
import { useUserData } from "../../context/useUserData";
import { isTokenExpired } from "../../context/useAuth";

export const CvUploadContainer = ({
  userId,
  hasCv,
  onSuccess,
  savedFilename,
  fileType = "CV",
}) => {
  const { token, refreshToken } = useUserData();
  const [errorMessage, setErrorMessage] = useState("");
  const [fileState, setFileState] = useState(hasCv ? "uploaded" : "no_file"); // 'no_file', 'uploading', 'uploaded'
  const [filename, setFilename] = useState(savedFilename || "");

  const handleFileChange = (file) => {
    console.log("handleFileChange: ", file);
    const filename = file.name;

    setFilename(filename);
    handleFileUpload(file, filename);
  };

  const handleFileUpload = async (file, filename) => {
    setFileState("uploading");

    const reader = new FileReader();
    const authToken = await (isTokenExpired(token)
      ? refreshToken()
      : Promise.resolve(token));

    reader.onload = function (evt) {
      const fileContent = evt.target.result;

      const re = /^data:application\/pdf;base64,/;
      const type = fileType === "CV" ? "cv" : "coverLetter";
      const endpoint = `${URL_UPDATE_USER_INFO}?type=${type}&userUid=${userId}&filename=${filename}`;
      const bodyContent = fileContent.replace(re, "");
      const headers = {
        headers: {
          "Content-Type": "application/json",
          Authorization: authToken,
        },
      };

      axios
        .post(endpoint, bodyContent, headers)
        .then(() => {
          setFileState("uploaded");
          onSuccess();
        })
        .catch((error) => {
          console.error("There was an error uploading the file!", error);
          setFileState("no_file");
          setErrorMessage(
            error.message || "An error occurred during file upload."
          );
        });
    };

    reader.onerror = function (evt) {
      console.error("An error occurred reading the file", evt);
      setFileState("no_file");
      setErrorMessage("An error occurred while reading the file.");
    };

    reader.readAsDataURL(file);
  };

  const handleReplaceClick = () => {
    console.log("replace was clicked");
    setErrorMessage("");
    setFileState("no_file");
    setTimeout(() => {
      document.getElementById("cvFileUpload").click();
    }, 300);
  };

  const renderCvUploadForm = () => {
    const shouldRender = fileState === "no_file";
    if (!shouldRender) return;

    return (
      <div className="flex flex-col items-center space-y-2">
        <CvUploadForm onFileChange={handleFileChange} filename={filename} />
      </div>
    );
  };

  const renderUploading = () => {
    const shouldRender = fileState === "uploading";
    if (!shouldRender) return;

    const msg = `Uploading ${fileType === "CV" ? "CV " : "Cover Letter"}`;
    return <IconLoading message={msg} />;
  };

  const renderSuccess = () => {
    const shouldRender = fileState === "uploaded";
    if (!shouldRender) return;

    return (
      <div className="flex flex-col align-center items-center space-y-2">
        {renderFileInfo()}
        {renderSuccessMessage()}
      </div>
    );
  };

  const renderError = () => {
    const shouldRender = Boolean(errorMessage);
    if (!shouldRender) return;

    return (
      <div className="flex flex-col align-center items-center space-y-2">
        {renderFileInfo()}
        {renderErrorMessage()}
        {errorMessage}
      </div>
    );
  };

  const renderFileInfo = () => (
    <div className="flex flex-row items-center">
      <img src={FileUploadIcon} width="40" />
      <p>{filename}</p>
    </div>
  );

  const renderErrorMessage = () => {
    return (
      <div className="flex flex-row items-center">
        <CheckCircleIcon color="red.500" style={{ width: 40 }} boxSize={5} />
        <span className="">
          Error uploading file. Please{" "}
          <span
            className={"border-b text-main-blue border-main-blue"}
            onClick={() => setErrorMessage("") && setFileState("no_file")}
          >
            try again
          </span>{" "}
          or{" "}
          <NavLink
            to="/contact"
            className={"border-b text-main-blue border-main-blue"}
          >
            contact us
          </NavLink>
          .
        </span>
      </div>
    );
  };

  const renderSuccessMessage = () => {
    return (
      <div className="flex flex-col items-center pt-4">
        <span className="text-center">
          {`Your ${fileType} has been uploaded successfully.`}
        </span>
        <span className="text-center">
          If you want, you can{" "}
          <span
            className={"border-b text-main-blue border-main-blue"}
            onClick={handleReplaceClick}
          >
            replace it
          </span>
          .
        </span>
      </div>
    );
  };

  return (
    <div className="flex flex-col border-2 border-gray-600 border-dashed rounded-md justify-center py-8 md:py-16">
      <div className="flex flex-col">
        {renderCvUploadForm()}
        {renderSuccess()}
        {renderUploading()}
        {renderError()}
      </div>
    </div>
  );
};
