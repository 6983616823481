import {
  Button,
  FormControl,
  FormLabel,
  Input,
  Textarea,
} from "@chakra-ui/react";
import { useEffect, useState } from "react";

const jsonReqAttr = [
  "companyName",
  "companyDescription",
  "jobTitle",
  "jobDescription",
  "applicationUrl",
];

export const SubmissionAddForm = ({ onSubmit }) => {
  const [companyName, setCompanyName] = useState("");
  const [companyDescription, setCompanyDescription] = useState("");
  const [jobTitle, setJobTitle] = useState("");
  const [jobDescription, setJobDescription] = useState("");
  const [applicationUrl, setApplicationUrl] = useState("");
  const [companyUrl, setCompanyUrl] = useState("");
  const [salary, setSalary] = useState("");
  const [json, setJson] = useState("");
  const [isValidJson, setIsValidJson] = useState(false);

  const handleSubmit = () => {
    onSubmit({
      companyName,
      companyDescription,
      jobTitle,
      jobDescription,
      applicationUrl,
      companyUrl,
      salary,
      json,
    });
  };

  const isSubmitEnabled =
    jobTitle && jobDescription && companyName && applicationUrl && companyUrl;

  useEffect(() => {
    if (!json) {
      setIsValidJson(false);
    }

    try {
      const valJson = JSON.parse(json);
      const hasAllRequiredAttributes = jsonReqAttr.every((attr) => {
        return valJson.hasOwnProperty(attr) && valJson[attr].trim() !== "";
      });

      setIsValidJson(hasAllRequiredAttributes);
    } catch (e) {
      console.log("Error parsing suggestion JSON", e);
      setIsValidJson(false);
    }
  }, [json]);

  const Explainer = () => (
    <div className="text-sm">
      <p>Insert suggestion by completing the form OR using JSON.</p>
      <p>If using JSON, the required payload is: </p>
      <pre className="text-xs text-wrap bg-gray-200">{`{"companyName": "","companyDescription": "","jobTitle": "","jobDescription": "","applicationUrl": ""}`}</pre>
    </div>
  );

  return (
    <>
      <Explainer />
      <FormControl isRequired>
        <FormLabel className="pt-4">Job Title</FormLabel>
        <Input
          value={jobTitle}
          onChange={(e) => setJobTitle(e.target.value)}
          placeholder="Enter job title"
        />
      </FormControl>

      <FormControl mt={4} isRequired>
        <FormLabel>Job Description</FormLabel>
        <Textarea
          value={jobDescription}
          onChange={(e) => setJobDescription(e.target.value)}
          placeholder="Enter job description"
          rows={1}
        />
      </FormControl>

      <FormControl isRequired>
        <FormLabel>Company Name</FormLabel>
        <Input
          value={companyName}
          onChange={(e) => setCompanyName(e.target.value)}
          placeholder="Enter company name"
        />
      </FormControl>

      <FormControl mt={4} isRequired>
        <FormLabel>Company Description</FormLabel>
        <Textarea
          value={companyDescription}
          onChange={(e) => setCompanyDescription(e.target.value)}
          placeholder="Enter company description"
          rows={1}
        />
      </FormControl>

      <FormControl mt={4} isRequired>
        <FormLabel>Application URL</FormLabel>
        <Input
          value={applicationUrl}
          onChange={(e) => setApplicationUrl(e.target.value)}
          placeholder="Enter application URL"
        />
      </FormControl>

      <FormControl mt={4} isRequired>
        <FormLabel>Company URL</FormLabel>
        <Input
          value={companyUrl}
          onChange={(e) => setCompanyUrl(e.target.value)}
          placeholder="Enter company URL"
        />
      </FormControl>

      <FormControl mt={4}>
        <FormLabel>Salary (optional)</FormLabel>
        <Input
          value={salary}
          onChange={(e) => setSalary(e.target.value)}
          placeholder="Enter salary"
          type="text"
        />
      </FormControl>

      <p className="pt-4 font-bold">OR</p>

      <FormControl mt={4}>
        <FormLabel>JSON</FormLabel>
        <Input
          value={json}
          onChange={(e) => setJson(e.target.value)}
          placeholder="Enter JSON"
          type="text"
        />
      </FormControl>

      <div className="flex my-4 justify-center">
        <Button
          colorScheme="green"
          onClick={handleSubmit}
          isDisabled={!isSubmitEnabled && !isValidJson}
        >
          Submit
        </Button>
      </div>
    </>
  );
};
