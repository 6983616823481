import axios from "axios";
import React, { createContext, useContext, useState, useEffect } from "react";
import { URL_GET_IP, URL_IP_INFO } from "../config";

const LocationContext = createContext();

export const LocationProvider = ({ children }) => {
  const [ip, setIp] = useState(null);
  const [location, setLocation] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchLocationData = async () => {
      try {
        setIsLoading(true);

        // Step 1: Get the client's IP address from ipify
        const ipResponse = await axios.get(URL_GET_IP);
        const ip = ipResponse?.data?.ip;
        setIp(ip);

        // Step 2: Get the geolocation data from the backend using the IP address
        const res = await axios.post(URL_IP_INFO, { ip });
        const data = res?.data;

        setLocation(data?.location);
      } catch (error) {
        setError(error);
        console.error("Error fetching location data:", error);
      } finally {
        setIsLoading(false);
      }
    };

    if (!ip && !location) {
      fetchLocationData();
    }
  }, [ip, location]);

  return (
    <LocationContext.Provider value={{ ip, location, isLoading, error }}>
      {children}
    </LocationContext.Provider>
  );
};

export const useLocationInfo = () => useContext(LocationContext);
