import { navBarMl, navBarMr } from "../../assets/classnames";
import { dataTerms } from "./dataTaC";

const TermsSubsection = ({ title, content }) => (
  <div className="ml-4 sm:ml-10 mt-3">
    <h3 className="text-md font-semibold">{title}</h3>
    <p className="mt-1">{content}</p>
  </div>
);

const TermsSection = ({ title, content, subsections }) => (
  <div className="my-5">
    <h2 className="text-xl font-bold">{title}</h2>
    <p className="mt-2">{content}</p>
    {subsections &&
      subsections.map((sub, index) => <TermsSubsection key={index} {...sub} />)}
  </div>
);

export const TermsAndConditions = () => (
  <div className={`bg-gray-600 min-h-screen flex rounded-md ${navBarMl} ${navBarMr}`}>
    <div className="p-4 md:p-12 text-white">
      <h1 className="text-2xl font-bold">Terms and Conditions</h1>
      {dataTerms.map((term, index) => (
        <TermsSection key={index} {...term} />
      ))}
      <div className="mt-8 text-center">
        <a href="/" className=" border-b-2 border-gray-400">
          Go back home
        </a>
      </div>
    </div>
  </div>
);
