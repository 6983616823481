import React, { useState, useEffect } from "react";
import { CardSubmission } from "./CardSubmission";
import { stylePageTitle } from "../../assets/classnames";
import { useSubmissionsApi } from "../../context/useSubmissionsApi";
import { useUserData } from "../../context/useUserData";
import { AlertAll } from "../AlertMessage";
import { useNavigate, useParams } from "react-router-dom";
import { CardSuggestionsSimplified } from "../../routes/user-profile/Cards";
import { ButtonCustom } from "../cta/ButtonCustom";
import { SortDropdown, sortSuggestions } from "./SortDropdown";

export const NewSuggestionsPage = () => {
  const navigate = useNavigate();
  const { idUser } = useParams();
  const { isRecruiter, userData, getCustomerData } = useUserData();
  const {
    isLoading,
    isUpdating,
    rejectSuggestion,
    fetchSuggestions,
    completeSubmission,
    approveSuggestion,
    approveAllSuggestions,
  } = useSubmissionsApi();

  const [suggestions, setSuggestions] = useState([]);
  const [customerData, setCustomerData] = useState();
  const [currentSort, setCurrentSort] = useState("most_recent");
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(null);

  console.log("new Suggestions Page", {
    isLoading,
    isUpdating,
    error,
    success,
    suggestions,
    userData,
    customerData,
  });

  const onGetSuggestions = (res) => {
    setSuggestions(sortSuggestions(res?.data?.docList));
  };

  useEffect(() => {
    setError(false);
    fetchSuggestions(idUser, onGetSuggestions, onError);
    getCustomerData(
      idUser,
      (res) => setCustomerData(res?.data),
      () => {}
    );
  }, [idUser]);

  useEffect(() => {
    if (isUpdating) return;

    setError(false);
    fetchSuggestions(idUser, onGetSuggestions, onError);
  }, [isUpdating]);

  useEffect(() => {
    // set a timer to reset the success/error states
    if (!success && !error) return; // Do nothing if both are false

    const timer = setTimeout(() => {
      setError(false);
      setSuccess(false);
    }, 7000);

    // Clear the timeout if the component unmounts or if success/error change
    return () => clearTimeout(timer);
  }, [success, error]);

  const onError = (e) => setError(e);
  const onSuccess = () => setSuccess(true);

  const onCompleteSuggestion = (data) => {
    console.log("inside onCompleteSuggestion", data);
    completeSubmission(data, onSuccess, onError);
  };

  const onApproveSuggestion = (data) => {
    console.log("inside onApproveSuggestion", data);
    approveSuggestion({ ...data, idUser }, onSuccess, onError);
  };

  const onRejectSuggestion = (data, msg) => {
    console.log("inside onRejectSuggestion", { data, msg });
    const rejectedBy = isRecruiter
      ? userData?.displayName
      : customerData?.displayName;
    rejectSuggestion(data, msg, idUser, rejectedBy, onSuccess, onError);
  };

  const onApproveAllSuggestions = () => {
    console.log("inside onApproveAllSuggestions");
    approveAllSuggestions(idUser, onSuccess, onError);
  };

  const title = () => {
    if (isRecruiter) return `Suggestions - ${customerData?.displayName}`;
    return "Suggestions";
  };

  const TitleContent = () => (
    <>
      <div className={`${stylePageTitle} text-main-blue`}>{title()}</div>
      <p className="text-md max-w-3xl pb-4">
        These are the applications we are going to submit on your behalf.
        <br className="" /> If you don't like any of the suggestions below,
        please discard them by clicking on the bin icon.
      </p>
    </>
  );

  const AlertContent = () => (
    <AlertAll
      error={error}
      success={success}
      isUpdating={isLoading || isUpdating}
      msgLoading={isLoading ? "Loading suggestions" : "Processing..."}
      msgError="Error loading suggestions"
      msgSuccess="Success"
    />
  );

  const SuggestionsContent = () => {
    if (suggestions.length === 0) return;

    const sortedSuggestions = sortSuggestions(suggestions, currentSort);

    return (
      <div>
        <div className="space-y-2">
          {sortedSuggestions.map((dataSuggestion, index) => (
            <CardSubmission
              data={dataSuggestion}
              isRecruiter={isRecruiter}
              onReject={onRejectSuggestion}
              onSubmit={onCompleteSuggestion}
              onApproveSuggestion={onApproveSuggestion}
              key={`card-submission-${index}`}
            />
          ))}
        </div>
      </div>
    );
  };

  const NoSuggestionsContent = () => {
    if (suggestions.length > 0) return;

    return <CardSuggestionsSimplified />;
  };

  const BackHomeContent = () => (
    <div className="flex justify-center pt-12">
      <ButtonCustom
        text="Back Home"
        onClick={() => navigate("/profile")}
        isHighlight={false}
      />
    </div>
  );

  const ApproveSuggestions = () => {
    if (isRecruiter) return;

    return (
      <div className="py-4">
        <div className="">
          If you have reviewed all suggestions, you can approve them by clicking
          the button bellow.
          <br />
          You can also approve each suggestion individually, by clicking on the
          check icon.
        </div>
        <ButtonCustom
          onClick={onApproveAllSuggestions}
          text="Approve All"
          isHighlight={true}
          style="mt-2"
        />
      </div>
    );
  };

  return (
    <div>
      <TitleContent />
      <ApproveSuggestions />
      <AlertContent />
      <SortDropdown currentSort={currentSort} onSortChange={setCurrentSort} />
      <SuggestionsContent />
      <NoSuggestionsContent />
      <BackHomeContent />
    </div>
  );
};
