import React, { forwardRef } from "react";
import IconUser from "../../assets/images/icon-user.png";
import { styleContainerLogin, styleIconLogin} from "../../assets/classnames";

export const UserIcon = forwardRef((props, ref) => (
  <div
    ref={ref}
    className={styleContainerLogin}
    {...props}
  >
    <img className={styleIconLogin} src={IconUser} alt="User Icon" />
    <span className="text-white sm:pl-1">{props?.name}</span>
  </div>
));

UserIcon.displayName = "UserIcon";
