import React from "react";
import { ChevronLeftIcon, ChevronRightIcon } from "@chakra-ui/icons";

const btnStyle =
  "flex flex-row items-center w-40 md:w-32 justify-center py-1 border-2 border-gray-400 text-lg font-medium rounded-md  bg-white hover:bg-main-blue text-black";

const ButtonPrevious = ({ onClick }) => (
  <div className={btnStyle} onClick={onClick}>
    <ChevronLeftIcon boxSize={6} />
    <p className="pr-2">Previous</p>
  </div>
);

const ButtonNext = ({ onClick, labelNext }) => (
  <div className={btnStyle} onClick={onClick}>
    <p className="pl-2">{labelNext}</p>
    <ChevronRightIcon boxSize={6} />
  </div>
);

export const PreviousAndNext = ({
  showPrevious = true,
  showNext = true,
  labelNext = "Next",
  onClickPrevious,
  onClickNext,
}) => {
  return (
    <div
      className={`flex flex-row ${
        showPrevious && showNext ? "justify-between" : "justify-end"
      } md:justify-end space-x-2 md:space-x-4 md:pt-4`}
    >
      {showPrevious && <ButtonPrevious onClick={onClickPrevious} />}
      {showNext && <ButtonNext onClick={onClickNext} labelNext={labelNext} />}
    </div>
  );
};
