import React from "react";
import GoogleIcon from "../../assets/images/google-icon.webp";
import { authGoogle } from "../../hooks/authGoogle";
import { ButtonWithImage } from "../cta/ButtonWithImage";
import { useNavigate } from "react-router-dom";

export const SignInWithGoogle = ({
  redirectProfile = true,
  buttonText = "Sign in with Google",
}) => {
  const { logIn } = authGoogle();
  const navigate = useNavigate();

  const handleLogin = async (redirectProfile) => {
    await logIn();
    if (redirectProfile) {
      navigate("/profile");
    }
  };

  return (
    <ButtonWithImage
      onClick={() => handleLogin(redirectProfile)}
      imgSrc={GoogleIcon}
      imgAlt="Google"
      buttonText={buttonText}
    />
  );
};
