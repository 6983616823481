import React, { useEffect, useState } from "react";
import { useUserData } from "../../context/useUserData";
import { useNavigate } from "react-router-dom";
import { HeaderRegistration } from "./HeaderRegistration";
import { URL_UPDATE_USER_INFO, urlRegistration } from "../../config";
import { styleWidthJobTitles } from "../../assets/classnames";
import { useProgress } from "../../context/useProgress";
import { ListInput } from "./ListInput";
import { RegistrationContainer } from "./RegistrationContainer";
import { useSuccess } from "../../context/useSucess";

const title = "Set job titles";
const subTitle =
  "Choose up to 5 job titles. This information can be edited later.";

export const RegistrationJobTitles = ({}) => {
  const navigate = useNavigate();

  const { userData, updateUserData } = useUserData();
  const { increaseProgress, decreaseProgress, stepCompleted } = useProgress();
  const { success, setSuccess, setTargetUrl, countdown } = useSuccess();

  const [jobTitles, setJobTitles] = useState([]);
  const [hasChanged, setHasChanged] = useState(false);
  const [error, setError] = useState(null);

  const hasBeenCompleted = stepCompleted > 1;
  const showNext = hasChanged || hasBeenCompleted;

  useEffect(() => {
    setJobTitles(userData?.jobTitles || []);
  }, [userData]);

  const onSuccess = () => {
    setTargetUrl(urlRegistration.locationPreferences);
    setSuccess(true);
  };

  const onClickPrevious = () => {
    navigate(urlRegistration.cvUpload);
    window.scrollTo(0, 0);
    decreaseProgress();
  };

  const handleUpdateJobTitles = (titleList) => {
    setJobTitles(titleList);
    setHasChanged(true);
  };

  const onClickNext = () => {
    console.log("jobTitles submit triggered", jobTitles);

    if (jobTitles.length === 0) {
      setError("You must select at least one job title");
      return;
    }

    if (!hasChanged) {
      navigate(urlRegistration.locationPreferences);
      window.scrollTo(0, 0);
      increaseProgress();
      return
    }

    const endpoint = `${URL_UPDATE_USER_INFO}?type=jobTitles&userUid=${userData.uid}`;
    const data = { jobTitles };

    const onOk = () => {
      setError(null);
      onSuccess();
    };
    const onError = () => setError("Error updating. Please try again.");

    setError("");
    updateUserData(endpoint, data, onOk, onError);
  };

  return (
    <div className="">
      <HeaderRegistration />
      <RegistrationContainer
        error={error}
        title={title}
        subTitle={subTitle}
        showNext={showNext}
        onClickPrevious={onClickPrevious}
        onClickNext={onClickNext}
        success={success}
        countdown={countdown}
      >
        <ListInput
          items={jobTitles}
          setItems={handleUpdateJobTitles}
          stylesContainer={styleWidthJobTitles}
          placeholderText="Enter job title"
        />
      </RegistrationContainer>
    </div>
  );
};
