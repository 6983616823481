import { Layout } from "../components/Layout";
import { UserRoute } from "../routes/UserRoute";
import { LocationProvider } from "./useLocation";
import { ProgressProvider } from "./useProgress";
import { SubmissionsApiProvider } from "./useSubmissionsApi";
import { SuccessProvider } from "./useSucess";

export const ProviderRegistration = ({ children }) => (
  <Layout center={false}>
    <UserRoute>
      <ProgressProvider>
        <SuccessProvider>{children}</SuccessProvider>
      </ProgressProvider>
    </UserRoute>
  </Layout>
);

export const ProviderSubmissions = ({ children }) => (
  <Layout center={false}>
    <UserRoute>
      <SubmissionsApiProvider>{children}</SubmissionsApiProvider>
    </UserRoute>
  </Layout>
);

export const ProviderCheckout = ({ children }) => (
  <LocationProvider>{children}</LocationProvider>
);
