import React, { useState } from "react";
import { Button } from "@chakra-ui/react";
import { CookieSettingsModal } from "./CookieSettingsModal";
import { allCookies, useCookiePreferences } from "../../context/useCookies";

export const CookieBanner = () => {
  const [_, savePreferences, shouldDisplayBanner, setShouldDisplayBanner] =
    useCookiePreferences();
  const [isModalOpen, setModalOpen] = useState(false);

  const handleClose = (success = true) => {
    setModalOpen(false);
    if (success) {
      setShouldDisplayBanner(false);
    }
  };

  const handleAcceptAll = () => {
    savePreferences(allCookies);
    handleClose();
  };

  if (!shouldDisplayBanner) {
    return null;
  }

  if (isModalOpen) {
    return <CookieSettingsModal onClose={handleClose} />;
  }

  return (
    <div className="fixed inset-x-0 bottom-0 bg-white p-4 pt-6 shadow-md text-center z-50">
      <div className="text-gray-800">
        We use cookies to improve your experience. By continuing, you agree to
        our{" "}
        <a href="/policies/cookies" className="border-b-2 border-gray-400">
          Cookie Policy
        </a>{" "}
        and{" "}
        <a
          href="/policies/terms-and-conditions"
          className="border-b-2 border-gray-400"
        >
          Terms and Conditions
        </a>
        .
      </div>
      <div className="flex justify-center mt-4 space-x-2">
        <Button
          variant="outline"
          colorScheme="blue"
          onClick={() => setModalOpen(true)}
        >
          Manage
        </Button>
        <Button
          colorScheme="blue"
          className="mx-3"
          style={{ padding: "0 1.5em", fontWeight: "bold" }}
          onClick={handleAcceptAll}
        >
          Accept
        </Button>
      </div>
    </div>
  );
};
