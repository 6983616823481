import React, { useEffect, useState } from "react";
import { useUserData } from "../../context/useUserData";
import { useNavigate } from "react-router-dom";
import { HeaderRegistration } from "./HeaderRegistration";
import { URL_UPDATE_USER_INFO, urlRegistration } from "../../config";
import { styleWidthJobTitles } from "../../assets/classnames";
import { useProgress } from "../../context/useProgress";
import { ListInput } from "./ListInput";
import { Checkbox } from "../../components/forms/Checkbox";
import { RegistrationContainer } from "./RegistrationContainer";
import { useSuccess } from "../../context/useSucess";

const title = "Set location preferences";
const subTitle = "Choose up to 5 locations where you would like to work.";

export const RegistrationLocations = ({}) => {
  const navigate = useNavigate();

  const { userData, updateUserData } = useUserData();
  const { increaseProgress, decreaseProgress, stepCompleted } = useProgress();
  const { success, setSuccess, setTargetUrl, countdown } = useSuccess();

  const [locations, setLocations] = useState([]);
  const [remoteStatus, setRemoteStatus] = useState(false);
  const [hybridStatus, setHybridStatus] = useState(false);
  const [onSiteStatus, setOnSiteStatus] = useState(true);
  const [hasChanged, setHasChanged] = useState(false);
  const [error, setError] = useState(null);

  console.log("locations: ", { onSiteStatus, hybridStatus, remoteStatus, userData });
  const hasBeenCompleted = stepCompleted > 2;
  const showNext = hasChanged || hasBeenCompleted;

  useEffect(() => {
    if (!userData) return 
    setLocations(userData?.locations || []);

    const { onSiteOk, remoteOk, hybridOk } = userData;
    typeof(onSiteOk) === 'boolean' && setOnSiteStatus(onSiteOk);
    typeof(remoteOk) === 'boolean' && setRemoteStatus(remoteOk);
    typeof(hybridOk) === 'boolean' && setHybridStatus(hybridOk);
  }, [userData]);

  const onSuccess = () => {
    setTargetUrl(urlRegistration.otherPreferences);
    setSuccess(true);
  };

  const onClickPrevious = () => {
    navigate(urlRegistration.jobTitles);
    window.scrollTo(0, 0);
    decreaseProgress();
  };

  const handleUpdateLocations = (list) => {
    setLocations(list);
    setHasChanged(true);
  };

  const onClickNext = () => {
    console.log("locationPreferences submit triggered", {
      hasChanged,
      locations,
      hybridStatus,
      remoteStatus,
      onSiteStatus,
    });

    if (locations.length === 0 && !hybridStatus && !remoteStatus) {
      setError("You must select at least one location, or allow remote work.");
      return;
    }

    if (locations.length === 0 && (hybridStatus || onSiteStatus)) {
      setError(
        "You must select at least one location for onsite or hybrid work."
      );
      return;
    }

    if (!hasChanged) {
      navigate(urlRegistration.otherPreferences);
      window.scrollTo(0, 0);
      increaseProgress();
      return;
    }

    const endpoint = `${URL_UPDATE_USER_INFO}?type=locationPreferences&userUid=${userData?.uid}`;
    const data = {
      onSiteOk: onSiteStatus,
      remoteOk: remoteStatus,
      hybridOk: hybridStatus,
      locations,
    };

    const onOk = () => {
      setError(null);
      onSuccess();
    };
    const onError = () => setError("Error updating. Please try again.");

    setError("");
    updateUserData(endpoint, data, onOk, onError);
  };

  const mainContent = () => {
    return (
      <div className="grid grid-cols-1 md:grid-cols-3">
        <div id="list-child" className="col-span-2">
          <ListInput
            items={locations}
            setItems={handleUpdateLocations}
            stylesContainer={styleWidthJobTitles}
            placeholderText="Enter location"
          />
        </div>
        <div
          id="checkbox-child"
          className="flex flex-col space-y-4 md:pl-2 pt-4 md:pt-0"
        >
          <p>I'm open to work:</p>
          <Checkbox
            isChecked={onSiteStatus}
            label="Onsite"
            onCheckboxChange={() => {
              setOnSiteStatus(!onSiteStatus);
              setHasChanged(true);
            }}
          />
          <Checkbox
            isChecked={hybridStatus}
            label="Hybrid"
            onCheckboxChange={() => {
              setHybridStatus(!hybridStatus);
              setHasChanged(true);
            }}
          />
          <Checkbox
            isChecked={remoteStatus}
            label="Remote"
            onCheckboxChange={() => {
              setRemoteStatus(!remoteStatus);
              setHasChanged(true);
            }}
          />
        </div>
      </div>
    );
  };

  return (
    <div className="">
      <HeaderRegistration />
      <RegistrationContainer
        error={error}
        title={title}
        subTitle={subTitle}
        showNext={showNext}
        onClickPrevious={onClickPrevious}
        onClickNext={onClickNext}
        success={success}
        countdown={countdown}
      >
        {mainContent()}
      </RegistrationContainer>
    </div>
  );
};
