import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { SignInUserIcon } from "../signin/SignInUserIcon";
import { useNavigate } from "react-router-dom";
import { useUserData } from "../../context/useUserData";
import { navBarMl, navBarMr } from "../../assets/classnames";
import { desktopLinks, mobileLinks } from "./links";
import { ToggleButton } from "./ToggleButton";
import { DesktopMenu } from "./DesktopMenu";
import { MobileMenu } from "./MobileMenu";


export default function Navigation() {
  const [isOpen, setIsOpen] = useState(false);
  const [activeDropdown, setActiveDropdown] = useState(null);
  const { userData, loading } = useUserData();
  const navigate = useNavigate();

  const toggleMenu = () => setIsOpen(!isOpen);

  const handleDropdownClick = (linkName) => {
    setActiveDropdown(linkName === activeDropdown ? null : linkName);
  };

  const resetLocation = () => window.scrollTo(0, 0);
  const redirectToProfile = () => navigate("/profile");

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (!event.target.closest(".dropdown")) {
        setActiveDropdown(null);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <nav className="fixed top-0 left-0 w-full z-50 bg-gray-900 pt-2 sm:pt-6 lg:w-full">
      <div
        className={`flex items-center justify-between h-16 ${navBarMl} ${navBarMr}`}
      >
        <div className="w-20 sm:w-24 lg:hidden">
          <ToggleButton isOpen={isOpen} toggle={toggleMenu} />
        </div>
        <div className="flex items-center">
          <Link to="/" onClick={resetLocation}>
            <p className="text-white text-3xl hover:text-4xl font-black tracking-tight hover:underline">
              ezJobs
            </p>
          </Link>
        </div>
        <div className="w-20 sm:w-24 lg:w-fit flex flex-row items-center">
          <DesktopMenu
            links={desktopLinks}
            actionOnClick={resetLocation}
            activeDropdown={activeDropdown}
            handleDropdownClick={handleDropdownClick}
          />
          <SignInUserIcon
            isLoggedIn={Boolean(userData?.uid)}
            userName={userData?.displayName}
            onProfileClick={redirectToProfile}
            isLoading={loading}
          />
        </div>
      </div>
      <MobileMenu
        isOpen={isOpen}
        links={mobileLinks}
        actionOnClick={() => {
          setIsOpen(false);
          resetLocation();
        }}
      />
    </nav>
  );
}
