import React, { useState } from "react";
import { useUserData } from "../../context/useUserData";
import { useNavigate } from "react-router-dom";
import { HeaderRegistration } from "./HeaderRegistration";
import { PreviousAndNext } from "./PreviousAndNext";
import { URL_UPDATE_USER_INFO, urlRegistration } from "../../config";
import {
  registrationSubTitle,
  registrationTitle,
  styleRegistrationContentContainer,
} from "../../assets/classnames";
import { IconLoading } from "../../components/IconLoading";
import { useProgress } from "../../context/useProgress";
import { AlertAll } from "../../components/AlertMessage";
import { useSuccess } from "../../context/useSucess";
import { ProfileInfo } from "./ProfileInfo";

export const RegistrationReview = ({}) => {
  const navigate = useNavigate();
  const { userData, isUpdating, updateUserData, loading } = useUserData();
  const { decreaseProgress } = useProgress();
  const { success, setSuccess, setTargetUrl, countdown } = useSuccess();

  const [error, setError] = useState("");

  const onClickPrevious = () => {
    decreaseProgress();
    navigate(urlRegistration.otherPreferences);
    window.scrollTo(0, 0);
  };

  const onSuccess = () => {
    setTargetUrl("/profile");
    setSuccess(true);
  };

  const handleSubmit = () => {
    const endpoint = `${URL_UPDATE_USER_INFO}?type=registrationComplete&userUid=${userData.uid}`;

    const onOk = () => {
      setError("");
      onSuccess();
    };
    const onError = () => setError("error");

    updateUserData(endpoint, {}, onOk, onError);
  };

  if (loading) return <IconLoading />;

  return (
    <div className="">
      <HeaderRegistration />
      <div className={styleRegistrationContentContainer}>
        <div className="">
          <p className={registrationTitle}>Review & Confirm</p>
          <p className={registrationSubTitle}>
            Please make sure all data is correct.
          </p>
          <ProfileInfo userData={userData} styleContainer="pb-4" />
        </div>

        <AlertAll
          isUpdating={isUpdating}
          msgLoading="Submitting data"
          error={error}
          msgError={`There was an error submitting your data: ${error}`}
          success={success}
          msgSuccess={`Success updating your details. Redirecting in ${countdown}`}
        />

        <div className="">
          <PreviousAndNext
            showPrevious={true}
            onClickPrevious={onClickPrevious}
            showNext={true}
            labelNext="Submit"
            onClickNext={handleSubmit}
          />
        </div>
      </div>
    </div>
  );
};
