import { Bars3Icon, XMarkIcon } from "@heroicons/react/24/solid";

export const ToggleButton = ({ isOpen, toggle }) => (
  <button onClick={toggle} className="lg:hidden" aria-label="Toggle Navigation">
    {isOpen ? (
      <XMarkIcon className="h-6 w-6 text-white" />
    ) : (
      <Bars3Icon className="h-6 w-6 text-white" />
    )}
  </button>
);
