export const listIsEqual = (a, b) => {
  const sortedA = [...a].sort();
  const sortedB = [...b].sort();
  return (
    sortedA.length === sortedB.length &&
    sortedA.every((val, index) => val === sortedB[index])
  );
};

export const timestampToDate = (tsSeconds) => {
  const date = new Date(tsSeconds * 1000); // Convert to milliseconds

  const formattedDate = date.toLocaleDateString("en-US", {
    year: "numeric",
    month: "long",
    day: "numeric",
  });

  return formattedDate;
};

export const capitalize = (str) => {
  if (str.length === 0) return str;

  return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
};
