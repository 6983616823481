export const dataTerms = [
  {
    title: "1. Acceptance of Terms",
    content:
      "By using our services, you confirm that you accept these Terms and that you agree to comply with them. If you do not agree to these Terms, you must not use our services.",
  },
  {
    title: "2. No-Responsibility Clause",
    content:
      "We provide a platform for job application facilitation. While we strive to find relevant job openings and apply on your behalf, we do not guarantee job placement, interview selection, or the outcome of any job application. We are not liable for any actions taken by third-party job providers, including rejections or temporary blocking of your profile.",
  },
  {
    title: "3. Intellectual Property Rights",
    content:
      "All content on this Website, including text, graphics, logos, images, and software, is the property of the Company or its licensors and is protected by copyright and intellectual property laws. You may not use any content from our Website without our prior written permission.",
  },
  {
    title: "4. Digital Millennium Copyright Act (DMCA) Notice and Policy",
    content:
      "Notifications of claimed copyright infringement should be sent to our designated agent. To be effective, the notification must be in writing and contain the following information: A physical or electronic signature of a person authorized to act on behalf of the owner of an exclusive right that is allegedly infringed; Identification of the copyrighted work claimed to have been infringed; Identification of the material that is claimed to be infringing or to be the subject of infringing activity.",
  },
  {
    title: "5. Third Parties Clause",
    content:
      "Our service may contain links to third-party websites or services that are not owned or controlled by the Company. We assume no responsibility for the content, policies, or practices of any third-party websites or services.",
  },
  {
    title: "6. Suspension and Termination Clause",
    content:
      "We may suspend or terminate your access to our services without notice or liability, for any reason whatsoever, including without limitation if you breach these Terms.",
  },
  {
    title: "7. Pricing and Payment Terms",
    content:
      "Fees for our services are due in accordance with the subscription plan selected (weekly, monthly, or other durations). These fees are subject to change with prior notice to you.",
  },
  {
    title: "8. Refund Policy",
    content:
      "If you are dissatisfied with our service, you may request a refund within a specified period as detailed on our Website. We reserve the right to accept or deny refund requests at our discretion.",
  },
  {
    title: "9. Privacy Policy",
    content:
      "Your privacy is important to us. The following subsections detail how we handle your personal information.",
    subsections: [
      {
        title: "9.1 Collection of Information",
        content:
          "We collect personal information that you provide directly through our service, such as your full name, contact information, and CV.",
      },
      {
        title: "9.2 Use of Information",
        content:
          "Your information is used specifically for the purpose of job application processing and cover letter generation.",
      },
      {
        title: "9.3 Disclosure of Information",
        content:
          "We do not sell or rent your personal information. It is disclosed to third parties only as necessary for job application purposes.",
      },
      {
        title: "9.4 Data Protection",
        content:
          "We take the protection of your data seriously and implement a variety of security measures to maintain the safety of your personal information.",
      },
      {
        title: "9.5 Access and Control of Your Information",
        content:
          "You have the right to access, correct, or request deletion of your personal information at any time. Please contact us to exercise these rights.",
      },
      {
        title: "9.6 Retention of Information",
        content:
          "We retain your personal information only for as long as is necessary for the purposes set out in these Terms.",
      },
      {
        title: "9.7 Changes to This Privacy Policy",
        content:
          "We may update our Privacy Policy from time to time. We will notify you of any changes by posting the new Privacy Policy on this page.",
      },
    ],
  },
  {
    title: "10. Governing and Applicable Laws",
    content:
      "These Terms shall be governed and construed in accordance with the laws of the United Kingdom, without regard to its conflict of law provisions.",
  },
  {
    title: "11. Corrections and Inaccuracies",
    content:
      "We aim to keep our Website updated and accurate, but we cannot guarantee that all content is free of errors or omissions. We reserve the right to correct any errors, inaccuracies, or omissions and to change or update information at any time without prior notice.",
  },
  {
    title: "12. Modifications and Interruptions",
    content:
      "We reserve the right to modify or discontinue all or part of our services without notice at any time. We will not be liable to you or any third party for any modification, price change, suspension, or discontinuance of our service.",
  },
  {
    title: "13. Contact Information",
    content:
      "If you have any questions about these Terms, please contact us at: support@getezjobs.com",
  },
];
