import React, { createContext, useContext, useState } from "react";
import {
  URL_APPROVE_ALL_SUGGESTIONS,
  URL_APPROVE_SUGGESTION,
  URL_DISCARDED_LIST,
  URL_SUBMISSIONS_ADD,
  URL_SUBMISSIONS_LIST,
  URL_SUBMISSIONS_REJECT,
  URL_SUGGESTIONS_LIST,
} from "../config";
import { useAxiosPost } from "../hooks/useAxios";

const SubmissionsApiContext = createContext();

export const SubmissionsApiProvider = ({ children }) => {
  const { axiosPost } = useAxiosPost();

  const [isLoading, setIsLoading] = useState(false);
  const [isUpdating, setIsUpdating] = useState(false);

  const fetchSuggestions = async (idUser, onSuccess, onError) => {
    console.log("inside fetchSuggestions", { idUser });

    const data = { idUser };
    await axiosPost(
      URL_SUGGESTIONS_LIST,
      data,
      () => setIsLoading(true),
      (res) => onSuccess(res),
      (e) => onError(e),
      () => setIsLoading(false)
    );
  };

  const fetchSubmissions = async (idUser, onSuccess, onError) => {
    console.log("inside fetchSubmissions", { idUser });

    const data = { idUser };
    await axiosPost(
      URL_SUBMISSIONS_LIST,
      data,
      () => setIsLoading(true),
      (res) => onSuccess(res),
      (e) => onError(e),
      () => setIsLoading(false)
    );
  };

  const fetchDiscarded = async (idUser, onSuccess, onError) => {
    console.log("inside fetchDiscarded", { idUser });

    const data = { idUser };
    await axiosPost(
      URL_DISCARDED_LIST,
      data,
      () => setIsLoading(true),
      (res) => onSuccess(res),
      (e) => onError(e),
      () => setIsLoading(false)
    );
  };

  const completeSubmission = async (submissionDoc, onSuccess, onError) => {
    console.log("inside completeSubmission", { submissionDoc });

    const data = {
      id: submissionDoc._id,
      additionalInfo: submissionDoc.additionalInfo,
    };
    await axiosPost(
      URL_SUBMISSIONS_ADD,
      data,
      () => setIsUpdating(true),
      (res) => onSuccess(res),
      (e) => onError(e),
      () => setIsUpdating(false)
    );
  };

  const approveSuggestion = async (doc, onSuccess, onError) => {
    console.log("inside approveSuggestion", doc);

    const data = { id: doc._id, idUser: doc.idUser };
    await axiosPost(
      URL_APPROVE_SUGGESTION,
      data,
      () => setIsUpdating(true),
      (res) => onSuccess(res),
      (e) => onError(e),
      () => setIsUpdating(false)
    );
  };

  const approveAllSuggestions = async (idUser, onSuccess, onError) => {
    console.log("inside approveAllSuggestions", { idUser });

    const data = { idUser };
    await axiosPost(
      URL_APPROVE_ALL_SUGGESTIONS,
      data,
      () => setIsUpdating(true),
      (res) => onSuccess(res),
      (e) => onError(e),
      () => setIsUpdating(false)
    );
  };

  const rejectSuggestion = async (
    submissionDoc,
    messageDiscard,
    idUser,
    rejectedBy,
    onSuccess,
    onError
  ) => {
    console.log("inside rejectSuggestion", { submissionDoc });

    const data = { id: submissionDoc._id, idUser, messageDiscard, rejectedBy };
    await axiosPost(
      URL_SUBMISSIONS_REJECT,
      data,
      () => setIsUpdating(true),
      (res) => onSuccess(res),
      (e) => onError(e),
      () => setIsUpdating(false)
    );
  };

  return (
    <SubmissionsApiContext.Provider
      value={{
        isLoading,
        isUpdating,
        completeSubmission,
        approveSuggestion,
        approveAllSuggestions,
        fetchSuggestions,
        fetchSubmissions,
        fetchDiscarded,
        rejectSuggestion,
      }}
    >
      {children}
    </SubmissionsApiContext.Provider>
  );
};

export const useSubmissionsApi = () => useContext(SubmissionsApiContext);
