import { signInWithPopup } from "firebase/auth";
import { auth, googleProvider } from "../firebaseConfig";
import { useAuth } from "../context/useAuth";

export const authGoogle = () => {
  const { updateToken } = useAuth();

  const logIn = async () => {
    try {
      console.log("logging in using google");

      const result = await signInWithPopup(auth, googleProvider);
      const token = await result.user.getIdToken();
      updateToken(token);

      console.log("Sign in success");
    } catch (error) {
      console.log("Sign in error: ", error);
      throw error;
    }
  };

  return { logIn };
};
