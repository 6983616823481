export const SortDropdown = ({ currentSort, onSortChange }) => {
  return (
    <select
      value={currentSort}
      onChange={(e) => onSortChange(e.target.value)}
      className="my-2 border rounded text-black"
    >
      <option value="most_recent">Most Recent</option>
      <option value="oldest">Oldest</option>
      <option value="highest_match_rate">Highest Match Rate</option>
      <option value="lowest_match_rate">Lowest Match Rate</option>
      <option value="job_title">Job Title (A-Z)</option>
      <option value="job_title_reverse">Job Title (Z-A)</option>
      <option value="company">Company (A-Z)</option>
      <option value="company_reverse">Company (Z-A)</option>
    </select>
  );
};

export const sortSuggestions = (suggestionsToSort, currentSort) => {
  return [...suggestionsToSort].sort((a, b) => {
    switch (currentSort) {
      case "most_recent":
        return new Date(b.createdAt) - new Date(a.createdAt);
      case "oldest":
        return new Date(a.createdAt) - new Date(b.createdAt);
      case "highest_match_rate":
        return b.matchRate - a.matchRate;
      case "lowest_match_rate":
        return a.matchRate - b.matchRate;
      case "job_title":
        return a.jobTitle.localeCompare(b.jobTitle);
      case "job_title_reverse":
        return b.jobTitle.localeCompare(a.jobTitle);
      case "company":
        return a.companyName.localeCompare(b.companyName);
      case "company_reverse":
        return b.companyName.localeCompare(a.companyName);
      default:
        return 0;
    }
  });
};

export const sortSubmissions = (suggestionsToSort, currentSort) => {
  return [...suggestionsToSort].sort((a, b) => {
    switch (currentSort) {
      case "most_recent":
        return new Date(b.updatedAt) - new Date(a.updatedAt);
      case "oldest":
        return new Date(a.updatedAt) - new Date(b.updatedAt);
      case "highest_match_rate":
        return b.matchRate - a.matchRate;
      case "lowest_match_rate":
        return a.matchRate - b.matchRate;
      case "job_title":
        return a.jobTitle.localeCompare(b.jobTitle);
      case "job_title_reverse":
        return b.jobTitle.localeCompare(a.jobTitle);
      case "company":
        return a.companyName.localeCompare(b.companyName);
      case "company_reverse":
        return b.companyName.localeCompare(a.companyName);
      default:
        return 0;
    }
  });
};
