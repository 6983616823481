import { navBarMl, navBarMr } from "../../assets/classnames";
import ContactFormSection from "./ContactFormSection";

export function ContactPage() {
  return (
    <main className={`flex align-start`}>
      <div className="w-full">
        <ContactFormSection />
      </div>
    </main>
  );
}
