import React, { createContext, useContext, useEffect, useState } from "react";
import {  urlRegistration } from "../config";
import { useUserData } from "./useUserData";

const ProgressContext = createContext();

export const ProgressProvider = ({ children }) => {
  const { userData } = useUserData();

  const totalSteps = 5;
  const [currProgress, setCurrProgress] = useState(1);
  const [prevProgress, setPrevProgress] = useState(0);
  const [stepCompleted, setStepCompleted] = useState(null);

  const increaseProgress = () => {
    setPrevProgress(currProgress);
    setCurrProgress(currProgress + 1);
  };

  const decreaseProgress = () => {
    setPrevProgress(currProgress);
    setCurrProgress(currProgress - 1);
  };

  useEffect(() => {
    const path = window.location.pathname;

    if (path === urlRegistration.cvUpload) setCurrProgress(1);
    if (path === urlRegistration.jobTitles) setCurrProgress(2);
    if (path === urlRegistration.locationPreferences) setCurrProgress(3);
    if (path === urlRegistration.otherPreferences) setCurrProgress(4);
    if (path === urlRegistration.review) setCurrProgress(5);
  }, [window.location.pathname]);

  useEffect(() => {
    if (!userData) return;

    const hasCompletedLocations =
      userData.locations || userData.hybridOk || userData.remoteOk;

    setStepCompleted(0);
    if (userData.cvPath) setStepCompleted(1);
    if (userData.jobTitles) setStepCompleted(2);
    if (hasCompletedLocations) setStepCompleted(3);
    if (userData.jobSearchNotes) setStepCompleted(4);
    if (userData.isRegistrationComplete) setStepCompleted(5);
  }, [userData]);

  return (
    <ProgressContext.Provider
      value={{
        currProgress,
        prevProgress,
        increaseProgress,
        decreaseProgress,
        totalSteps,
        stepCompleted,
      }}
    >
      {children}
    </ProgressContext.Provider>
  );
};

export const useProgress = () => useContext(ProgressContext);
