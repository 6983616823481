import { initializeApp } from "firebase/app";
import { getAuth, GoogleAuthProvider } from "firebase/auth";

const firebaseConfig = {
  apiKey: "AIzaSyAyYsKYGB5Gq8ed1rPdxUsdRkL380Wsm4A",
  authDomain: "getezjobs-557fe.firebaseapp.com",
  projectId: "getezjobs-557fe",
  storageBucket: "getezjobs-557fe.appspot.com",
  messagingSenderId: "255419766007",
  appId: "1:255419766007:web:2393b60c3420a330596ff2",
  measurementId: "G-HYLCK412TG",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

// Initialize Firebase Auth and Google Auth Provider
const auth = getAuth(app);
const googleProvider = new GoogleAuthProvider();

export { auth, googleProvider };

