import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";

export function ScrollToHash() {
  const location = useLocation();
  const [pageReady, setPageReady] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => {
      setPageReady(true);
    }, 500); 

    return () => clearTimeout(timer);
  }, []);

  useEffect(() => {
    if (pageReady) {
      const { hash } = location;
      if (hash) {
        const id = hash.replace('#', '');
        const element = document.getElementById(id);
        if (element) {
          setTimeout(() => {
            const yOffset = -120; 
            const y = element.getBoundingClientRect().top + window.pageYOffset + yOffset;
            window.scrollTo({top: y, behavior: 'auto'});
          }, 0);
        }
      }
    }
  }, [location, pageReady]);

  return null;
}
