import { Link } from "react-router-dom";
import { animationDelays } from "../../assets/classnames";

export const Cta = ({
  animate = false,
  center = false,
  text = "See how it works",
  link = "/#HowItWorks",
  lgAlign = "text-left",
  id = "CTA",
}) => {
  const ResetLocation = () => window.scrollTo(0, 0);

  return (
    <section className={center ? "flex flex-col items-center" : ""}>
      <h1
        className={`hidden sm:block flex flex-row ${
          center ? "flex-col items-center" : ""
        } w-full text-2xl font-extrabold tracking-tight text-gray-900 text-center lg:${lgAlign}`}
      >
        <span
          className={`${animate ? "animate-slideIn" : ""} text-white`}
          style={{ animationDelay: animationDelays.ctaTitle }}
        >
          We automate your job applications.
        </span>
        <br />
        <span
          className={`${animate ? "animate-slideIn" : ""} text-main-blue ml-2`}
          style={{ animationDelay: animationDelays.ctaSubtitle }}
        >
          Ready?
        </span>
      </h1>

      <h2
        className={`sm:hidden ${
          center ? "flex flex-col items-center" : ""
        } w-full text-2xl font-extrabold tracking-tight text-gray-900 text-center lg:${lgAlign}`}
      >
        <span
          className={`${animate ? "animate-slideIn" : ""} text-white`}
          style={{ animationDelay: animationDelays.ctaTitle }}
        >
          We automate your job applications.
        </span>
      </h2>

      <h1
        className={`sm:hidden ${
          center ? "flex flex-col items-center" : ""
        } w-full text-2xl font-extrabold tracking-tight text-gray-900 text-center lg:${lgAlign}`}
      >
        <span
          className={`${animate ? "animate-slideIn" : ""} text-main-blue`}
          style={{ animationDelay: animationDelays.ctaSubtitle }}
        >
          Ready?
        </span>
      </h1>

      <section
        className={`pt-6 flex justify-center ${
          lgAlign === "text-left" ? "lg:justify-start" : "justify-center"
        }
        `}
      >
        <section
          className="w-fit"
          style={{ animation: "bounce 4s infinite", animationDelay: "2.5s" }}
        >
          <Link
            id={id}
            onClick={ResetLocation}
            to={link}
            className="px-8 py-3 border border-transparent text-xl font-medium rounded-xl text-black bg-main-highlight hover:bg-main-highlight-darker"
          >
            {text}
          </Link>
        </section>
      </section>
    </section>
  );
};
