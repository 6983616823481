import React from "react";
import { NavLink } from "react-router-dom";
import { footerData } from "./footerConfig";
import { navBarPaddingLeft, navBarPaddingRight } from "../../assets/classnames";

const ResetLocation = () => {
  window.scrollTo(0, 0);
  return null;
};

const FooterSection = ({ title, items, styles }) => (
  <section className={`${styles}`}>
    <h3 className="text-lg font-semibold text-white tracking-wider pb-2">
      {title}
    </h3>
    <ul className="space-y-1">
      {items.map((item) => (
        <li key={item.name}>
          <NavLink
            onClick={ResetLocation}
            to={item.href}
            className="text-base text-gray-300 hover:text-white"
          >
            {item.name}
          </NavLink>
        </li>
      ))}
    </ul>
  </section>
);

const SocialLinks = ({ items }) => (
  <section className="flex flex-row space-x-4 sm:space-x-2">
    {items.map((item) => (
      <a
        key={item.name}
        href={item.href}
        target="_blank"
        rel="noreferrer"
        className="text-gray-400 hover:text-gray-300"
      >
        <span className="sr-only">{item.name}</span>
        <img
          className="h-6 w-6 invert hover:h-8 hover:w-8 rounded"
          aria-hidden="true"
          src={item.icon}
          alt={item.icon}
        />
      </a>
    ))}
  </section>
);

export default function Footer() {
  const paddingNav = `${navBarPaddingLeft} ${navBarPaddingRight}`;

  return (
    <footer
      className={`${paddingNav} bg-gray-900`}
      aria-labelledby="footer-heading"
    >
      <h2 id="footer-heading" className="sr-only">
        Footer
      </h2>
      <div className="grid grid-cols-2 sm:grid sm:grid-cols-3 border-t border-gray-400 pt-4">
        <FooterSection title="Company" items={footerData.company} />
        <FooterSection
          title="Free Tools"
          items={footerData.tools}
          styles="sm:mx-auto "
        />
        <FooterSection
          title="Support"
          items={footerData.support}
          styles="sm:mx-auto mt-4 sm:mt-0"
        />
        <section className="pt-4 sm:mt-0">
          <h3 className="flex text-lg font-semibold text-white tracking-wider pb-2">
            Follow us
          </h3>
          <SocialLinks items={footerData.social} />
        </section>
      </div>
      <div className="mt-4 border-t border-gray-400 md:flex md:items-center md:justify-between">
        <p className="py-4 text-base text-white md:mt-0 md:order-1">
          &copy; 2024 ezCompany, Ltd.
        </p>
      </div>
    </footer>
  );
}
