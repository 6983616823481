import React, { useEffect } from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  useParams,
} from "react-router-dom";
import Landing from "./routes/landing/Landing";
import NotFound from "./components/NotFound";
import { FaqPage } from "./routes/faq/Faq";
import { ContactPage } from "./routes/contact/Contact";
import { UserRoute } from "./routes/UserRoute";
import { Layout } from "./components/Layout";
import { RecruiterRoute } from "./routes/RecruiterRoute";
import { UserListView } from "./routes/users/UserListView";
import { SubscribeCheckout } from "./components/subscription/SubscribeCheckout";
import { SignUp } from "./components/signin/SignUp";
import { CookiePolicy } from "./routes/policies/CookiePolicy";
import { TermsAndConditions } from "./routes/policies/TermsAndConditions";
import { ScrollToHash } from "./routes/ScrollToHash";
import { RegistrationPage } from "./routes/registration/Registration";
import { RegistrationCvUpload } from "./routes/registration/RegistrationCvUpload";
import { RegistrationJobTitles } from "./routes/registration/RegistrationJobTitles";
import { RegistrationLocations } from "./routes/registration/RegistrationLocations";
import { RegistrationOthers } from "./routes/registration/RegistrationOthers";
import { RegistrationReview } from "./routes/registration/RegistrationReview";
import {
  ProviderCheckout,
  ProviderRegistration,
  ProviderSubmissions,
} from "./context/providers";
import { ProfileNew } from "./routes/user-profile/ProfileNew";
import { ManageSubscription } from "./components/subscription/ManageSubscription";
import { NewSuggestionsPage } from "./components/submissions/NewSuggestionsPage";
import { NewSubmissionsPage } from "./components/submissions/NewSubmissionsPage";
import { NewAboutPage } from "./routes/about/NewAbout";
import { DiscardedPage } from "./components/submissions/DiscardedPage";
import { UserView } from "./routes/users/UserView";
import { NewPricingMenu } from "./routes/pricing/NewPricingMenu";
import { navBarPaddingLeft, navBarPaddingRight } from "./assets/classnames";
import { PageEmailLogin } from "./components/signin/PageEmailLogin";
import { PageEmailSignUp } from "./components/signin/PageEmailSignUp";

const RedirectToBlog = () => {
  useEffect(() => {
    window.location.href = "/blog";
  }, []);

  return null;
};

const RedirectToResCheck = () => {
  useEffect(() => {
    window.location.href = "/ats-resume-checker";
  }, []);

  return null;
};

const RedirectToInterviewPrep = () => {
  const wildcard = useParams()["*"];
  console.log("inside redirect", { wildcard });

  useEffect(() => {
    window.location.href = wildcard
      ? `/interview-preparation/${wildcard}`
      : "/interview-preparation";
  }, []);

  return null;
};

const RedirectToCLG = () => {
  useEffect(() => {
    window.location.href = "/cover-letter-generator";
  }, []);

  return null;
};

export default function App() {
  return (
    <Router>
      <ScrollToHash />
      <Routes>
        <Route
          path="/"
          element={
            <ProviderCheckout>
              <Landing isWeekly={true} />
            </ProviderCheckout>
          }
        />

        <Route
          path="/home"
          element={
            <ProviderCheckout>
              <Landing isWeekly={false} />
            </ProviderCheckout>
          }
        />

        <Route path="/blog" element={<RedirectToBlog />} />
        <Route path="/cover-letter-generator" element={<RedirectToCLG />} />
        <Route path="/resume-checker" element={<RedirectToResCheck />} />
        <Route path="/ats-resume-checker" element={<RedirectToResCheck />} />
        <Route
          path="/interview-preparation*"
          element={<RedirectToInterviewPrep />}
        />

        <Route
          path="/contact"
          element={
            <Layout center={false}>
              <ContactPage />
            </Layout>
          }
        />
        <Route
          path="/faq"
          element={
            <Layout>
              <FaqPage />
            </Layout>
          }
        />
        <Route path="/about" element={<NewAboutPage />} />

        <Route
          path="/users"
          element={
            <Layout center={false}>
              <RecruiterRoute>
                <UserListView />
              </RecruiterRoute>
            </Layout>
          }
        />

        <Route
          path="users/:idUser"
          element={
            <ProviderSubmissions>
              <UserView />
            </ProviderSubmissions>
          }
        />

        <Route
          path="profile/:idUser/manage-subscriptions"
          element={
            <Layout center={false}>
              <UserRoute>
                <ManageSubscription />
              </UserRoute>
            </Layout>
          }
        />

        {/* Suggestions */}
        <Route
          path="profile/:idUser/suggestions"
          element={
            <ProviderSubmissions>
              <NewSuggestionsPage />
            </ProviderSubmissions>
          }
        />

        <Route
          path="users/:idUser/suggestions"
          element={
            <ProviderSubmissions>
              <NewSuggestionsPage />
            </ProviderSubmissions>
          }
        />

        <Route
          path="subscriptions"
          element={
            <ProviderCheckout>
              <Layout>
                <section
                  id="Subscriptions"
                  className={`${navBarPaddingLeft} ${navBarPaddingRight} 2xl:px-72`}
                >
                  <NewPricingMenu />
                </section>
              </Layout>
            </ProviderCheckout>
          }
        />

        {/* Submissions */}
        <Route
          path="profile/:idUser/submissions"
          element={
            <ProviderSubmissions>
              <NewSubmissionsPage />
            </ProviderSubmissions>
          }
        />

        <Route
          path="users/:idUser/submissions"
          element={
            <ProviderSubmissions>
              <NewSubmissionsPage />
            </ProviderSubmissions>
          }
        />

        {/* Discarded */}
        <Route
          path="profile/:idUser/discarded-suggestions"
          element={
            <ProviderSubmissions>
              <DiscardedPage />
            </ProviderSubmissions>
          }
        />

        <Route
          path="users/:idUser/discarded-suggestions"
          element={
            <ProviderSubmissions>
              <DiscardedPage />
            </ProviderSubmissions>
          }
        />

        <Route
          path="/profile"
          element={
            <ProviderSubmissions>
              <ProfileNew />
            </ProviderSubmissions>
          }
        />

        <Route
          path="/registration"
          element={
            <ProviderRegistration>
              <RegistrationPage />
            </ProviderRegistration>
          }
        />

        <Route
          path="/registration/cv-upload"
          element={
            <ProviderRegistration>
              <RegistrationCvUpload />
            </ProviderRegistration>
          }
        />

        <Route
          path="/registration/job-titles"
          element={
            <ProviderRegistration>
              <RegistrationJobTitles />
            </ProviderRegistration>
          }
        />

        <Route
          path="/registration/location-preferences"
          element={
            <ProviderRegistration>
              <RegistrationLocations />
            </ProviderRegistration>
          }
        />

        <Route
          path="/registration/other-preferences"
          element={
            <ProviderRegistration>
              <RegistrationOthers />
            </ProviderRegistration>
          }
        />

        <Route
          path="/registration/review"
          element={
            <ProviderRegistration>
              <RegistrationReview />
            </ProviderRegistration>
          }
        />

        <Route
          path="/subscribe/:subscriptionName"
          element={
            <Layout>
              <ProviderCheckout>
                <SubscribeCheckout />
              </ProviderCheckout>
            </Layout>
          }
        />

        <Route
          path="/sign-up"
          element={
            <Layout>
              <SignUp />
            </Layout>
          }
        />

        <Route
          path="/policies/cookies"
          element={
            <Layout>
              <CookiePolicy />
            </Layout>
          }
        />

        <Route
          path="/policies/terms-and-conditions"
          element={
            <Layout disableCookies={true}>
              <TermsAndConditions />
            </Layout>
          }
        />

        <Route
          path="/email-login"
          element={
            <Layout disableCookies={true}>
              <PageEmailLogin />
            </Layout>
          }
        />

<Route
          path="/email-signup"
          element={
            <Layout disableCookies={true}>
              <PageEmailSignUp />
            </Layout>
          }
        />


        <Route path="*" element={<NotFound />} />
      </Routes>
    </Router>
  );
}
