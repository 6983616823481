import React from "react";
import { Navigate } from "react-router-dom";
import { IconLoading } from "../components/IconLoading";
import { useUserData } from "../context/useUserData";

export const RecruiterRoute = ({ children }) => {
  const { isRecruiter, loading } = useUserData();

  console.log("recruiter route: ", { loading, isRecruiter });
  if (loading) {
    return <IconLoading />;
  }

  if (!loading && !isRecruiter) {
    console.log("inside !isRecruiter");
    return <Navigate to="/" replace />;
  }

  return children;
};
