import { PencilSquareIcon } from "@heroicons/react/24/solid";
import { stylePageTitle } from "../../assets/classnames";
import EmailIcon from "../../assets/images/icon-email-2.svg";
import BinIcon from "../../assets/images/bin-icon.svg";
import SearchIcon from "../../assets/images/icon-search-2.svg";
import IconUser from "../../assets/images/icon-user.png";
import { ButtonCustom } from "../../components/cta/ButtonCustom";
import { ProfileInfo } from "../registration/ProfileInfo";

export const styleSuggestionsContainer =
  "flex flex-col items-center bg-gray-700 rounded-md py-4 justify-end";

export const CardTitle = ({ prefix = "Hi", name }) => (
  <div className={stylePageTitle}>
    <span className="">{prefix}</span>
    <span className="text-main-blue"> {name}</span>
  </div>
);

export const CardSuggestions = ({ numSuggestions, onClick, isHighlight }) => {
  return numSuggestions ? (
    <CardSuggestionsReady
      numSuggestions={numSuggestions}
      onClick={onClick}
      isHighlight={isHighlight}
    />
  ) : (
    <CardSuggestionsSimplified />
  );
};

export const CardSuggestionsSimplified = ({}) => (
  <div className={styleSuggestionsContainer}>
    <img src={SearchIcon} className="h-16 w-16" />
    <div className="text-2xl font-bold text-center">Suggestions</div>
    <div className="pt-2 text-center">
      We are looking for jobs for you, and they will appear here soon
    </div>
  </div>
);

export const CardSubmissionsSimplified = ({}) => (
  <div className={styleSuggestionsContainer}>
    <img src={EmailIcon} className="h-16 w-16" />
    <div className="text-2xl font-bold text-center">Submissions</div>
    <div className="pt-2 text-center">
      We are looking for jobs for you, and they will appear here soon
    </div>
  </div>
);

export const CardDiscardedSimplified = ({}) => (
  <div className={styleSuggestionsContainer}>
    <img src={BinIcon} className="h-10 w-10 invert my-2" />
    <div className="text-2xl font-bold text-center">Discarded Suggestions</div>
    <div className="pt-2 text-center">
      You have not discarded any suggestions yet.
    </div>
  </div>
);

export const CardSuggestionsReady = ({
  numSuggestions = 5,
  onClick,
  isHighlight,
}) => (
  <div className={styleSuggestionsContainer}>
    <img src={SearchIcon} className="h-16 w-16" />
    <div className="text-2xl font-bold text-center">{numSuggestions} Suggestions Ready</div>
    <div className="pt">They will be sent soon</div>
    <ButtonCustom
      text="Review"
      isHighlight={isHighlight}
      style="min-w-40 mt-4"
      onClick={onClick}
    />
  </div>
);

export const CardAddSuggestion = ({ numSuggestions, onClick }) => (
  <div className={styleSuggestionsContainer}>
    <img src={SearchIcon} className="h-16 w-16" />
    <div className="text-2xl font-bold text-center">{numSuggestions} Suggestions ready</div>
    <ButtonCustom
      text="Add suggestion"
      isHighlight={false}
      style="min-w-40 mt-4"
      onClick={onClick}
    />
  </div>
);

export const CardGeneric = ({
  title,
  subTitle,
  icon,
  textButton,
  onClick,
  isHighlight,
}) => (
  <div className={styleSuggestionsContainer}>
    <img src={icon} className="h-16 w-16" />
    <div className="text-2xl font-bold text-center">{title}</div>
    {subTitle && <div className="pt">{subTitle}</div>}
    <ButtonCustom
      text={textButton}
      isHighlight={isHighlight}
      style="min-w-40 mt-4"
      onClick={onClick}
    />
  </div>
);

export const CardSubmissions = ({ numSubmissions, onClick }) => (
  <div className={`${styleSuggestionsContainer} `}>
    <img src={EmailIcon} className="h-16 w-16" />
    <div className="text-2xl font-bold text-center">{numSubmissions} Applications Sent</div>
    <div className="pt">Review them here</div>
    <ButtonCustom
      text="See submissions"
      isHighlight={false}
      style="min-w-40 mt-4"
      onClick={onClick}
    />
  </div>
);

export const CardSubscription = ({ subscriptionName, onClick }) => {
  if (!subscriptionName) return;

  return (
    <div className={`${styleSuggestionsContainer} pt-6 md:pt-4`}>
      <img src={IconUser} className="h-10 w-10 mb-3" />
      <div className="text-2xl font-bold text-center">{subscriptionName} Subscription</div>
      <div className="pt">Manage it here</div>
      <ButtonCustom text="Manage" style="min-w-40 mt-4" onClick={onClick} />
    </div>
  );
};

export const CardDiscarded = ({ numDiscarded, onClick }) => (
  <div className={`${styleSuggestionsContainer} `}>
    <img src={BinIcon} className="h-9 w-9 invert mb-4" />
    <div className="text-2xl font-bold text-center">
      {numDiscarded} Discarded Suggestions
    </div>
    <div className="pt">Review them here</div>
    <ButtonCustom
      text="Review"
      isHighlight={false}
      style="min-w-40 mt-4"
      onClick={onClick}
    />
  </div>
);

export const CardDiscardedRecruiter = ({ numDiscarded, onClick }) => (
  <div className={`${styleSuggestionsContainer} `}>
    <img src={BinIcon} className="h-9 w-9 invert mb-4" />
    <div className="text-2xl font-bold text-center">
      {numDiscarded} Discarded
    </div>
    <ButtonCustom
      text="Review"
      isHighlight={false}
      style="min-w-40 mt-4"
      onClick={onClick}
    />
  </div>
);

export const CardSubscribeNow = ({ onClick }) => {
  return (
    <div className={`${styleSuggestionsContainer} md:justify-center`}>
      <div className="text-2xl font-bold text-center text-center pt-2">
        You are not a subscriber
      </div>
      <div className="text-md font-normal max-w-sm text-center pt-2">
        Once you set up your subscription, we will start looking for jobs for
        you.
      </div>
      <ButtonCustom
        text="Subscribe Now!"
        isHighlight={true}
        style="min-w-40 mt-4"
        onClick={onClick}
      />
    </div>
  );
};

export const CardEditProfile = ({ userData, onClick }) => (
  <div
    className={"flex flex-col bg-gray-700 rounded-md p-4 md:px-8  space-y-6"}
  >
    <div className="flex flex-row space-x-2 items-center">
      <div className="text-2xl font-bold text-center">Your data</div>
      <PencilSquareIcon
        onClick={onClick}
        className="h-6 w-6 hover:h-8 hover:w-8 text-gray-400 "
        aria-hidden="true"
      />
    </div>
    <ProfileInfo userData={userData} styleContainer="" />
  </div>
);
