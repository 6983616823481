import React, { createContext, useContext, useEffect, useState } from "react";
import { onAuthStateChanged } from "firebase/auth";
import { auth } from "../firebaseConfig";

const AUTH_TOKEN_NAME = "idToken";

const AuthContext = createContext();

export const isTokenExpired = (token) => {
  if (!token) return true;
  const [, payload] = token.split(".");
  const decoded = JSON.parse(atob(payload));
  const exp = decoded.exp * 1000; // Convert to milliseconds
  return Date.now() >= exp;
};

export const AuthProvider = ({ children }) => {
  const [currentUser, setCurrentUser] = useState(null);
  const [token, setToken] = useState(null);
  const [loading, setLoading] = useState(true);

  const updateToken = (val) => {
    console.log("update token was called.", val);

    setToken(val);
    if (val) {
      localStorage.setItem(AUTH_TOKEN_NAME, val);
    } else {
      localStorage.removeItem(AUTH_TOKEN_NAME);
    }
  };

  const refreshToken = async () => {
    console.log("inside refreshToken");

    if (!currentUser) {
      console.warn("no user logged in. Can not refresh token");
      return;
    }

    try {
      const idToken = await currentUser.getIdToken(true); // Force token refresh
      console.log("Token refreshed successfully");

      updateToken(idToken);
      return idToken;
    } catch (error) {
      console.error("Error refreshing token:", error);
      throw new Error("Failed to refresh token");
    }
  };

  useEffect(() => {
    const storedToken = localStorage.getItem(AUTH_TOKEN_NAME);
    if (storedToken) {
      if (isTokenExpired(storedToken)) {
        console.log("token is expired");

        refreshToken().then((newToken) => {
          console.log(`${newToken ? "success" : "error"} on refresh token`);
        });
      } else {
        setToken(storedToken);
      }
    }

    const handleAuthStateChange = onAuthStateChanged(auth, (user) => {
      setCurrentUser(user);
      setLoading(false);
    });

    return () => handleAuthStateChange();
  }, [currentUser]);

  return (
    <AuthContext.Provider
      value={{ currentUser, token, updateToken, refreshToken }}
    >
      {!loading && children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => useContext(AuthContext);
