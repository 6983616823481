import ContactForm from "./ContactForm";

export default function ContactFormSection() {
  return (
    <section className="py-4">
      <h2 className="text-3xl font-extrabold tracking-tight sm:text-4xl text-white">
        Let's work together
      </h2>
      <p className="mt-4 text-lg text-gray-300 sm:mt-3 text-justify">
        If you have any questions or concerns, feel free to contact us via the
        form below, or via email.
      </p>

      <h2 className="text-xl font-extrabold sm:text-xl text-white pt-2">
        E-mail:
      </h2>
      <p className="text-lg text-gray-300">admin@getezjobs.com</p>
      <p className="text-lg text-gray-300">rafaelmarques76076@gmail.com</p>

      <h2 className="text-xl font-extrabold sm:text-3xl text-white pt-8">
        Send us a message
      </h2>
      <p className="pt-2">We try to reply in 12 hours or less.</p>
      <ContactForm />
    </section>
  );
}
