import { titlesLanding } from "../../assets/classnames";
import { Cta } from "../../components/cta/Cta";
import { CtaJoinWaitlist } from "../../components/cta/CtaJoinWaitlist";
import { useFeatureFlags } from "../../hooks/useFeatureFlags";
import { useLogging } from "../../hooks/useLogging";
import { useObserver } from "../../hooks/useObserver";
import FaqList from "./FaqList";

export function FaqPage({ showCTA = true }) {
  const { ffGoLive } = useFeatureFlags();

  const { logEvent } = useLogging();
  const [setRef] = useObserver("HowItWorks", () =>
    logEvent("visibility: Landing Faq")
  );

  const renderCta = () => {
    if (!showCTA) return;

    if (ffGoLive) {
      return (
        <div className="text-center pb-6 pt-16">
          <Cta />
        </div>
      );
    }

    return (
      <section className="bg-colour-two mx-auto py-4 rounded-md md:rounded-full max-w-4xl">
        <h2 className="text-center text-3xl font-extrabold text-white sm:text-4xl italic underline pt-2">
          Ready to start?
        </h2>

        <section className="mx-auto max-w-md px-4 sm:max-w-2xl sm:px-6 sm:text-center lg:px-0 lg:flex lg:items-center justify-center">
          <CtaJoinWaitlist />
        </section>
      </section>
    );
  };

  return (
    <div ref={setRef} className={`bg-gray-900`}>
      <h2 className={titlesLanding}>Frequently Asked</h2>
      <FaqList />

      {renderCta()}
    </div>
  );
}
