import React from "react";
import flagsmith from "flagsmith";
import App from "./App";
import { createRoot } from "react-dom/client";
import { ChakraProvider } from "@chakra-ui/react";
import { AuthProvider } from "./context/useAuth";
import { UserDataProvider } from "./context/useUserData";
import { FlagsmithProvider } from "flagsmith/react";
import { WeeklyProvider } from "./context/useWeekly";
import { StripeProvider } from "./context/useStripe";
import theme from "./theme";
import "react-tooltip/dist/react-tooltip.css";
import "./assets/index.css";

const container = document.getElementById("root");
const root = createRoot(container);
root.render(
  <ChakraProvider theme={theme}>
    <FlagsmithProvider
      options={{
        environmentID: process.env.REACT_APP_FLAGSMITH_ENVIRONMENT_ID,
      }}
      flagsmith={flagsmith}
    >
      <AuthProvider>
        <UserDataProvider>
          <WeeklyProvider>
            <StripeProvider>
              <App />
            </StripeProvider>
          </WeeklyProvider>
        </UserDataProvider>
      </AuthProvider>
    </FlagsmithProvider>
  </ChakraProvider>
);

