import { useFlags } from "flagsmith/react";

export const useFeatureFlags = () => {
  const flags = useFlags(["ff_show_login", "ff_show_pricing", "ff_go_live"]);
  const ffShowPricing = flags?.ff_show_pricing?.enabled;
  const ffGoLive = flags?.ff_go_live?.enabled;
  const ffShowLogin = flags?.ff_show_login?.enabled;

  return { ffShowPricing, ffGoLive, ffShowLogin };
};
