const currencies = {
  usd: { name: "usd", symbol: "$" },
  gbp: { name: "gbp", symbol: "£" },
  eur: { name: "eur", symbol: "€" },
};

export const selectCurrency = (location) => {
  if (location?.country === "United Kingdom") {
    return currencies.gbp;
  }

  if (location?.continent === "EU") {
    return currencies.eur;
  }

  return currencies.usd;
};
