import axios from "axios";
import { useCallback } from "react";
import { useUserData } from "../context/useUserData";
import { isTokenExpired } from "../context/useAuth";

export const useAxiosPost = () => {
  const { token, refreshToken } = useUserData();

  const axiosPost = useCallback(
    async (
      url = "",
      data = {},
      onStart = () => {},
      onSuccess = (res) => res,
      onError = (e) => e,
      onFinish = () => {}
    ) => {
      try {
        console.log("Starting axiosPost", { url, data });

        onStart();

        const authToken = await (isTokenExpired(token)
          ? refreshToken()
          : Promise.resolve(token));
        const config = { headers: { Authorization: authToken } };

        const response = await axios.post(url, data, config);
        console.log("Success on axiosPost:", response);
        onSuccess(response);
      } catch (error) {
        console.error("Error on axiosPost:", error);
        onError(error);
      } finally {
        onFinish();
      }
    },
    [token, refreshToken]
  );

  return { axiosPost };
};
