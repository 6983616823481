import {
  signInWithEmailAndPassword,
  createUserWithEmailAndPassword,
} from "firebase/auth";
import { auth } from "../firebaseConfig";
import { useAuth } from "../context/useAuth";

export const authEmail = () => {
  const { updateToken } = useAuth();

  const logInWithEmail = async (email, password) => {
    try {
      const result = await signInWithEmailAndPassword(auth, email, password);
      const token = await result.user.getIdToken();
      updateToken(token);
      console.log("Sign in with email success");
    } catch (error) {
      console.log("Sign in error: ", error);
      throw error;
    }
  };

  const signUpWithEmail = async (email, password) => {
    try {
      const result = await createUserWithEmailAndPassword(
        auth,
        email,
        password
      );
      const token = await result.user.getIdToken();
      updateToken(token);
      console.log("Sign up with email success");
    } catch (error) {
      console.log("Sign up error: ", error);
      throw error;
    }
  };

  return { logInWithEmail, signUpWithEmail };
};
