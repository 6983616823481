import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { useDisclosure } from "@chakra-ui/react";
import { useUserData } from "../../context/useUserData";
import { ButtonCustom } from "../../components/cta/ButtonCustom";
import { SuggestionsAddModal } from "../../components/submissions/SuggestionsAddModal";
import { IconLoading } from "../../components/IconLoading";
import {
  CardDiscardedRecruiter,
  CardGeneric,
  CardTitle,
} from "../user-profile/Cards";
import { ProfileInfo } from "../registration/ProfileInfo";
import { useSubmissionsApi } from "../../context/useSubmissionsApi";
import EmailIcon from "../../assets/images/icon-email-2.svg";
import SearchIcon from "../../assets/images/icon-search-2.svg";

export const UserView = () => {
  const navigate = useNavigate();
  const { idUser } = useParams();
  const { getCustomerData, token, refreshToken } = useUserData();
  const { fetchSuggestions, fetchSubmissions, fetchDiscarded } =
    useSubmissionsApi();
  const [customerData, setCustomerData] = useState(false);
  const [numSuggestions, setNumSuggestions] = useState(-1);
  const [numSubmissions, setNumSubmissions] = useState(-1);
  const [numDiscarded, setNumDiscarded] = useState(-1);
  const [isCompleteLoading, setIsCompleteLoading] = useState(false);
  const [error, setError] = useState(null);
  const { isOpen, onOpen, onClose } = useDisclosure();

  console.log("inside user view: ", {
    customerData,
    numDiscarded,
    numSubmissions,
    numSuggestions,
    isCompleteLoading,
  });

  useEffect(() => {
    const onLoadCustomer = (res) => setCustomerData(res?.data);
    const onLoadSuggestions = (res) => setNumSuggestions(res?.data?.numDocs);
    const onLoadSubmissions = (res) => setNumSubmissions(res?.data?.numDocs);
    const onLoadDiscarded = (res) => setNumDiscarded(res?.data?.numDocs);

    const onError = (e) => setError(e);

    fetchSuggestions(idUser, onLoadSuggestions, onError);
    fetchSubmissions(idUser, onLoadSubmissions, onError);
    fetchDiscarded(idUser, onLoadDiscarded, onError);
    getCustomerData(idUser, onLoadCustomer, onError);
  }, []);

  useEffect(() => {
    if (
      customerData &&
      numSubmissions >= 0 &&
      numSubmissions >= 0 &&
      numDiscarded >= 0
    ) {
      setIsCompleteLoading(true);
    }
  }, [customerData, numSuggestions, numSubmissions, numDiscarded]);

  const handleClickSubmissions = () => {
    navigate(`/users/${idUser}/suggestions`);
  };

  const handleClickSuggestions = () => {
    onOpen();
  };

  if (!isCompleteLoading) return <IconLoading />;

  const BackHomeContent = () => (
    <div className="flex justify-center pt-8 sm:pt-16">
      <ButtonCustom
        text="Back to Users"
        onClick={() => navigate("/users")}
        isHighlight={false}
        style="min-w-40 md:ml-4"
      />
    </div>
  );

  const ContentAction = () => (
    <div className="space-y-4">
      <div className="md:grid grid-cols-2 md:space-x-4 space-y-4 md:space-y-0">
        <CardGeneric
          icon={SearchIcon}
          title={`Suggestions`}
          textButton={"Add"}
          onClick={handleClickSuggestions}
        />

        <CardGeneric
          icon={EmailIcon}
          title={`Submissions`}
          textButton={"Complete"}
          onClick={handleClickSubmissions}
        />
      </div>

      <div className="md:grid grid-cols-3 md:space-x-4 space-y-4 md:space-y-0">
        <CardGeneric
          icon={SearchIcon}
          title={`${numSuggestions} Suggestions ready`}
          textButton={"Review"}
          onClick={() => navigate(`/users/${idUser}/suggestions`)}
        />

        <CardGeneric
          icon={EmailIcon}
          title={`${numSubmissions} Submissions done`}
          textButton={"Review"}
          onClick={() => navigate(`/users/${idUser}/submissions`)}
        />

        <CardDiscardedRecruiter
          numDiscarded={numDiscarded}
          onClick={() => navigate(`/users/${idUser}/discarded-suggestions`)}
        />
      </div>
    </div>
  );

  return (
    <div className="pt-4">
      <CardTitle prefix="User:" name={customerData?.displayName} />

      <div className="border-y pt-4 mb-8">
        <ProfileInfo userData={customerData} styleContainer="" />
      </div>

      <ContentAction />
      <BackHomeContent />

      <SuggestionsAddModal
        isOpen={isOpen}
        onClose={onClose}
        idUser={idUser}
        token={token}
        refreshToken={refreshToken}
      />
    </div>
  );
};
