import React, { useRef, useState } from "react";
import axios from "axios";
import { URL_CONTACT_US } from "../../config";
import { IconLoading } from "../../components/IconLoading";

// import ReCAPTCHA from "react-google-recaptcha";
// const captchaKey =
//   process.env.REACT_APP_CAPTCHA_KEY ||
//   "6LfTe8QpAAAAAIo0EuNs9mVxPPX71LfGPxwwCZd4";
// const captchaSecret =
//   process.env.REACT_APP_CAPTCHA_SECRET ||
//   "6LfTe8QpAAAAAIZ7guKDwD5rUuqdkg9ko9LBMTQG";
// const serverlUrl = process.env.REACT_APP_SERVER_URL;
// see this guide: https://clerk.com/blog/implementing-recaptcha-in-react

export default function ContactForm() {
  const captchaRef = useRef();
  const [formValue, setFormValue] = useState({
    name: "",
    email: "",
    textarea: "",
  });
  const [formError, setFormError] = useState({});
  const [submit, setSubmitOk] = useState(false);
  const [loading, setLoading] = useState(false);
  const [captchaError, setCaptchaError] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setFormError(validateForm(formValue));
    setLoading(true);

    if (Object.keys(validateForm(formValue)).length > 0) {
      setLoading(false);
      return null;
    }

    // let captchaToken = captchaRef.current?.getValue();
    // if (captchaToken.length === 0) {
    //   setCaptchaError("ReCaptcha is mandatory");
    //   setLoading(false);
    //   return;
    // }
    await submitMessage();
    captchaRef.current?.reset();
    // if (ok) {
    //   console.log('inside ok')
    //   setSubmitOk(true);
    //   setCaptchaError("");
    //   setLoading(false);
    // } else {
    //   console.log('inside else')

    //   setSubmitOk(false);
    //   setCaptchaError("Error submitting message");
    //   setLoading(false);
    // }
  };

  const handleValidation = (e) => {
    const { name, value } = e.target;
    setFormValue({ ...formValue, [name]: value });
  };

  const submitMessage = async () => {
    console.log("inside submitMessage");

    const endpoint = URL_CONTACT_US;
    const postData = {
      name: formValue.name,
      email: formValue.email,
      message: formValue.textarea,
    };

    axios
      .post(endpoint, postData)
      .then(() => {
        console.log("Success sending contact-us message");
        setSubmitOk(true);
        setCaptchaError("");
        setLoading(false);
      })
      .catch((error) => {
        console.error("Error sending contact-us message", error);
        setSubmitOk(false);
        setCaptchaError(
          "There was an error. Please send a message to admin@getezjobs.com"
        );
        setLoading(false);
      });
  };

  const validateForm = (value) => {
    let errors = {};
    const emailRegex = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/;
    if (!value.name) {
      errors.name = "Please enter name!";
    } else if (value.name && value.name.length < 3) {
      errors.name = "Name is too short";
    }
    if (!value.email) {
      errors.email = "Please enter email!";
    } else if (!emailRegex.test(value.email)) {
      errors.email = "Please enter valid email!";
    }
    if (!value.textarea) {
      errors.textarea = "Please enter message!";
    } else if (value.textarea && value.textarea.length < 10) {
      errors.textarea = "The message should be min. 10 characters";
    }

    return errors;
  };

  if (loading) {
    return (
      <div className="pt-4">
        <IconLoading message="" />
      </div>
    );
  }

  if (submit && Object.keys(formError).length === 0) {
    return (
      <div className="bg-green-700 text-lg rounded-md p-2 mt-2 max-w-lg">
        <p>Thank you submitting your request.</p>
        <p>We are reviewing your message and will contact you shortly!</p>
      </div>
    );
  }

  return (
    <React.Fragment>
      {captchaError && (
        <div className="bg-red-700 text-lg rounded-md p-2 mt-2 max-w-lg">
          <p>{captchaError}</p>
        </div>
      )}
      <form
        onSubmit={handleSubmit}
        className="mt-8 grid grid-cols-1 gap-y-6 sm:grid-cols-2 sm:gap-x-8 text-white"
      >
        <section className="sm:col-span-1">
          <label
            htmlFor="name"
            className="block text-sm font-medium text-white"
          >
            Name *
          </label>
          <section className="mt-1">
            <input
              type="text"
              value={formValue.name}
              onChange={handleValidation}
              name="name"
              id="first-name"
              autoComplete="given-name"
              className="text-gray-900 block w-full shadow-sm sm:text-sm focus:ring-grape-500 focus:border-grape-500 border-gray-300 rounded-md p-2"
            />
            <span className="text-red-400">{formError.name}</span>
          </section>
        </section>
        <section className="sm:col-span-1">
          <label
            htmlFor="email"
            className="block text-sm font-medium text-white"
          >
            Email *
          </label>
          <section className="mt-1">
            <input
              id="email"
              name="email"
              value={formValue.email}
              onChange={handleValidation}
              type="text"
              autoComplete="email"
              className="text-gray-900 block w-full shadow-sm sm:text-sm focus:ring-grape-500 focus:border-grape-500 border-gray-300 rounded-md p-2"
            />
            <span className="text-red-400">{formError.email}</span>
          </section>
        </section>
        <section className="sm:col-span-2">
          <section className="flex justify-between">
            <label
              htmlFor="textarea"
              className="block text-sm font-medium text-white"
            >
              How can we help you? *
            </label>
          </section>
          <section className="mt-1">
            <textarea
              id="how-can-we-help"
              name="textarea"
              value={formValue.textarea}
              onChange={handleValidation}
              aria-describedby="how-can-we-help-description"
              rows={4}
              className="text-gray-900 block w-full shadow-sm sm:text-sm focus:ring-grape-500 focus:border-grape-500 border border-gray-300 rounded-md p-2"
            />
            <span className="text-red-400">{formError.textarea}</span>
          </section>
        </section>
        {/* <section>
            <ReCAPTCHA
              ref={captchaRef}
              sitekey={captchaKey}
              className="table mx-auto "
            />
            {captchaError && (
              <span className="text-red-400">{captchaError}</span>
            )}
          </section> */}

        {formValue.name && formValue.email && formValue.textarea && (
          <section className="text-right sm:col-span-2">
            <button
              type="submit"
              className="inline-flex items-center px-4 py-2 border border-transparent shadow-sm text-base font-bold rounded-md text-black bg-main-highlight hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
            >
              Submit
            </button>
          </section>
        )}
      </form>
    </React.Fragment>
  );
}
