import React from "react";
import LockIcon from "../../assets/images/lock.svg";
import { ButtonWithImage } from "../cta/ButtonWithImage";
import { useNavigate } from "react-router-dom";

export const SignInWithEmail = ({
  buttonText = " Sign in with Email/Password",
}) => {
  const navigate = useNavigate();

  const handleEmailLogin = async () => {
    navigate("/email-login");
  };

  return (
    <ButtonWithImage
      onClick={() => handleEmailLogin()}
      imgSrc={LockIcon}
      imgAlt="Microsoft"
      buttonText={buttonText}
    />
  );
};
