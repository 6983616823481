import React from "react";
import MicrosoftIcon from "../../assets/images/microsoft-icon.png";
import { authMicrosoft } from "../../hooks/authMicrosoft";
import { ButtonWithImage } from "../cta/ButtonWithImage";
import { useNavigate } from "react-router-dom";

export const SignInWithMicrosoft = ({
  redirectProfile = true,
  buttonText = " Sign in with Microsoft",
}) => {
  const { logIn } = authMicrosoft();
  const navigate = useNavigate();

  const handleLogin = async (redirectProfile) => {
    await logIn();
    if (redirectProfile) {
      navigate("/profile");
    }
  };

  return (
    <ButtonWithImage
      onClick={() => handleLogin(redirectProfile)}
      imgSrc={MicrosoftIcon}
      imgAlt="Microsoft"
      buttonText={buttonText}
    />
  );
};
