import React from "react";
import { useAuth } from "../context/useAuth";
import { IconLoading } from "../components/IconLoading";
import { SignUp } from "../components/signin/SignUp";
import { Layout } from "../components/Layout";

export const UserRoute = ({ children }) => {
  const { currentUser, loading } = useAuth();

  if (loading) {
    return <IconLoading />;
  }

  if (!currentUser) {
    console.log("user is not logged in. Redirecting to login page");

    return (
      <Layout>
        <SignUp isSignIn={true} />
      </Layout>
    );
  }

  return children;
};
