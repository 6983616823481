import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useUserData } from "../../context/useUserData";
import { useProgress } from "../../context/useProgress";
import {  urlRegistration } from "../../config";

export const RegistrationPage = ({}) => {
  const navigate = useNavigate();

  const { userData } = useUserData();
  const { stepCompleted } = useProgress();

  useEffect(() => {
    if (userData?.isRegistrationComplete) {
      navigate("/profile");
    }

    const sc = stepCompleted;
    if (typeof sc !== "number") return;

    if (sc === 0) navigate(urlRegistration.cvUpload);
    if (sc === 1) navigate(urlRegistration.jobTitles);
    if (sc === 2) navigate(urlRegistration.locationPreferences);
    if (sc === 3) navigate(urlRegistration.otherPreferences)
    if (sc === 4) navigate(urlRegistration.review);
    if (sc === 5) navigate(urlRegistration.review);
  }, [userData, stepCompleted]);

  return (
    <div>
      <p>Registration Page</p>
    </div>
  );
};
