import React, { createContext, useState, useEffect, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { useProgress } from "./useProgress";

const SuccessContext = createContext();

export const SuccessProvider = ({ children }) => {
  const navigate = useNavigate();
  const { increaseProgress } = useProgress();


  const [success, setSuccess] = useState(false);
  const [countdown, setCountdown] = useState(3);
  const [targetUrl, setTargetUrl] = useState("");

  useEffect(() => {
    let timer;
    if (success && countdown > 0) {
      timer = setTimeout(() => {
        setCountdown(countdown - 1);
      }, 1000);
    } else if (countdown === 0 && success) {
      navigate(targetUrl);
      window.scrollTo(0, 0);
      setSuccess(false);
      setCountdown(3);
      increaseProgress()
    }
    return () => clearTimeout(timer);
  }, [success, countdown, navigate, targetUrl]);

  return (
    <SuccessContext.Provider value={{ success, setSuccess, countdown, setTargetUrl }}>
      {children}
    </SuccessContext.Provider>
  );
};

export const useSuccess = () => useContext(SuccessContext);
