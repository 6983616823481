import React from "react";
import { DesktopDropdown } from "./DesktopDropdown";
import { LinkStyled } from "./LinkStyled";

export const DesktopMenu = ({
  links,
  actionOnClick,
  activeDropdown,
  handleDropdownClick,
}) => (
  <div className="hidden lg:flex ml-10 mr-2">
    {links.map((link) =>
      link.list ? (
        <DesktopDropdown
          key={link.name}
          link={link}
          isActive={activeDropdown === link.name}
          handleDropdownClick={handleDropdownClick}
          onClick={actionOnClick}
        />
      ) : (
        <LinkStyled key={link.name} link={link} onClick={actionOnClick} />
      )
    )}
  </div>
);
