import React from "react";
import { navBarMl, navBarMr, titlesLanding } from "../../assets/classnames";
import SaveTimeIcon from "../../assets/images/save-time-icon.png";
import PhoneCallIcon from "../../assets/images/phone-call-icon.png";
import ShakeHandsIcon from "../../assets/images/shake-hands-icon-2.png";
import { useObserver } from "../../hooks/useObserver";
import { useLogging } from "../../hooks/useLogging";

const styles = {
  containerOutter: `${navBarMl} ${navBarMr}`,
  title: "",
  number: "text-6xl font-bold pb-2",
  stat: "text-center text-lg text-white sm:py-4",
  image: "h-20 w-20 text-white mx-auto sm:mt-4 bg-white p-1 rounded-md",
  statText: "h-32",
};

const StatBlock = ({ number, text, imageSrc, border, borderTop }) => (
  <div
    className={`${styles.stat} ${border ? "sm:border-r-2" : ""} mt-12 sm:mt-0`}
  >
    {borderTop && (
      <div className="sm:hidden mb-8 mx-8 border-t-2 sm:border-t-0" />
    )}
    <div className={styles.statText}>
      <div className={styles.number}>{number}</div>
      {text}
    </div>
    <img src={imageSrc} alt="/" className={styles.image} aria-hidden="true" />
    <div className="sm:hidden mt-8 mx-8 border-t-2 sm:border-t-0" />
  </div>
);

export const StatsSection = () => {
  const { logEvent } = useLogging();
  const [setRef] = useObserver("StatsSection", () =>
    logEvent("visibility: StatsSection")
  );

  return (
    <div ref={setRef} id="Stats" className="flex flex-col">
      <div className={`${styles.containerOutter}`}>
        <div className={`${titlesLanding}`}>How does this help you?</div>
        <div className="grid sm:grid-cols-3 my-16 md:mt-24">
          <StatBlock
            number="95%"
            text="total time saved"
            imageSrc={SaveTimeIcon}
            border
            borderTop
          />
          <StatBlock
            number="91%"
            text="contacted by recruiters in 2 weeks"
            imageSrc={PhoneCallIcon}
            border
          />
          <StatBlock
            number="82%"
            text="land a job after 3 months"
            imageSrc={ShakeHandsIcon}
          />
        </div>
      </div>
    </div>
  );
};
