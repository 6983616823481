export function CookiePolicy() {
  return (
    <main className="relative bg-gray-900 py-8 sm:py-24 text-white px-4 sm:px-6 lg:px-8">
      <div className="max-w-3xl mx-auto">
        <h1 className="text-2xl font-bold">Cookie Policy</h1>
        <p className="mt-4">
          We are committed to ensuring that your privacy is protected. This
          Cookie Policy explains how we use cookies and similar technologies to
          improve your experience on our website.
        </p>

        <h2 className="mt-6 text-xl font-semibold">What are Cookies?</h2>
        <p>
          Cookies are small text files that are placed on your device to collect
          standard Internet log information and visitor behavior information.
          When you visit our sites, we may collect information from you
          automatically through cookies or similar technology.
        </p>

        <h2 className="mt-6 text-xl font-semibold">How we use Cookies</h2>
        <p>
          We use cookies exclusively for analytical purposes and to facilitate
          A/B testing, necessary for enhancing our website's functionality and
          user experience.
        </p>

        <ul className="list-disc ml-6 mt-4">
          <li>
            <strong>Google Analytics:</strong> We use Google Analytics cookies
            to help us understand how visitors interact with our website. This
            helps us measure and improve the content and functionality of our
            site.
          </li>
          <li>
            <strong>Visual Website Optimizer (VWO):</strong> VWO cookies support
            us in performing A/B testing by showing different versions of our
            web elements to see which performs better.
          </li>
        </ul>

        <h2 className="mt-6 text-xl font-semibold">Your Privacy</h2>
        <p>
          We do not use cookies for marketing purposes, nor do we track any
          cookies that could be used for marketing. Furthermore, no information
          collected via cookies is shared with any third parties other than our
          analytics partners, Google Analytics and VWO.
        </p>
      </div>
    </main>
  );
}
