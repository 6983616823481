import React from "react";
import { ProgressBarRegistration } from "./ProgressBarRegistration";

export const HeaderRegistration = ({ totalSteps, currentStep }) => {
  return (
    <div className="">
      <div className="text-5xl font-black mb-2">
        <span className="">Welcome to </span>
        <span className="text-main-blue">ezJobs</span>
      </div>
      <div className="text-lg">
        <span>Please complete the following steps</span>
      </div>
      <div className="px-2 py-8">
        <ProgressBarRegistration
          totalSteps={totalSteps}
          currentStep={currentStep}
        />
      </div>
    </div>
  );
};
