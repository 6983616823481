export const Checkbox = ({ label, isChecked, onCheckboxChange }) => (
  <label className="flex items-center">
    <input
      type="checkbox"
      checked={isChecked}
      onChange={onCheckboxChange}
      className="form-checkbox checkbox mr-2"
      style={{ width: "16px", height: "16px"}}
    />
    {label}
  </label>
);
