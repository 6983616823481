import React from "react";
import {
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverHeader,
  PopoverBody,
  PopoverArrow,
} from "@chakra-ui/react";
import IconDashboard from "../../assets/images/dashboard-icon.png";
import { SignInWithGoogle } from "./SignInWithGoogle";
import { ButtonWithImage } from "../cta/ButtonWithImage";
import { SignInWithMicrosoft } from "./SignInWithMicrosoft";
import { SignOutButton } from "./SignOut";
import { IconLoading } from "../IconLoading";
import { UserLoginIcon } from "./UserLoginIcon";
import { UserIcon } from "./UserIcon";
import { SignInWithEmail } from "./SignInWithEmail";

function extractDisplayName(text, maxLength = 6) {
  if (!text) return ''
  const firstWord = text.split(' ')[0]; 
  return firstWord.length > maxLength ? firstWord.substring(0, maxLength) : firstWord;
}


export const SignInUserIcon = ({
  userName = "",
  isLoggedIn = false,
  onProfileClick = () => {},
  isLoading = false,
}) => {
  const displayName = extractDisplayName(userName)

  if (isLoading) {
    return (
      <Popover>
        <PopoverTrigger>
          <UserLoginIcon />
        </PopoverTrigger>
        <PopoverContent style={{ borderRadius: "10px" }}>
          <PopoverArrow />
          <PopoverHeader
            className="text-center font-bold mt-2"
            borderBottom={"none"}
          >
            Loading
          </PopoverHeader>
          <PopoverBody className="m-auto mb-2">
            <IconLoading message="" />
          </PopoverBody>
        </PopoverContent>
      </Popover>
    );
  }

  const loggedOutContent = () => {
    return (
      <Popover>
        <PopoverTrigger>
          <UserLoginIcon />
        </PopoverTrigger>
        <PopoverContent style={{ borderRadius: "10px" }}>
          <PopoverArrow />
          <PopoverHeader
            className="text-center font-bold mt-2"
            borderBottom={"none"}
          >
            Sign In
          </PopoverHeader>
          <PopoverBody className="m-auto mb-2">
            <SignInWithGoogle />
            <SignInWithMicrosoft />
            <SignInWithEmail />
          </PopoverBody>
        </PopoverContent>
      </Popover>
    );
  };

  const loggedInContent = () => {
    return (
      <Popover>
        <PopoverTrigger>
          <UserIcon name={displayName} />
        </PopoverTrigger>
        <PopoverContent style={{ borderRadius: "10px" }}>
          <PopoverArrow />
          <PopoverHeader
            className="text-center font-bold mt-2"
            borderBottom={"none"}
          >
            {userName}
          </PopoverHeader>
          <PopoverBody className="m-auto mb-2">
            <>
              <ButtonWithImage
                onClick={onProfileClick}
                imgSrc={IconDashboard}
                imgAlt="Profile"
                buttonText="Profile"
              />
              <SignOutButton />
            </>
          </PopoverBody>
        </PopoverContent>
      </Popover>
    );
  };

  const content = isLoggedIn ? loggedInContent() : loggedOutContent();

  return <>{content}</>;
};
