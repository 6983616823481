import { Spinner } from "@chakra-ui/react";

export const IconLoading = ({
  message = "Loading",
  size = "md",
  center = true,
}) => (
  <div className={`${center ? "flex flex-row mx-auto" : ""}`}>
    <Spinner color="red.500" size={size} className="" />
    <p className="pl-2">{message}</p>
  </div>
);
