import React from "react";
import { navBarMl, navBarMr } from "../../assets/classnames";
import { LinkStyled } from "./LinkStyled";

export const MobileMenu = ({ isOpen, links, actionOnClick }) => {
  if (!isOpen) return;

  return (
    <div className={`lg:hidden ${navBarMl} ${navBarMr}`}>
      <div className="px-4 py-4 space-y-2 bg-gray-700 rounded-md">
        {links.map((link) =>
          !link.href ? (
            <hr key={link.name} />
          ) : (
            <LinkStyled key={link.name} link={link} onClick={actionOnClick} />
          )
        )}
      </div>
    </div>
  );
};
