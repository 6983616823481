export const faqData = [
  {
    question: "How does ezJobs help me get a job?",
    answer:
      "ezJobs searches for and applies to jobs for you. This saves you a considerable amount of time, and helps you apply to a larger number of jobs. Because of this, you are much more likely to get a job quickly, with a fraction of the work you had before using ezJobs.",
  },
  {
    question: "How is this different from a recruiting company?",
    answer:
      "Recruiting agents work for companies; we work for you. That is, a recruiter has one goal: to fill out the job vacancy for which they are currently recruiting. Our goal is different: it is to find you a job. Recruiters also have a limited number of companies they are recruiting for. We don't, and so, we apply to any company (as long as it's a good match), regardless of if that company is our partner or not.",
  },
  {
    question: "How does it work?",
    answer:
      "Our service works by combining the best parts of technology and human expertise. We have a number of proprietary technologies, including Artificial Intelligence systems, to analyse your CV and find the best jobs for you. And these recommendations are then fed to a human expert who double-checks the recommendations and refines the model. We then have a number of systems to automatically apply to jobs for you. The result is that you will apply to a large number of jobs, particularly tailored for you, without having to do any of the manual work yourself.",
  },
  {
    question: "What makes this different from other job search platforms?",
    answer:
      "On other job search platforms, you have to manually apply to jobs. Here, you don't - we apply for you. You simply have to provide us with your CV and your job preferences, and we will automatically apply to jobs for you. You save time, and apply to more jobs than ever before. This dramatically increases your chances to get a job quickly. And the best part, it requires almost no effort on your part.",
  },
  // {
  //   question: "Is ezJobs ready to be used?",
  //   answer:
  //     "We are currently in beta testing. This means that we have selected a number of users to be the first to test our service, and to provide us with feedback to make the experience better for everyone else. If you would like to get an update when we go live, we recommend you subscribe to our newsletter.",
  // },
  {
    question: "What happens after I sign up?",
    answer:
      "After you sign up, we will ask you to upload an updated CV, and ask you a few questions about your job search preferences. After that is done, ezJobs will start applying to jobs for you. You will be able to see the status of these applications on your profile page. At this point, you don't have to do anything, except reply to all the interview invitations that you will soon be receiving.",
  },
  {
    question:
      "I'm using ezJobs, but I am not getting any interviews. What should I do?",
    answer:
      "If you have been using our service for a few weeks, but did not receive any job interview requests, then there are two possible scenarios: either you are applying to a job which does not fit you well, or your CV needs some work. In this case, we propose that you schedule an interview with one of our experts to understand what is going wrong.",
  },
  {
    question:
      "I have been getting interviews, but no job offers. What should I do?",
    answer:
      "If you have been getting interviews, but no job offers, do not despair. This is a common enough scenario. This could be because the companies have interviewed a large number of people for a limited number of roles, or because your interviews have not been going so well. If you believe that it's the latter scenario, we recommend that you schedule an interview with one of our experts to understand exactly what is going wrong during your interviews. They will ask you a number of questions, and also run a mock interview that will allow you to practice and get proactive feedback in order to increase your chances.",
  },

  // {
  //   question: "Do you offer a refund?",
  //   answer:
  //     "Yes, of course! After you sign up with us, we will immediately start working towards applying to jobs for you. However, if you do encounter any problems, you can cancel your subscription free of charge during the first 48 hours",
  // },
];
