import Rafa from "../../src/assets/images/rafa_4.webp";
import Maria from "../../src/assets/images/Maria.jpeg";
import Isabel from "../../src/assets/images/Isabel.jpeg";


const team = [
    {
        name: 'Rafael Marques',
        role: 'Founder',
        imageUrl: Rafa,
        twitterUrl: 'https://twitter.com/',
        linkedinUrl: 'https://www.linkedin.com/',
    },
    {
        name: 'Maria Mendonça',
        role: 'HR',
        imageUrl: Maria,
        twitterUrl: 'https://twitter.com/',
        linkedinUrl: 'https://www.linkedin.com/in/amariamendonca/',
    },
    {
        name: 'Rafael Marques',
        role: 'Founder',
        imageUrl: Rafa,
        twitterUrl: 'https://twitter.com/',
        linkedinUrl: 'https://www.linkedin.com/',
    },
    {
        name: 'Isabel Germano',
        role: 'UI/UX',
        imageUrl: Isabel,
        twitterUrl: 'https://twitter.com/',
        linkedinUrl: 'https://www.linkedin.com/in/isabel-m-germano/',
    },

]

export default team;