import React, { useState, useEffect } from "react";

const maxColor = 150; // Lower than 255 to make colors darker
const styleIcon =
  "flex items-center justify-center rounded-sm sm:rounded-md w-10 h-10 md:w-14 md:h-14 md:hover:w-20 md:hover:h-20 text-white font-bold text-md pl-1 cursor-pointer";

export const MatchRateIcon = ({ matchRate, onClick }) => {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const isNumber = typeof matchRate === "number";
  let backgroundColor;

  useEffect(() => {
    function handleResize() {
      setWindowWidth(window.innerWidth);
    }

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  // Tailwind's 'sm' breakpoint is typically 640px
  if (isNumber) {
    const red = Math.floor(maxColor * (1 - matchRate / 100));
    const green =
      matchRate > 49 ? Math.floor(maxColor * (matchRate / 100)) : 20;
    backgroundColor = `rgb(${red}, ${green}, 0)`;
    matchRate = `${Math.min(100, Math.max(0, matchRate))}%`;
  } else {
    matchRate = "n.a.";
    backgroundColor = "gray"; // Apply a default color or adjust as needed
  }

  const dynamicStyle =
    windowWidth >= 640
      ? { backgroundColor }
      : { "text-decoration": "underline" };

  return (
    <div className={styleIcon} style={dynamicStyle} onClick={onClick}>
      {matchRate}
    </div>
  );
};
