import {
  registrationSubTitle,
  registrationTitle,
  styleRegistrationContentContainer,
  styleWidthJobTitles,
} from "../../assets/classnames";
import {
  AlertMessage,
  AlertSuccess,
  ErrorMessage,
  LoadingMessage,
} from "../../components/AlertMessage";
import { IconLoading } from "../../components/IconLoading";
import { useUserData } from "../../context/useUserData";
import { PreviousAndNext } from "./PreviousAndNext";

export const RegistrationContainer = ({
  children,
  error,
  title,
  subTitle,
  onClickPrevious,
  showNext,
  onClickNext,
  success,
  msgSuccess,
  countdown,
}) => {
  const { loading, isUpdating } = useUserData();
  const successMessage =
    msgSuccess || `Success updating your details. Redirecting in ${countdown}`;

  if (loading) {
    return <IconLoading />;
  }

  return (
    <div className={styleRegistrationContentContainer}>
      <div className="pb-8">
        <p className={registrationTitle}>{title}</p>
        <p className={registrationSubTitle}>{subTitle}</p>
        {children}
      </div>

      <AlertMessage
        type={"loading"}
        display={isUpdating}
        message={"Submitting data"}
      />
      <AlertMessage
        type={"error"}
        display={error}
        message={`There was an error submitting your data: ${error}`}
      />
      <AlertMessage
        type={"success"}
        display={success}
        message={successMessage}
      />

      <div className="">
        <PreviousAndNext
          showPrevious={true}
          onClickPrevious={onClickPrevious}
          showNext={showNext}
          onClickNext={onClickNext}
        />
      </div>
    </div>
  );
};
