import React from "react";
import Footer from "./footer/Footer";
import Navigation from "./navigation/Navigation";
import { CookieBanner } from "./cookies/CookieBanner";
import { navBarMl, navBarMr } from "../assets/classnames";

export const Layout = ({
  children,
  className = "",
  disableCookies = false,
  center=true
}) => {

  return (
    <main
      className={`flex flex-col justify-between bg-gray-900 min-h-screen ${className}`}
    >
      <Navigation />
      <div className="flex-grow pt-20 sm:pt-24">
        <article className="relative bg-gray-900 flex-grow">
          <div className="text-white mx-auto">
            <div className={`flex flex-col  ${center ? 'items-center' : `${navBarMl} ${navBarMr}`} space-y-3`}>
              {children}
              {!disableCookies && <CookieBanner />}
            </div>
          </div>
        </article>
      </div>
      <div className="pt-12" />
      <Footer />
    </main>
  );
};
