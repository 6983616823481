import Linkedin from "../../assets/socials/linkedin-icon.png";
import Instagram from "../../assets/socials/instagram-icon-3.png";
import Facebook from "../../assets/socials/facebook-icon.png";

export const footerData = {
  support: [
    { name: "FAQ", href: "/faq" },
    { name: "Terms", href: "/policies/terms-and-conditions" },
    { name: "Contact Us", href: "/contact" },
  ],
  company: [
    { name: "About", href: "/about" },
    { name: "Blog", href: "/blog" },
    { name: "Subscriptions", href: "/subscriptions" },
  ],
  tools: [
    { name: "ATS Resume Checker", href: "/ats-resume-checker" },
    { name: "Cover Letter Generator", href: "/cover-letter-generator" },
    { name: "Interview Answers", href: "/interview-preparation" },
  ],
  social: [
    {
      name: "Linkedin",
      href: "https://www.linkedin.com/company/ezcompany123",
      icon: Linkedin,
    },
    {
      name: "Facebook",
      href: "https://www.facebook.com/profile.php?id=61557380730755",
      icon: Facebook,
    },
    {
      name: "Instagram",
      href: "https://www.instagram.com/getezjobs",
      icon: Instagram,
    },
  ],
};
