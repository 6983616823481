const styleTitle = "text-xl font-bold pb";
const styleSection = "pb-4";
const styleContent = "";

export const ProfileInfo = ({ userData, styleContainer = "" }) => {
  const ContentCoverLetter = () => {
    if (userData?.coverLetterFilename) {
      return (
        <p
          className={`${styleContent} underline underline-offset-1 hover:text-blue-500`}
        >
          <a
            href={userData?.coverLetterSignedUrl}
            target="_blank"
            rel="noopener noreferrer"
          >
            {userData?.coverLetterFilename}
          </a>
        </p>
      );
    }
    return <p className={`${styleContent}`}> Not provided</p>;
  };

  return (
    <div className={`sm:grid sm:grid-cols-2 ${styleContainer} `}>
      <div className="">
        <div className={styleSection}>
          <p className={styleTitle}>Profile Information</p>
          <p className={styleContent}>Name: {userData?.displayName}</p>
        </div>

        <div className={styleSection}>
          <p className={styleTitle}>CV</p>
          <p
            className={`${styleContent} underline underline-offset-1 hover:text-blue-500`}
          >
            <a
              href={userData?.cvSignedUrl}
              target="_blank"
              rel="noopener noreferrer"
            >
              {userData?.cvFilename}
            </a>
          </p>
        </div>

        <div className={styleSection}>
          <p className={styleTitle}>Cover Letter</p>
          <ContentCoverLetter />
        </div>

        <div className={styleSection}>
          <p className={styleTitle}>Job Titles</p>
          {userData?.jobTitles?.map((title) => (
            <p key={title} className={styleContent}>
              {title}
            </p>
          ))}
        </div>
      </div>
      <div className="sm:pl-4">
        <div className={styleSection}>
          <p className={styleTitle}>Location Preferences</p>
          {userData?.onSiteOk && (
            <p className={styleContent}>Allow onsite work</p>
          )}
          {userData?.hybridOk && (
            <p className={styleContent}>Allow hybrid work</p>
          )}
          {userData?.remoteOk && (
            <p className={styleContent}>Allow remote work</p>
          )}

          {userData?.locations?.length && (
            <p className="font-bold pt-2">Locations:</p>
          )}
          {userData?.locations?.map((location) => (
            <p key={location} className={styleContent}>
              {location}
            </p>
          ))}
        </div>

        <div className={styleSection}>
          <p className={styleTitle}>Other details</p>
          <p className={styleContent}>{userData?.jobSearchNotes}</p>
        </div>
      </div>
    </div>
  );
};
