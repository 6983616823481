import React from "react";
import LogoutIcon from "../../assets/images/logout-icon.webp";
import { ButtonWithImage } from "../cta/ButtonWithImage";
import { authLogout } from "../../hooks/authLogout";
import { useNavigate } from "react-router-dom";

export const SignOutButton = () => {
  const { logOut } = authLogout();
  const navigate = useNavigate();

  const handleLogout = async () => {
    await logOut();
    navigate("/");
  };

  return (
    <ButtonWithImage
      onClick={handleLogout}
      imgSrc={LogoutIcon}
      imgAlt="user-icon"
      buttonText="Sign Out"
    />
  );
};
