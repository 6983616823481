import axios from "axios";
import { useState } from "react";
import { SubmissionAddForm } from "./SubmissionAddForm";
import { URL_SUGGESTIONS_ADD } from "../../config";
import { IconLoading } from "../IconLoading";
import { SuccessOrAlertMessage } from "../AlertMessage";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
} from "@chakra-ui/react";
import { useAxiosPost } from "../../hooks/useAxios";

const delaySec = 3;

export const SuggestionsAddModal = ({
  isOpen,
  onClose,
  idUser,
}) => {
  const { axiosPost } = useAxiosPost();
  const [isLoading, setIsLoading] = useState(false);
  const [statusUpload, setStatusUpload] = useState(null);

  const onAddSuggestion = async (data) => {
    console.log("inside onAddSuggestion: ", data, idUser);

    const postData = {
      ...data,
      idUser,
    };

    const onSuccess = () => {
      setStatusUpload("success");
      setIsLoading(false);
      setTimeout(() => {
        onClose();
        setStatusUpload(null);
      }, delaySec * 1000);
    };

    const onError = () => setStatusUpload("error");

    await axiosPost(
      URL_SUGGESTIONS_ADD,
      postData,
      () => setIsLoading(true),
      () => onSuccess(),
      () => onError(),
      () => setIsLoading(false)
    );
  };

  const handleSubmit = (data) => {
    console.log("inside handlesubmit");
    onAddSuggestion(data);
  };

  const renderContent = () => {
    if (isLoading) {
      return <IconLoading />;
    }

    if (statusUpload) {
      return (
        <SuccessOrAlertMessage
          type={statusUpload}
          delaySeconds={delaySec}
          message={`${statusUpload} uploading suggestion`}
        />
      );
    }

    return <SubmissionAddForm onSubmit={handleSubmit} />;
  };

  if (!isOpen) {
    return;
  }

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>{`Upload Job Suggestion`}</ModalHeader>
        <ModalCloseButton />
        <ModalBody>{renderContent()}</ModalBody>
      </ModalContent>
    </Modal>
  );
};
