import React, { useEffect, useState } from "react";
import { useUserData } from "../../context/useUserData";
import { useNavigate } from "react-router-dom";
import { ProfileContent } from "./ProfileContent";
import { useSubmissionsApi } from "../../context/useSubmissionsApi";

export const ProfileNew = ({}) => {
  const navigate = useNavigate();
  const { userData, isRecruiter, subscriptionDetails } = useUserData();
  const { fetchSuggestions, fetchSubmissions, fetchDiscarded } =
    useSubmissionsApi();
  const [numSuggestions, setNumSuggestions] = useState(-1);
  const [numSubmissions, setNumSubmissions] = useState(-1);
  const [numDiscarded, setNumDiscarded] = useState(-1);
  const [error, setError] = useState(false);
  const [isCompleteLoading, setIsCompleteLoading] = useState(false);

  useEffect(() => {
    if (isRecruiter) navigate("/users");

    if (!userData) return;

    if (!userData.isRegistrationComplete && !isRecruiter) {
      navigate("/registration");
    }

    const onLoadSuggestions = (res) => setNumSuggestions(res?.data?.numDocs);
    const onLoadSubmissions = (res) => setNumSubmissions(res?.data?.numDocs);
    const onLoadDiscarded = (res) => setNumDiscarded(res?.data?.numDocs);
    const onError = (e) => setError(e);

    setError(null);
    fetchSuggestions(userData?._id, onLoadSuggestions, onError);
    fetchSubmissions(userData?._id, onLoadSubmissions, onError);
    fetchDiscarded(userData?._id, onLoadDiscarded, onError);
  }, [userData, isRecruiter]);

  useEffect(() => {
    if (
      userData &&
      numSubmissions >= 0 &&
      numSubmissions >= 0 &&
      numDiscarded >= 0
    ) {
      setIsCompleteLoading(true);
    }
  }, [userData, numSuggestions, numSubmissions, numDiscarded]);

  const handleClickEdit = () => {
    navigate("/registration/review");
  };

  const handleClickSuggestions = () => {
    navigate(`/profile/${userData?._id}/suggestions`);
  };

  const handleClickSubmissions = () => {
    navigate(`/profile/${userData?._id}/submissions`);
  };

  const handleClickSubscription = () => {
    navigate(`/profile/${userData?._id}/manage-subscriptions`);
  };

  const handleClickDiscarded = () => {
    navigate(`/profile/${userData?._id}/discarded-suggestions`);
  };

  if (!isCompleteLoading) {
    return (
      <div>
        <ProfileContent
          userData={{ displayName: "User" }}
          numSubmissions={0}
          numSuggestions={0}
          numDiscarded={0}
          subscriptionName={" "}
          isLoading={true}
        />
      </div>
    );
  }

  return (
    <ProfileContent
      userData={userData}
      error={error}
      numSubmissions={numSubmissions}
      numSuggestions={numSuggestions}
      numDiscarded={numDiscarded}
      subscriptionName={subscriptionDetails?.productName}
      handleClickEdit={handleClickEdit}
      handleClickSuggestions={handleClickSuggestions}
      handleClickSubmissions={handleClickSubmissions}
      handleClickSubscription={handleClickSubscription}
      handleClickDiscarded={handleClickDiscarded}
    />
  );
};
