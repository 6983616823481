import React, { useState } from "react";
import { authEmail } from "../../hooks/authEmail";
import { useNavigate } from "react-router-dom";
import { sendPasswordResetEmail } from "firebase/auth";
import { auth } from "../../firebaseConfig";
import { navBarMl, navBarMr } from "../../assets/classnames";
import { ButtonCustom } from "../cta/ButtonCustom";

export const PageEmailLogin = () => {
  const { logInWithEmail } = authEmail();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [isResettingPassword, setIsResettingPassword] = useState(false);
  const [error, setError] = useState(null);
  const [message, setMessage] = useState(null);
  const navigate = useNavigate();

  const handleLogin = async (e) => {
    e.preventDefault();
    try {
      await logInWithEmail(email, password);
      navigate("/profile");
    } catch (error) {
      setError("Login failed. Please check your email and password.");
    }
  };

  const handlePasswordReset = async () => {
    setError(null);
    setMessage(null);
    try {
      await sendPasswordResetEmail(auth, email);
      setMessage("Password reset email sent! Please check your inbox.");
    } catch (error) {
      setError("Failed to send password reset email. Please check your email address.");
    }
  };

  const togglePasswordReset = () => {
    setIsResettingPassword(!isResettingPassword);
    setError(null);
    setMessage(null);
  };

  return (
    <div className="">
      <div className={`${navBarMl} ${navBarMr}`}>
        <h1 className="text-center text-3xl font-bold">Log In</h1>
        {!isResettingPassword ? (
          <form onSubmit={handleLogin}>
            <div className="flex flex-col pt-4 w-64">
              <p>Email</p>
              <input
                type="email"
                placeholder="Email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                className="text-black"
                required
              />
              <p>Password</p>
              <input
                type="password"
                placeholder="Password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                className="text-black"
                required
              />
              <ButtonCustom text="Log In" style="mt-4" />

              {error && <p className="text-red-400 text-center mt-4">{error}</p>}

              <p className="mt-4 text-center underline hover:cursor-pointer" onClick={togglePasswordReset}>
                Forgot Password?
              </p>

              <p className="mt-8 text-center">
                Don't have an account?
                <a href={"/email-signup"}>
                  <p className="underline hover:cursor-pointer">
                    Sign up for ezJobs
                  </p>
                </a>
              </p>
            </div>
          </form>
        ) : (
          <div className="flex flex-col pt-4 w-64">
            <h2 className="text-center text-xl font-bold">Reset Password</h2>
            <p className="text-center">Enter your email to reset your password.</p>
            <input
              type="email"
              placeholder="Email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              className="text-black mt-4"
              required
            />
            <ButtonCustom text="Send Reset Email" style="mt-4" onClick={handlePasswordReset} />

            {error && <p className="text-red-400 text-center mt-4">{error}</p>}
            {message && <p className="text-green-400 text-center mt-4">{message}</p>}

            <p className="mt-8 text-center underline hover:cursor-pointer" onClick={togglePasswordReset}>
              Back to Login
            </p>
          </div>
        )}
      </div>
    </div>
  );
};
