import React from "react";
import FileUploadIcon from "../../assets/images/file-upload-icon.svg";
import { ButtonCustom } from "../cta/ButtonCustom";

export const CvUploadForm = ({ onFileChange, fileName }) => {
  const handleDrop = (event) => {
    console.log("handleDrop event: ", event);
    event.preventDefault();
    event.stopPropagation();

    if (event.dataTransfer.files && event.dataTransfer.files.length > 0) {
      onFileChange(event.dataTransfer.files[0]);
      event.dataTransfer.clearData();
    }
  };

  const handleDragOver = (event) => {
    event.preventDefault();
    event.stopPropagation();
  };

  return (
    <div
      onDrop={handleDrop}
      onDragOver={handleDragOver}
      onDragEnter={handleDragOver}
      onClick={() => document.getElementById("cvFileUpload").click()}
      className="cursor-pointer"
    >
      <img
        src={FileUploadIcon}
        width="80"
        className="mx-auto"
      />
      <input
        id="cvFileUpload"
        type="file"
        accept="application/pdf"
        onChange={(event) => onFileChange(event.target.files[0])}
        hidden={true}
      />
      <ButtonCustom isHighlight={true} text="Choose File" style="mt-2"/>
    </div>
  );
};
