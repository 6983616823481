import { Cta } from "../../components/cta/Cta";
import Team from "../../components/team/Team";
import ContactForm from "../contact/ContactForm";
import {
  bordersLanding,
  navBarMl,
  navBarMr,
  titlesLanding,
} from "../../assets/classnames";
import Footer from "../../components/footer/Footer";
import Navigation from "../../components/navigation/Navigation";
import { CookieBanner } from "../../components/cookies/CookieBanner";
import { useEffect, useState } from "react";
import { ChevronDoubleDownIcon } from "@heroicons/react/24/solid";

export const fullH = "h-[calc(90vh)]  sm:h-[calc(90vh)]";

const styles = {
  why: {
    container:
      "mt-8 flex flex-col items-center justify-center rounded-lg bg-rose-100 min-h-48 sm:min-h-80 text-black",
    title: "text-4xl sm:text-6xl font-bold",
    subTitle: "text-2xl sm:text-4xl pt-2 sm:pt-4",
  },
  why2: {
    container: `${fullH} mt-8 flex flex-col items-center justify-center rounded-lg text-center`,
    title: "text-4xl sm:text-6xl font-bold text-main-blue",
    subTitle: "text-2xl sm:text-4xl pt-2 sm:pt-4 text-white",
  },
  story: {
    container: `flex flex-col py-8 sm:py-16 mb-10`,
    gridContainer:
      "sm:grid sm:grid-cols-3 sm:space-x-4 md:space-x-12 space-y-4 sm:space-y-0",
    title: "text-5xl font-bold text-main-blue pb-8 sm:pb-12 text-center",
    text: "sm:text-lg sm:text-left tracking-wide px-6 sm:px-0",
  },
  mission: {
    container:
      "flex flex-col items-center justify-center rounded-lg bg-rose-100 min-h-48 sm:min-h-80 text-black",
    title: "text-4xl sm:text-6xl font-bold ",
    subTitle: "text-center text-xl sm:text-4xl pt-2 sm:pt-4",
    subTitleTwo: "sm:pt-1 text-center text-xl sm:text-4xl ",
  },
};

const storyOne = `Facing the same challenges as many job seekers today, the founder of ezJobs - Rafael - was getting frustrated while looking for a job. Despite a lot of effort in sending job applications, <b>the responses were few and far between</b>. Motivated to change this situation, he developed a tool to help him.`;
const storyTwo = `Scanning the internet, this tool found relevant job openings, and automatically applied to them on Rafael's behalf. For over two weeks, the program run every night. And the result? 200+ applications, 40 interviews, 3 job offers.`;
const storyThree = `Having landed a job, Rafael put this tool aside - for a while. But, 5 years later, with the job market being even worse, Rafael felt <b>it was time time to release this to the public</b>. And so... ezJobs was born.`;

export function NewAboutPage() {
  const [showScrollIndicator, setShowScrollIndicator] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => {
      setShowScrollIndicator(true);
    }, 1000); // Delay the indicator's appearance

    const handleScroll = () => {
      if (window.scrollY > 50) {
        setShowScrollIndicator(false);
      } else {
        setShowScrollIndicator(true);
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      clearTimeout(timer); // Clear the timeout on component unmount
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const ContentScrollIcon = () => {
    if (!showScrollIndicator) return;

    return (
      <div
        className={`fixed bottom-8 right-8 animate-bounce`}
        onClick={handleScrollClick}
      >
        <ChevronDoubleDownIcon className="h-8 w-8 text-main-highlight" />
      </div>
    );
  };

  const handleScrollClick = () => {
    const section = document.getElementById("Team");
    if (section) {
      section.scrollIntoView({ behavior: "smooth", alignToTop: true });
    }
  };

  const ContentWhy = () => (
    <div className={styles.why2.container}>
      <div className="pb-28">
        <div className={styles.why2.title}>Because finding a job is hard</div>
        <div className={styles.why2.subTitle}>
          And we're trying to make it easy.
        </div>
      </div>
    </div>
  );

  const ContentMission = () => (
    <div className={styles.mission.container}>
      <div className={styles.mission.title}>Our mission</div>
      <div className={styles.mission.subTitle}>
        To help people find good jobs,
      </div>
      <div className={styles.mission.subTitleTwo}>with (almost) no effort.</div>
    </div>
  );

  const ContentSlogan = () => (
    <div>Finding a job is hard. With us, it's ez.</div>
  );

  const ContentStory = () => (
    <div className={styles.story.container}>
      <div className={styles.story.title}>Our story</div>
      <div className={styles.story.gridContainer}>
        <div
          className={styles.story.text}
          dangerouslySetInnerHTML={{ __html: storyOne }}
        />
        <div
          className={styles.story.text}
          dangerouslySetInnerHTML={{ __html: storyTwo }}
        />
        <div
          className={styles.story.text}
          dangerouslySetInnerHTML={{ __html: storyThree }}
        />
      </div>
    </div>
  );

  const ContentBottom = () => {
    return (
      <div className="flex flex-col">
        <div className="">
          <div className={bordersLanding}>
            <p className={`${titlesLanding} !text-center`}>Questions?</p>
            <p className="text-white text-base sm:text-lg mt-4 text-center">
              If you still have any questions, please send us a message.
            </p>
            <p className="text-white text-base sm:text-lg text-center">
              We usually reply in 12 hours or less.
            </p>

            <div className="sm:mx-48 xl:mx-60">
              <ContactForm />
            </div>
            <div className="mb-12 md:mb-32" />
          </div>
        </div>

        <div className="">
          <div className="border-t border-gray-400 pt-8 sm:pt-16 text-center ">
            <Cta
              text="Subscribe"
              link="/subscriptions"
              lgAlign="center"
              id="CtaAboutSubscribe"
            />
          </div>
          <div className="mb-2 sm:mb-8" />
        </div>
      </div>
    );
  };

  const ContentTeam = () => (
    <div id="Team" className={`flex justify-center`}>
      <Team />
    </div>
  );

  return (
    <main className={`flex flex-col justify-between bg-gray-900 min-h-screen`}>
      <Navigation />
      <div className="flex-grow pt-12 sm:pt-20">
        <div className={`flex flex-col ${navBarMl} ${navBarMr} text-white `}>
          <ContentWhy />
          <ContentTeam />
          <ContentStory />
          <ContentMission />
          <ContentBottom />
          <CookieBanner />
          <ContentScrollIcon />
        </div>
      </div>
      <div className="pt-12" />
      <Footer />
    </main>
  );
}
