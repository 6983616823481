import { useEffect } from "react";
import { features, featuresTwo } from "../../data/features";
import { useObserver } from "../../hooks/useObserver";
import { useLogging } from "../../hooks/useLogging";

const FeatureBox = ({ feature }) => {
  return (
    <section
      key={feature.name}
      className="sm:bg-gray-700 rounded-2xl sm:border sm:border-gray-500 sm:py-8"
    >
      <section className="inline-flex items-center justify-center p-3">
        <img
          src={feature.icon}
          alt="/"
          className="h-16 w-16 text-white"
          aria-hidden="true"
        />
      </section>
      <h3 className="text-xl font-medium tracking-tight text-white">
        {feature.name}
      </h3>
      <p
        className="mt-2 text-base text-gray-400 px-4"
        dangerouslySetInnerHTML={{ __html: feature.description }}
      ></p>
    </section>
  );
};

const FeatureGroup = ({ features }) => {
  return (
    <section className="grid grid-cols-1 gap-8 md:grid-cols-3">
      {features.map((feature, index) => (
        <FeatureBox key={`feature-${index}`} feature={feature} />
      ))}
    </section>
  );
};

export function HowItWorks() {
  const { logEvent } = useLogging();
  const [setRef] = useObserver("HowItWorks", () =>
    logEvent("visibility: HowItWorks")
  );

  return (
    <article ref={setRef} id="HowItWorks" className={`bg-gray-900`}>
      <section className="mx-auto text-center">
        <p className="mt-3 text-3xl font-extrabold tracking-tight text-white sm:text-4xl md:text-left">
          {/* <p className={titlesLanding}> */}
          How does it work?
        </p>
        <p className="mx-auto mt-3 max-w-prose text-md md:text-xl text-gray-400 md:text-left md:mx-0">
          Finding employing is tiring and time consuming. So don't. We will do
          it for you!
        </p>
        <section className="mt-12">
          <FeatureGroup features={features} />

          <p className="mt-24 sm:mt-40 text-3xl font-extrabold tracking-tight text-white sm:text-4xl md:text-left">
            What happens behind the scenes?
          </p>
          <p className="mx-auto mt-3 mb-7 max-w-prose text-md md:text-xl text-gray-400 md:text-left md:mx-0">
            We combine AI and human expertise to find the best jobs for you.
          </p>
          <FeatureGroup features={featuresTwo} />
        </section>
      </section>
    </article>
  );
}
