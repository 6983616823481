import React, { useState, useEffect } from 'react';

export const CouponForm = ({ visible, onCouponValidate, isCorrect, isWrong, appliedCouponCode }) => {
  const [inputValue, setInputValue] = useState('');

  useEffect(() => {
    if (appliedCouponCode) {
      setInputValue(appliedCouponCode);
    }
  }, [appliedCouponCode]);

  if (!visible) return null;
  
  const handleChange = (event) => {
    setInputValue(event.target.value);
  };

  const handleBlur = () => {
    onCouponValidate(inputValue);
  };
  
  const getInputClasses = () => {
    const baseClasses = 'w-full h-8 rounded-lg border py-4 p-2 text-black';
    if (isCorrect) return `${baseClasses} bg-green-100 border-green-500`;
    if (isWrong) return `${baseClasses} bg-red-100 border-red-500`;
    return `${baseClasses} bg-gray-200 border-gray-600`;
  };
  
  return (
    <div className="space-y-2">
      <input
        className={getInputClasses()}
        placeholder="Type discount code"
        onChange={handleChange}
        onBlur={handleBlur}
        value={inputValue}
      />
      {isCorrect && (
        <p className="text-green-600 text-sm">
          Your coupon is correct.
        </p>
      )}
      {isWrong && (
        <p className="text-red-600 text-sm">
          Coupon is not valid.
        </p>
      )}
    </div>
  );
};