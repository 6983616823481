import React from "react";

import Spotify from "../../assets/images/companies/spotify-1-logo-svgrepo-com.svg";
import Google from "../../assets/images/companies/google-1-1-logo-svgrepo-com.svg";
import Visa from "../../assets/images/companies/visa-logo-svgrepo-com.svg";
import Meta from "../../assets/images/companies/meta.webp";
import Nike from "../../assets/images/companies/logo-nike-white.png";
import Binance from "../../assets/images/companies/binance.svg";
import Microsoft from "../../assets/images/companies/microsoft.svg";

const companies = [
  { id: "visa", src: Visa, alt: "visa" },
  { id: "Nike", src: Nike, alt: "Nike" },
  { id: "google", src: Google, alt: "Google" },
  { id: "Binance", src: Binance, alt: "Binance" },
  { id: "Microsoft", src: Microsoft, alt: "Microsoft" },
  { id: "Meta ", src: Meta, alt: "Meta" },
  { id: "spotify", src: Spotify, alt: "Spotify" },
];

const layoutStyles =
  "bg-gray-800 text-white text-center pt-2 mt-16 sm:mt-8 h-30";
const titleStyles = "sm:text-lg text-gray-400 pt-1";
const companiesStyles = "flex justify-center gap-4 md:gap-16";
const companyContainerStyles = "flex items-center justify-center h-16 w-20";
const companyImgStyles = "max-h-full max-w-full";

export const BannerCompanies = ({ title }) => (
  <div className={layoutStyles}>
    <h2 className={titleStyles}>{title}</h2>
    <div className={companiesStyles}>
      {companies.map((company, index) => (
        <div
          key={`${company.id}-${index}`}
          className={`${companyContainerStyles} ${
            index < 3 ? "hidden sm:flex" : ""
          }`}
        >
          <img
            className={companyImgStyles}
            src={company.src}
            alt={company.alt}
          />
        </div>
      ))}
    </div>
  </div>
);
