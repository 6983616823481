export const animationDelays = {
  lettersDelay: 0.1,
  titleMain: "",
  titleQuick: "0.92s",
};

export const navBarPaddingLeft = "pl-4  sm:pl-24 lg:pl-40 xl:pl-60 2xl:pl-80";
export const navBarPaddingRight = "pr-4 sm:pr-24 lg:pr-40 xl:pr-60 2xl:pr-80";
export const navBarMl = "ml-2 sm:ml-24 lg:ml-40 xl:ml-60 2xl:ml-80";
export const navBarMr = "mr-2 sm:mr-24 lg:mr-40 xl:mr-60 2xl:mr-80";

export const imgHeroMl = "ml-4 sm:ml-24 lg:ml-0";

export const styleRegistrationContentContainer = "min-h-80 md:min-h-96 flex flex-col justify-between border-2 border-gray-600 rounded-md py-4 md:py-8 px-2 md:px-8"
export const styleWidthJobTitles = "min-w-xs max-w-sm"
export const registrationTitle = "text-2xl font-bold pb-2"
export const registrationSubTitle = "h-16 md:h-20 pb-12"

export const titlesLanding = `font-extrabold text-main-blue sm:text-4xl lg:text-5xl text-3xl sm:text-5xl text-center md:text-left`
export const bordersLanding = `border-t border-gray-400 pt-8 sm:pt-12 lg:pt-24` 

export const stylePageTitle = 'text-3xl md:text-5xl font-bold mb-4'
export const stylePageDescription = 'text-xl md:text-xl'

export const styleContainerLogin = "flex w-full justify-center items-center bg-gray-700 rounded-sm p-1 pr-2"
export const styleIconLogin = "hidden sm:block h-7 sm:h-6 m-1"
