import React from "react";
import { Link } from "react-router-dom";

export const LinkStyled = ({ link, onClick }) => (
  <Link
    id={link.id || ""}
    to={link.href}
    className="block text-white px-4 text-gray-800 hover:underline"
    onClick={onClick}
  >
    {link.name}
  </Link>
);
