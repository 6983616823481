import React, { useEffect } from "react";
import { SignInWithGoogle } from "./SignInWithGoogle";
import { SignInWithMicrosoft } from "./SignInWithMicrosoft";
import { useUserData } from "../../context/useUserData";
import { useNavigate, useLocation } from "react-router-dom";
import { SignInWithEmail } from "./SignInWithEmail";

export const SignUp = ({isSignIn=false}) => {
  const { userData } = useUserData();
  const navigate = useNavigate();
  const location = useLocation();
  const destination = location.state?.from || "/profile";
  const message = isSignIn ? 'Sign In' : 'Sign up'
  
  console.log("sign up destination: ", destination);

  useEffect(() => {
    const isLoggedIn = Boolean(userData?.uid);
    if (isLoggedIn) {
      navigate(destination);
    }
  }, [userData, destination]);

  return (
    <div className="bg-gray-600 py-10 mx-10 px-10 rounded-lg space-y-3">
      <p className="text-center pb-1 mb-7 border-b">{message}</p>
      <SignInWithGoogle
        redirectProfile={false}
        buttonText={`${message} with Google`}
      />
      <SignInWithMicrosoft
        redirectProfile={false}
        buttonText={`${message} with Microsoft`}
      />
      <SignInWithEmail
        buttonText={`${message} with Email & Password`}
      />
    </div>
  );
};
