import UploadIcon from "../assets/images/file-icon-2.svg";
import SearchIcon from "../assets/images/icon-search-2.svg";
import SofaIcon from "../assets/images/icon-sofa-2.svg";
import GearIcon from "../assets/images/icon-gear-2.svg";
import PersonIcon from "../assets/images/icon-team-2.svg";
import EmailIcon from "../assets/images/icon-email-2.svg";

export const features = [
  {
    name: "Upload Resume",
    description: "Upload your Resume in order to start applying",
    icon: UploadIcon,
  },
  {
    name: "Set preferences",
    description: "Tell us your job search preferences",
    icon: GearIcon,
  },
  {
    name: "Relax",
    description: "We find you relevant job openings and automatically send the applications for you",
    icon: SofaIcon,
  },
];

export const featuresTwo = [
  {
    name: "Match openings",
    description: "Our AI finds the most relevant job openings",
    icon: SearchIcon,
  },
  {
    name: "Human supervision",
    description: "Our recruiters double check the AI suggestions",
    icon: PersonIcon,
  },
  {
    name: "Apply!",
    description: "Applications are submitted by us, for you",
    icon: EmailIcon,
  },
];
