import React from "react";
import { LinkStyled } from "./LinkStyled";

export const DesktopDropdown = ({
  link,
  isActive,
  handleDropdownClick,
  onClick,
}) => (
  <div className="relative px-2 md:px-4 text-white rounded-md">
    <span
      className="cursor-pointer hover:underline flex items-center whitespace-nowrap"
      onClick={() => handleDropdownClick(link.name)}
    >
      {link.name}
    </span>
    {isActive && (
      <div className="dropdown absolute mt-2 bg-gray-600 shadow-lg rounded-md py-2 z-50 w-60 space-y-1">
        {link.list.map((sublink) => (
          <LinkStyled key={sublink.name} link={sublink} onClick={onClick} />
        ))}
      </div>
    )}
  </div>
);
