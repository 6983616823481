import React from "react";
import { useUserData } from "../../context/useUserData";
import { HeaderRegistration } from "./HeaderRegistration";
import { CvUploadContainer } from "../../components/forms/CvUploadContainer";
import { urlRegistration } from "../../config";
import { PreviousAndNext } from "./PreviousAndNext";
import {
  registrationTitle,
  styleRegistrationContentContainer,
} from "../../assets/classnames";
import { IconLoading } from "../../components/IconLoading";
import { useProgress } from "../../context/useProgress";
import { useSuccess } from "../../context/useSucess";
import { AlertMessage } from "../../components/AlertMessage";
import { useNavigate } from "react-router-dom";

export const RegistrationCvUpload = ({}) => {
  const navigate = useNavigate();

  const { userData, loading } = useUserData();
  const { increaseProgress, stepCompleted } = useProgress();
  const { success, setSuccess, setTargetUrl, countdown } = useSuccess();

  const hasBeenCompleted = stepCompleted > 0;
  const showNext = hasBeenCompleted && !success;

  const onClickNext = () => {
    navigate(urlRegistration.jobTitles);
    window.scrollTo(0, 0);
    increaseProgress();
  };

  const onSuccess = () => {
    setTargetUrl(urlRegistration.jobTitles);
    setSuccess(true);
  };

  if (loading) return <IconLoading />;

  return (
    <div className="">
      <HeaderRegistration />
      <div className={styleRegistrationContentContainer}>
        <div className="">
          <p className={registrationTitle}>Upload your CV</p>
          <CvUploadContainer
            userId={userData?.uid}
            hasCv={Boolean(userData?.cvPath)}
            onSuccess={onSuccess}
            savedFilename={userData?.cvFilename}
          />
        </div>

        <AlertMessage
          type={"success"}
          display={success}
          message={`Success updating your details. Redirecting in ${countdown}`}
          center={true}
        />

        <div className="">
          <PreviousAndNext
            showPrevious={false}
            showNext={showNext}
            onClickNext={onClickNext}
          />
        </div>
      </div>
    </div>
  );
};
