import React, { useRef, useEffect, useState } from "react";
import { useLogging } from "../../hooks/useLogging";
import { navBarPaddingLeft, navBarPaddingRight } from "../../assets/classnames";

export const DemoVideo = () => {
  const containerRef = useRef(null);
  const playerRef = useRef(null);
  const [playerReady, setPlayerReady] = useState(false);
  const [isPlaying, setIsPlaying] = useState(false);
  const [firstClick, setIsFirstClick] = useState(true);

  const { logEvent } = useLogging();

  useEffect(() => {
    // Load the IFrame Player API code asynchronously.
    const tag = document.createElement("script");
    tag.src = "https://www.youtube.com/iframe_api";
    const firstScriptTag = document.getElementsByTagName("script")[0];
    firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);

    // Create an <iframe> (and YouTube player) after the API code downloads.
    window.onYouTubeIframeAPIReady = () => {
      playerRef.current = new window.YT.Player("player", {
        videoId: "upNMjAy_T34",
        playerVars: {
          controls: 1,
          rel: 0,
          modestbranding: 1,
        },
        events: {
          onReady: onPlayerReady,
          onStateChange: onPlayerStateChange,
        },
      });
    };
  }, []);

  const onPlayerReady = (event) => {
    setPlayerReady(true);
    if (containerRef.current) {
      const iframe = containerRef.current.querySelector("iframe");
      if (iframe) {
        iframe.style.width = "100%";
        iframe.style.height = "100%";
      }
    }
  };

  const onPlayerStateChange = (event) => {
    setIsPlaying(event.data === window.YT.PlayerState.PLAYING);
  };

  const handleClick = () => {
    if (firstClick) {
      logEvent("demo video clicked");
      setIsFirstClick(false);
    }

    if (playerRef.current && playerReady) {
      if (isPlaying) {
        playerRef.current.pauseVideo();
      } else {
        playerRef.current.playVideo();
      }
    }
  };

  return (
    <div className="h-full py-4 pt-12 bg-gray-900">
      <div
        ref={containerRef}
        className={`mx-auto w-full md:w-2/3 h-2/3 md:h-1/2 pb-24 md:pb-0 ${navBarPaddingLeft} ${navBarPaddingRight}`}
      >
        <div className="relative w-full h-full">
          <div id="player" className="w-full h-full"></div>
          <div
            className="absolute inset-0 cursor-pointer"
            onClick={handleClick}
          ></div>
        </div>
      </div>
    </div>
  );
};
