import React, { useEffect, useState } from "react";
import { useUserData } from "../../context/useUserData";
import { useNavigate } from "react-router-dom";
import { AlertAll } from "../AlertMessage";
import { CardSubscribeNow } from "../../routes/user-profile/Cards";
import { stylePageTitle } from "../../assets/classnames";
import { timestampToDate } from "../../utils";
import { ModalConfirm } from "../../components/submissions/ModalConfirm";
import { URL_SUBSCRIBE_CANCEL } from "../../config";
import { ButtonCustom } from "../cta/ButtonCustom";
import { useAxiosPost } from "../../hooks/useAxios";

export const ManageSubscription = ({}) => {
  const navigate = useNavigate();
  const { axiosPost } = useAxiosPost();
  const { userData, loading, subscriptionDetails } = useUserData();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isUpdating, setIsUpdating] = useState(false);
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(false);

  console.log("ManageSubscription: ", { subscriptionDetails, loading });

  const nameSubscription = subscriptionDetails?.productName;
  const isSubscriber = Boolean(nameSubscription);
  const isCancellationScheduled =
    subscriptionDetails?.subscription?.cancel_at_period_end === true;

  useEffect(() => {}, []);

  const handleClickEdit = () => {
    navigate("/registration/cv-upload");
  };

  const RenewsOnDate = () => {
    const ts = new Date(subscriptionDetails?.renewalDate).getTime();
    const ds = timestampToDate(ts / 1000);
    return ds;
  };

  const handleCancel = async () => {
    const reqPayload = {
      _id: userData?._id,
      email: userData?.email,
      subscriptionId: subscriptionDetails.id,
    };

    await axiosPost(
      URL_SUBSCRIBE_CANCEL,
      reqPayload,
      () => setIsUpdating(true),
      () => setSuccess(true),
      (e) => setError(e),
      () => setIsUpdating(false)
    );
  };

  const ModalContent = () => {
    if (isModalOpen) {
      const title = "Cancel subscription";
      const text = "Are you sure you want to cancel?";

      return (
        <ModalConfirm
          title={title}
          text={text}
          onClose={() => setIsModalOpen(false)}
          onSubmit={handleCancel}
        />
      );
    }
  };

  const TitleContent = () => (
    <div className={`${stylePageTitle} text-main-blue`}>
      Manage Subscription
    </div>
  );

  const SubscribeNowContent = () => {
    if (isSubscriber || loading) return;

    return <CardSubscribeNow />;
  };

  const ActiveSubscriberContent = () => {
    if (loading || !isSubscriber || isCancellationScheduled) return;

    return (
      <div className="flex flex-col items-center bg-gray-700 rounded-md py-4">
        <div className="text-2xl font-bold">
          {nameSubscription} Subscription
        </div>
        <p className="pt-1">Renews on {RenewsOnDate()}</p>
        <p
          className="pt-4 underline cursor-pointer"
          onClick={() => setIsModalOpen(true)}
        >
          Cancel subscription
        </p>
      </div>
    );
  };

  const CancelledSubscriberContent = () => {
    if (loading || !isCancellationScheduled) return;

    const tsSec = subscriptionDetails.subscription.cancel_at;

    return (
      <div className="flex flex-col items-center bg-gray-700 rounded-md py-4">
        <div className="text-2xl font-bold">
          {nameSubscription} Subscription
        </div>
        <p className="pt-1">
          Cancellation scheduled for {timestampToDate(tsSec)}
        </p>
      </div>
    );
  };

  const AlertContent = () => (
    <div className="flex justify-center">
      <AlertAll
        error={error}
        msgError="There was an error cancelling your subscription"
        isUpdating={loading || isUpdating}
        msgLoading={loading ? "Loading..." : "Cancelling subscription"}
        success={success}
        msgSuccess="Success cancelling subscription"
      />
    </div>
  );

  const BackHomeContent = () => (
    <div className="flex justify-center">
      <ButtonCustom
        text="Back Home"
        onClick={() => navigate("/profile")}
        isHighlight={true}
      />
    </div>
  );

  return (
    <div className={"space-y-4"}>
      <TitleContent />
      <ModalContent />
      <SubscribeNowContent />
      <ActiveSubscriberContent />
      <CancelledSubscriberContent />
      <BackHomeContent />
      <AlertContent />
    </div>
  );
};
