import React from "react";

export const ButtonWithImage = ({
  onClick = () => {},
  imgSrc = "",
  imgAlt = "",
  buttonText = "Click me",
}) => {
  return (
    <div
      className="py-2.5 px-4 m-1 w-60 flex items-center  rounded-xl border border-gray-300 cursor-pointer"
      onClick={onClick}
    >
      <img className="h-6 w-6 mr-3" src={imgSrc} alt={imgAlt} />
      <div className="ml-3 md:ml-10">{buttonText}</div>
    </div>
  );
};
