import React, { useEffect, useState } from "react";
import { useUserData } from "../../context/useUserData";
import { useNavigate } from "react-router-dom";
import { HeaderRegistration } from "./HeaderRegistration";
import { URL_UPDATE_USER_INFO, urlRegistration } from "../../config";
import { useProgress } from "../../context/useProgress";
import { RegistrationContainer } from "./RegistrationContainer";
import { IconLoading } from "../../components/IconLoading";
import { useSuccess } from "../../context/useSucess";
import { CvUploadContainer } from "../../components/forms/CvUploadContainer";
import {
  registrationSubTitle,
  registrationTitle,
} from "../../assets/classnames";
import { AlertAll } from "../../components/AlertMessage";

const title = "Additional details";
const subTitle = "Please enter the final details.";
const placeholderNotes = `(optional)
Other details relevant to your dream job (salary, culture, technologies, etc.)`;

export const RegistrationOthers = ({}) => {
  const navigate = useNavigate();

  const { userData, fetchUserData, updateUserData, loading } = useUserData();
  const { increaseProgress, decreaseProgress } = useProgress();
  const { success, setSuccess, setTargetUrl, countdown } = useSuccess();

  const [cvJobSearchNotes, setCvJobSearchNotes] = useState("");
  const [name, setName] = useState("");
  const [error, setError] = useState("");
  const [hasChanged, setHasChanged] = useState(false);
  const [successCover, setSuccessCover] = useState();

  const onSuccess = () => {
    setTargetUrl(urlRegistration.review);
    setSuccess(true);
  };

  const onClickPrevious = () => {
    navigate(urlRegistration.locationPreferences);
    window.scrollTo(0, 0);
    decreaseProgress();
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    setSuccessCover(false)

    if (!hasChanged) {
      fetchUserData(false)
      navigate(urlRegistration.review);
      window.scrollTo(0, 0);
      increaseProgress();
      return;
    }

    const endpoint = `${URL_UPDATE_USER_INFO}?type=additionalNotes&userUid=${userData.uid}`;
    const data = {
      name,
      jobSearchNotes: cvJobSearchNotes,
    };

    const onOk = () => {
      setError("");
      onSuccess();
    };
    const onError = () => setError("error");

    updateUserData(endpoint, data, onOk, onError);
    fetchUserData(false)
  };

  const handleUpdateName = (e) => {
    setHasChanged(true);
    setName(e.target.value);
  };

  const handleUpdateNotes = (e) => {
    setHasChanged(true);
    setCvJobSearchNotes(e.target.value);
  };

  const handleSuccessCoverLetter = () => {
    setSuccessCover(true);
  };

  useEffect(() => {
    if (!userData) return;
    const { displayName, jobSearchNotes } = userData;

    if (displayName) setName(userData.displayName);
    if (jobSearchNotes) setCvJobSearchNotes(userData.jobSearchNotes);
  }, [userData]);

  if (loading) return <IconLoading />;

  const mainContent = (
    <div>
      <input
        type="text"
        className="border-gray-500 border rounded-md text-black bg-gray-200 placeholder-gray-600 sm:text-md"
        placeholder="Your name"
        value={name}
        onChange={handleUpdateName}
      />
      <textarea
        id="cvJobSearchNotes"
        rows="4"
        className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 mt-1 pt-2 pl-2 block w-full sm:text-md border-2 border-gray-500 rounded-md text-black bg-gray-200 placeholder-gray-600"
        value={cvJobSearchNotes}
        onChange={handleUpdateNotes}
        placeholder={placeholderNotes}
      />

      <p className={`${registrationTitle} mt-16`}>Cover letter (optional)</p>
      <p className={`${registrationSubTitle}`}>
        The cover letter is not required, but having it allows us to apply to
        more jobs on your behalf.
      </p>

      <CvUploadContainer
        userId={userData?.uid}
        hasCv={Boolean(userData?.coverLetterPath)}
        onSuccess={handleSuccessCoverLetter}
        savedFilename={userData?.coverLetterFilename}
        fileType="Cover Letter"
      />
    </div>
  );

  return (
    <div className="">
      <HeaderRegistration />
      <RegistrationContainer
        error={error}
        title={title}
        subTitle={subTitle}
        showNext={true}
        onClickPrevious={onClickPrevious}
        onClickNext={handleSubmit}
        success={success || successCover}
        msgSuccess = {successCover ? 'Success uploading Cover Letter' : ''}
        countdown={countdown}
      >
        {mainContent}
      </RegistrationContainer>
    </div>
  );
};
