import { useState, useEffect } from "react";
import Hero from "./Hero";
import Navigation from "../../components/navigation/Navigation";
import Footer from "../../components/footer/Footer";
import { FaqPage } from "../faq/Faq";
import { HowItWorks } from "./HowItWorks";
import { CookieBanner } from "../../components/cookies/CookieBanner";
// import { SectionReviews } from "./SectionReviews";
import { NewPricingMenu } from "../pricing/NewPricingMenu";
import {
  bordersLanding,
  navBarMl,
  navBarMr,
  navBarPaddingLeft,
  navBarPaddingRight,
  titlesLanding,
} from "../../assets/classnames";
import ContactForm from "../contact/ContactForm";
import { Cta } from "../../components/cta/Cta";
import { useWeekly } from "../../context/useWeekly";
import { useLogging } from "../../hooks/useLogging";
import { useObserver } from "../../hooks/useObserver";
import { StatsSection } from "./Stats";

function Landing({ isWeekly = true }) {
  const { updateIsWeekly } = useWeekly();
  const [showCookieBanner, setShowCookieBanner] = useState(false);

  const { logEvent } = useLogging();
  const [setRef] = useObserver("Questions", () =>
    logEvent("visibility: Questions")
  );

  useEffect(() => {
    updateIsWeekly(isWeekly);
  }, []);

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 100) {
        setShowCookieBanner(true);
        window.removeEventListener("scroll", handleScroll);
      }
    };

    const timer = setTimeout(() => {
      setShowCookieBanner(true);
    }, 10000);

    window.addEventListener("scroll", handleScroll);

    return () => {
      clearTimeout(timer);
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <article className="bg-gray-900">
      {showCookieBanner && <CookieBanner />}

      <Navigation />

      <Hero />
      {/* 
      <div className="pt-8 sm:pt-12 lg:pt-32" />

      <div className={`${navBarMr} ${navBarMl} `}>
        <ApplyEverywhere />
      </div> */}

      <div className={`pt-8 sm:pt-12 lg:pt-32 ${navBarMr} ${navBarMl} `}>
        <HowItWorks />
      </div>
      <div
        className={`${navBarMl} ${navBarMr} 2xl:px-72 my-12 border-y border-gray-400`}
      >
        <div className="pt-8 sm:pt-16 text-center ">
          <Cta
            text="Subscribe"
            link="#Subscriptions"
            lgAlign="center"
            id="CtaHowItWorks"
          />
        </div>
        <div className="mb-10 sm:mb-16" />
      </div>

      {/* <SectionReviews />
      <div className="mb-12 sm:mb-32" /> */}

      {/* <Team /> */}

      <StatsSection />
      <div
        className={` ${bordersLanding} ${navBarMl} ${navBarMr} mt-8 sm:mt-16`}
      />

      <section
        id="Subscriptions"
        className={`${navBarPaddingLeft} ${navBarPaddingRight} 2xl:px-72`}
      >
        <NewPricingMenu />
      </section>
      <div className="mb-12 sm:mb-32" />

      <div id="Faq" className={`${bordersLanding} ${navBarMr} ${navBarMl} `}>
        <FaqPage showCTA={false} />
      </div>
      <div className="mb-20" />

      <div
        id="Questions"
        className={` ${navBarPaddingLeft} ${navBarPaddingRight} 2xl:px-72`}
        ref={setRef}
      >
        <div className={bordersLanding}>
          <p className={titlesLanding}>Questions?</p>
          <p className="text-white text-base sm:text-lg mt-4 text-center md:text-left">
            We're here to help you.
          </p>
          <p className="text-white text-base sm:text-lg text-center md:text-left">
            If you still have any questions, please send us a message.
          </p>

          <ContactForm />
          <div className="mb-12 md:mb-32" />
        </div>
      </div>

      <div className={` ${navBarPaddingLeft} ${navBarPaddingRight} 2xl:px-72 `}>
        <div className="border-t border-gray-400 pt-8 sm:pt-16 text-center ">
          <Cta
            text="Subscribe"
            link="#subscriptions"
            lgAlign="center"
            id="CtaBottom"
          />
        </div>
        <div className="mb-10 sm:mb-16" />
      </div>

      <Footer />
    </article>
  );
}

export default Landing;
