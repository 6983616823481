import React from "react";
import { useNavigate } from "react-router-dom";

export const UserList = ({ userList }) => {
  const navigate = useNavigate();

  return (
    <div className="rounded-md overflow-x-auto ">
      <table className="w-full">
        <thead>
          <tr className="bg-gray-300">
            <TableHeader>#</TableHeader>
            <TableHeader>Name</TableHeader>
            <TableHeader>Email</TableHeader>
            <TableHeader>Subscription</TableHeader>
            <TableHeader>#Suggestions Ready</TableHeader>
            <TableHeader>#Discarded</TableHeader>
            <TableHeader>#Required submissions</TableHeader>
            <TableHeader>#Submissions </TableHeader>
          </tr>
        </thead>
        <tbody>
          {userList.map((user, index) => (
            <UserRow
              index={index}
              key={user._id}
              user={user}
              onNavigate={navigate}
            />
          ))}
        </tbody>
      </table>
    </div>
  );
};

const TableCell = ({ children, onClick, className }) => (
  <td
    className={`${className} px-5 py-5 border-b border-gray-200  text-black ${
      onClick ? "hover:bg-gray-200 hover:underline cursor-pointer" : ""
    }`}
    onClick={onClick}
  >
    {children}
  </td>
);

const TableHeader = ({ children }) => (
  <th className="px-5 py-3 border-b-2 border-gray-200 text-left text-xs font-bold text-gray-800 tracking-wider">
    {children}
  </th>
);

const UserRow = ({ index, user, onNavigate }) => (
  <tr className="odd:bg-white even:bg-gray-200">
    <TableCell>{index + 1}</TableCell>
    <TableCell
      // onClick={() => onNavigate(`/users/${user._id}`)}
      className="underline"
    >
      <a href={`/users/${user._id}`}>
      {user.displayName}

      </a>
    </TableCell>
    <TableCell>{user?.email || ""}</TableCell>
    <TableCell>{user?.plan?.name || "None"}</TableCell>
    <TableCell>{user.suggestions7days}</TableCell>
    <TableCell>{user.discarded7days}</TableCell>
    <TableCell>{user?.plan?.numSubmissionsPerWeek || 0}</TableCell>
    <TableCell
      className={`${
        user.submissions7days < user?.plan?.numSubmissionsPerWeek
          ? "text-red-600 font-bold text-xl"
          : ""
      }`}
    >
      {user.submissions7days}
    </TableCell>
  </tr>
);
