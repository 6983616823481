import React, { useState, createContext, useContext } from "react";
import { loadStripe } from "@stripe/stripe-js";

const StripeContext = createContext();

export const StripeProvider = ({ children }) => {
  const [stripe, setStripe] = useState(null);

  const initStripe = async () => {
    if (stripe) return;

    const instance = await loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY);
    setStripe(instance);
  };

  return (
    <StripeContext.Provider
      value={{
        stripe,
        initStripe,
      }}
    >
      {children}
    </StripeContext.Provider>
  );
};

export const useStripeHook = () => useContext(StripeContext);
