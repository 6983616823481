import React, { useState, useEffect } from "react";
import { CardSubmission } from "./CardSubmission";
import { stylePageTitle } from "../../assets/classnames";
import { useSubmissionsApi } from "../../context/useSubmissionsApi";
import { useUserData } from "../../context/useUserData";
import { AlertAll } from "../AlertMessage";
import { useNavigate, useParams } from "react-router-dom";
import { CardSubmissionsSimplified } from "../../routes/user-profile/Cards";
import { ButtonCustom } from "../cta/ButtonCustom";
import { SortDropdown, sortSubmissions } from "./SortDropdown";

export const NewSubmissionsPage = () => {
  const navigate = useNavigate();
  const { idUser } = useParams();
  const { isRecruiter } = useUserData();
  const { isLoading, fetchSubmissions } = useSubmissionsApi();
  const [submissions, setSubmissions] = useState([]);
  const [currentSort, setCurrentSort] = useState("most_recent");
  const [error, setError] = useState(null);

  console.log("new Submissions Page", { isLoading, submissions });

  useEffect(() => {
    setError(false);
    fetchSubmissions(idUser, onSuccess, onError);
  }, [idUser]);

  const onSuccess = (res) => setSubmissions(res?.data?.docList);
  const onError = (e) => setError(e);

  const TitleContent = () => (
    <>
      <div className={`${stylePageTitle} text-main-blue`}>Submissions</div>
      <p className="text-md max-w-3xl pb-4">
        These are the applications already submitted on your behalf.
      </p>
    </>
  );

  const AlertContent = () => (
    <AlertAll
      isUpdating={isLoading}
      msgLoading={"Loading submissions"}
      error={error}
      msgError="Error loading submissions"
    />
  );

  const SuggestionsContent = () => {
    if (submissions.length === 0) return;

    const sortedSubmissions = sortSubmissions(submissions, currentSort);

    return (
      <div>
        <div className="space-y-2">
          {sortedSubmissions.map((data, index) => (
            <CardSubmission
              data={data}
              isRecruiter={isRecruiter}
              key={`card-submission-${index}`}
              isComplete={true}
            />
          ))}
        </div>
      </div>
    );
  };
  const NoSuggestionsContent = () => {
    if (submissions.length > 0) return;

    return <CardSubmissionsSimplified />;
  };

  const BackHomeContent = () => (
    <div className="flex justify-center pt-12">
      <ButtonCustom
        text="Back Home"
        onClick={() => navigate("/profile")}
        isHighlight={false}
      />
    </div>
  );

  return (
    <div>
      <TitleContent />
      <AlertContent />
      <SortDropdown currentSort={currentSort} onSortChange={setCurrentSort} />
      <SuggestionsContent />
      <NoSuggestionsContent />
      <BackHomeContent />
    </div>
  );
};
